import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import login from "./login.jpeg";
import axios from "axios";

import { AuthContext } from "./context";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";

const ariaLabel = { "aria-label": "description" };

import configData from "./config.json";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const authContext = useContext(AuthContext);
  const history = useHistory();

  const authLogin = () => {
    axios
      .post(
        configData.API_URL + "personalAccount/users/login",
        // "http://192.168.1.29:4141/api/v1/" + "personalAccount/users/login",
        {
          email: email,
          password: password,
        }
      )
      .then((response) => {
        if (response?.data?.data?.message) {
          alert("User Not Found! Please Enter Correct Email/Password");
          return;
        } else {
          // alert("Login Successful");
          console.log("Login success", response?.data?.data?.access_token);
          let accountId = response?.data?.data?.account_id;
          let accessToken = response?.data?.data?.access_token;

          sessionStorage.setItem("ACCESS_TOKEN", accessToken);
          authContext.login(accountId);

          let plan = response?.data?.data?.plan;
          if (
            plan === "price_1LfOlnSBwqDDsny7nprdkWUQ" ||
            plan === "price_1LfOnUSBwqDDsny71PPaevJ8" ||
            plan === "price_1LfOpESBwqDDsny7sB1s8fra" ||
            plan === "price_1LfOrRSBwqDDsny7TiYnfuXA"
          )
            history.replace("/Dashboard/" + accountId + "/" + plan);
          else if (plan === "free" || plan === null) {
            plan = "free_5508";
            history.replace("/Dashboard/" + accountId + "/" + plan);
          }
        }

        return response;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };

  const authenticate = () => {
    if (email === "rupalishah@qualidatai.com" && password === "asd123")
      history.push("/Dashboard");
    else if (
      email === "shrikant4qualidatai@qualidatai.com" &&
      password === "asd123"
    )
      history.push("/Dashboard");
    else window.alert("Please Enter valid Email/password");
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#fff",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          width: "60%",
          height: "80%",
          backgroundImage: `url(${login})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          marginLeft: "5%",
          marginTop: "3%",
        }}
      ></div>
      <div style={{ width: "40%", height: "100%", marginTop: "3%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "85vh",
            width: 400,
            borderRadius: 10,
            backgroundColor: "#F3F3F3",
            boxShadow: "1px 3px 1px #CCC",
          }}
        >
          <text
            style={{
              fontSize: 40,
              fontFamily: "Trebuchet MS",
              color: "#747574",
              fontWeight: "bold",
              marginBottom: 50,
              marginTop: -20,
            }}
          >
            Login
          </text>

          <Input
            placeholder="Email"
            value={email}
            inputProps={ariaLabel}
            disableUnderline={true}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              borderRadius: 5,
              width: "78%",
              height: 38,
              display: "flex",
              alignSelf: "center",
              marginBottom: 30,
              paddingLeft: 5,
              backgroundColor: "#fff",
              fontSize: 12,
              color: "#666363",
            }}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#069" }}>
                <AccountCircleOutlinedIcon />
              </InputAdornment>
            }
          />
          <Input
            placeholder="Password"
            value={password}
            type="password"
            inputProps={ariaLabel}
            disableUnderline={true}
            onChange={(e) => setPassword(e.target.value)}
            secureEntry={true}
            style={{
              borderRadius: 5,
              width: "78%",
              height: 38,
              display: "flex",
              alignSelf: "center",
              marginBottom: 60,
              paddingLeft: 5,
              backgroundColor: "#fff",
              fontSize: 12,
              color: "#666363",
            }}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#069" }}>
                <LockOutlined />
              </InputAdornment>
            }
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: -35,
            }}
          >
            {/* <div>
              <Checkbox {...label} disabled checked size="small" />
              <text style={{ fontSize: 14 }}>Remember me</text>
            </div> */}
            <text
              style={{
                fontSize: 14,
                marginRight: 15,
                marginBottom: 2,
                color: "#069",
              }}
            >
              Forgot password?
            </text>
          </div>
          <Button
            onClick={() => authLogin()}
            variant="contained"
            size="medium"
            style={{
              backgroundColor: "#069",
              width: 170,
              display: "flex",
              alignSelf: "center",
              marginTop: 45,
              cursor: "pointer",
            }}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}
