export const elementsCustom = [
  {
    id: '2',
    type: 'special',
    position: { x: 60, y: 100 },
    isHidden: true,
    data: { text: 'Input FIle Node' }
  },
  {
    id: '3',
    type: 'special2',
    isHidden: true,
    position: { x: 500, y: 100 },
    data: { text: 'COpy Paste Node' }
  },
  {
    id: '4',
    type: 'special3',
    isHidden: true,
    position: { x: 100, y: 220 },
    data: { text: 'Select DataSource' }
  },
  {
    id: '5',
    type: 'special4',
    isHidden: true,
    position: { x: 850, y: 0 },
    data: { text: 'Url Node' }
  },
  {
    id: '6',
    type: 'special5',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Filter Node' }
  },
  {
    id: '7',
    type: 'special6',
    isHidden: true,
    position: { x: 350, y: 70 },
    data: { text: 'Merge Node' }
  },
  {
    id: '8',
    type: 'special7',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Group Node' }
  },
  {
    id: '9',
    type: 'special8',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Slice Node' }
  },
  {
    id: '10',
    type: 'special9',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Sort Node' }
  },
  {
    id: '11',
    type: 'special10',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Rename Column Node' }
  },
  {
    id: '12',
    type: 'special11',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Barchart Node' }
  },
  {
    id: '13',
    type: 'special12',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Histogram Node' }
  },
  {
    id: '14',
    type: 'special13',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Scatterplot Node' }
  },
  {
    id: '15',
    type: 'special14',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Timeseries Node' }
  },
  {
    id: '16',
    type: 'special15',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Stats Node' }
  },
  {
    id: '17',
    type: 'special16',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Export Node' }
  },
  {
    id: '20',
    type: 'special20',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Line and Scatter Plot',
      categories: [
        {
          label: 'Line and Scatter Plot',
          type: 'special20'
        },

        {
          label: 'Data Labels Hover',
          type: 'special21'
        },
        {
          label: 'Data Labels on the Plot',
          type: 'special22'
        },
        {
          label: 'Scatter Plot with a Color Dimension',
          type: 'special23'
        }
      ]
    }
  },
  {
    id: '21',
    type: 'special21',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Data Labels Hover',
      categories: [
        {
          label: 'Line and Scatter Plot',
          type: 'special20'
        },

        {
          label: 'Data Labels Hover',
          type: 'special21'
        },
        {
          label: 'Data Labels on the Plot',
          type: 'special22'
        },
        {
          label: 'Scatter Plot with a Color Dimension',
          type: 'special23'
        }
      ]
    }
  },
  {
    id: '22',
    type: 'special22',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Data Labels on the Plot',
      categories: [
        {
          label: 'Line and Scatter Plot',
          type: 'special20'
        },

        {
          label: 'Data Labels Hover',
          type: 'special21'
        },
        {
          label: 'Data Labels on the Plot',
          type: 'special22'
        },
        {
          label: 'Scatter Plot with a Color Dimension',
          type: 'special23'
        }
      ]
    }
  },
  {
    id: '23',
    type: 'special23',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Scatter Plot with a Color Dimension',
      categories: [
        {
          label: 'Line and Scatter Plot',
          type: 'special20'
        },

        {
          label: 'Data Labels Hover',
          type: 'special21'
        },
        {
          label: 'Data Labels on the Plot',
          type: 'special22'
        },
        {
          label: 'Scatter Plot with a Color Dimension',
          type: 'special23'
        }
      ]
    }
  },
  {
    id: '24',
    type: 'special24',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Basic Line Plot',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },
  {
    id: '25',
    type: 'special25',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Line and Scatter Plot',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },

  {
    id: '26',
    type: 'special26',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Adding Names to Line and Scatter Plot',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },
  {
    id: '27',
    type: 'special27',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Line and Scatter Styling',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },

  {
    id: '28',
    type: 'special28',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Styling Line Plot',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },
  {
    id: '29',
    type: 'special29',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Colored and Styled Scatter Plot',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },
  {
    id: '30',
    type: 'special30',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Line Shape Options for Interpolation',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },
  {
    id: '31',
    type: 'special31',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Graph and Axes Titles',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },

  {
    id: '32',
    type: 'special32',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Line Dash',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },

  {
    id: '33',
    type: 'special33',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Connect Gaps Between Data',
      categories: [
        {
          label: 'Basic Line Plot',
          type: 'special24'
        },

        {
          label: 'Line and Scatter Plot',
          type: 'special25'
        },
        {
          label: 'Adding Names to Line and Scatter Plot',
          type: 'special26'
        },
        {
          label: 'Line and Scatter Styling',
          type: 'special27'
        },
        {
          label: 'Styling Line Plot',
          type: 'special28'
        },

        {
          label: 'Colored and Styled Scatter Plot',
          type: 'special29'
        },
        {
          label: 'Line Shape Options for Interpolation',
          type: 'special30'
        },
        {
          label: 'Graph and Axes Titles',
          type: 'special31'
        },
        {
          label: 'Line Dash',
          type: 'special32'
        },
        {
          label: 'Connect Gaps Between Data',
          type: 'special33'
        }
      ]
    }
  },
  {
    id: '34',
    type: 'special34',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Basic Bar Chart',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },

  {
    id: '35',
    type: 'special35',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Grouped Bar Chart',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },

  {
    id: '36',
    type: 'special36',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Stacked Bar Chart',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },

  {
    id: '37',
    type: 'special37',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Bar chart with Hover Text',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '38',
    type: 'special38',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Bar Charts with Direct Labels',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '39',
    type: 'special39',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Grouped Bar Chart with Direct Labels',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '40',
    type: 'special40',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Bar Chart with Rotated Labels',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '41',
    type: 'special41',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Customizing Individual Bar Colors',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '42',
    type: 'special42',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Customizing Individual Bar Widths',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '43',
    type: 'special43',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Customizing Individual Bar Base',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '44',
    type: 'special44',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Colored and Styled Bar Chart',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '45',
    type: 'special45',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Waterfall Bar Chart',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '46',
    type: 'special46',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Bar Chart with Relative Barmode',
      categories: [
        {
          label: 'Basic Bar Chart',
          type: 'special34'
        },

        {
          label: 'Grouped Bar Chart',
          type: 'special35'
        },
        {
          label: 'Stacked Bar Chart',
          type: 'special36'
        },
        {
          label: 'Bar chart with Hover Text',
          type: 'special37'
        },
        {
          label: 'Bar Charts with Direct Labels',
          type: 'special38'
        },

        {
          label: 'Grouped Bar Chart with Direct Labels',
          type: 'special39'
        },
        {
          label: 'Bar Chart with Rotated Labels',
          type: 'special40'
        },
        {
          label: 'Customizing Individual Bar Colors',
          type: 'special41'
        },
        {
          label: 'Customizing Individual Bar Widths',
          type: 'special42'
        },
        {
          label: 'Customizing Individual Bar Base',
          type: 'special43'
        },
        {
          label: 'Colored and Styled Bar Chart',
          type: 'special44'
        },
        {
          label: 'Waterfall Bar Chart',
          type: 'special45'
        },
        {
          label: 'Bar Chart with Relative Barmode',
          type: 'special46'
        }
      ]
    }
  },
  {
    id: '47',
    type: 'special47',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Basic Pie Chart',
      categories: [
        {
          label: 'Basic Pie Chart',
          type: 'special47'
        },

        {
          label: 'Pie Chart Subplots',
          type: 'special48'
        },
        {
          label: 'Donut Chart',
          type: 'special49'
        },
        {
          label: 'Automatically Adjust Margins',
          type: 'special50'
        },
        {
          label: 'Control Text Orientation Inside Pie Chart Sectors',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '48',
    type: 'special48',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Pie Chart Subplots',
      categories: [
        {
          label: 'Basic Pie Chart',
          type: 'special47'
        },

        {
          label: 'Pie Chart Subplots',
          type: 'special48'
        },
        {
          label: 'Donut Chart',
          type: 'special49'
        },
        {
          label: 'Automatically Adjust Margins',
          type: 'special50'
        },
        {
          label: 'Control Text Orientation Inside Pie Chart Sectors',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '49',
    type: 'special49',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Donut Chart',
      categories: [
        {
          label: 'Basic Pie Chart',
          type: 'special47'
        },

        {
          label: 'Pie Chart Subplots',
          type: 'special48'
        },
        {
          label: 'Donut Chart',
          type: 'special49'
        },
        {
          label: 'Automatically Adjust Margins',
          type: 'special50'
        },
        {
          label: 'Control Text Orientation Inside Pie Chart Sectors',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '50',
    type: 'special50',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Automatically Adjust Margins',
      categories: [
        {
          label: 'Basic Pie Chart',
          type: 'special47'
        },

        {
          label: 'Pie Chart Subplots',
          type: 'special48'
        },
        {
          label: 'Donut Chart',
          type: 'special49'
        },
        {
          label: 'Automatically Adjust Margins',
          type: 'special50'
        },
        {
          label: 'Control Text Orientation Inside Pie Chart Sectors',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '51',
    type: 'special51',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Control Text Orientation Inside Pie Chart Sectors',
      categories: [
        {
          label: 'Basic Pie Chart',
          type: 'special47'
        },

        {
          label: 'Pie Chart Subplots',
          type: 'special48'
        },
        {
          label: 'Donut Chart',
          type: 'special49'
        },
        {
          label: 'Automatically Adjust Margins',
          type: 'special50'
        },
        {
          label: 'Control Text Orientation Inside Pie Chart Sectors',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '52',
    type: 'special52',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Marker Size on Bubble Charts',
      categories: [
        {
          label: 'Marker Size on Bubble Charts',
          type: 'special52'
        },

        {
          label: 'Marker Size and Color on Bubble Charts',
          type: 'special53'
        },
        {
          label: 'Hover Text on Bubble Charts',
          type: 'special54'
        },
        {
          label: 'Bubble Size Scaling on Charts',
          type: 'special55'
        },
        {
          label: 'Marker Size, Color, and Symbol as an Array',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '53',
    type: 'special53',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Marker Size and Color on Bubble Charts',
      categories: [
        {
          label: 'Marker Size on Bubble Charts',
          type: 'special52'
        },

        {
          label: 'Marker Size and Color on Bubble Charts',
          type: 'special53'
        },
        {
          label: 'Hover Text on Bubble Charts',
          type: 'special54'
        },
        {
          label: 'Bubble Size Scaling on Charts',
          type: 'special55'
        },
        {
          label: 'Marker Size, Color, and Symbol as an Array',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '54',
    type: 'special54',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Hover Text on Bubble Charts',
      categories: [
        {
          label: 'Marker Size on Bubble Charts',
          type: 'special52'
        },

        {
          label: 'Marker Size and Color on Bubble Charts',
          type: 'special53'
        },
        {
          label: 'Hover Text on Bubble Charts',
          type: 'special54'
        },
        {
          label: 'Bubble Size Scaling on Charts',
          type: 'special55'
        },
        {
          label: 'Marker Size, Color, and Symbol as an Array',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '55',
    type: 'special55',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Bubble Size Scaling on Charts',
      categories: [
        {
          label: 'Marker Size on Bubble Charts',
          type: 'special52'
        },

        {
          label: 'Marker Size and Color on Bubble Charts',
          type: 'special53'
        },
        {
          label: 'Hover Text on Bubble Charts',
          type: 'special54'
        },
        {
          label: 'Bubble Size Scaling on Charts',
          type: 'special55'
        },
        {
          label: 'Marker Size, Color, and Symbol as an Array',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '56',
    type: 'special56',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Marker Size, Color, and Symbol as an Array',
      categories: [
        {
          label: 'Marker Size on Bubble Charts',
          type: 'special52'
        },

        {
          label: 'Marker Size and Color on Bubble Charts',
          type: 'special53'
        },
        {
          label: 'Hover Text on Bubble Charts',
          type: 'special54'
        },
        {
          label: 'Bubble Size Scaling on Charts',
          type: 'special55'
        },
        {
          label: 'Marker Size, Color, and Symbol as an Array',
          type: 'special56'
        }
      ]
    }
  },

  {
    id: '57',
    type: 'special57',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Categorical Dot Plot' }
  },
  {
    id: '58',
    type: 'special58',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Basic Overlaid Area Chart',
      categories: [
        {
          label: 'Basic Overlaid Area Chart',
          type: 'special58'
        },

        {
          label: 'Overlaid Area Chart Without Boundary Lines',
          type: 'special59'
        },
        {
          label: 'Stacked Area Chart',
          type: 'special60'
        },
        {
          label: 'Normalized Stacked Area Chart',
          type: 'special61'
        },
        {
          label: 'Select Hover Points',
          type: 'special62'
        }
      ]
    }
  },
  {
    id: '59',
    type: 'special59',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Overlaid Area Chart Without Boundary Lines',
      categories: [
        {
          label: 'Basic Overlaid Area Chart',
          type: 'special58'
        },

        {
          label: 'Overlaid Area Chart Without Boundary Lines',
          type: 'special59'
        },
        {
          label: 'Stacked Area Chart',
          type: 'special60'
        },
        {
          label: 'Normalized Stacked Area Chart',
          type: 'special61'
        },
        {
          label: 'Select Hover Points',
          type: 'special62'
        }
      ]
    }
  },
  {
    id: '60',
    type: 'special60',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Stacked Area Chart',
      categories: [
        {
          label: 'Basic Overlaid Area Chart',
          type: 'special58'
        },

        {
          label: 'Overlaid Area Chart Without Boundary Lines',
          type: 'special59'
        },
        {
          label: 'Stacked Area Chart',
          type: 'special60'
        },
        {
          label: 'Normalized Stacked Area Chart',
          type: 'special61'
        },
        {
          label: 'Select Hover Points',
          type: 'special62'
        }
      ]
    }
  },
  {
    id: '61',
    type: 'special61',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Normalized Stacked Area Chart',
      categories: [
        {
          label: 'Basic Overlaid Area Chart',
          type: 'special58'
        },

        {
          label: 'Overlaid Area Chart Without Boundary Lines',
          type: 'special59'
        },
        {
          label: 'Stacked Area Chart',
          type: 'special60'
        },
        {
          label: 'Normalized Stacked Area Chart',
          type: 'special61'
        },
        {
          label: 'Select Hover Points',
          type: 'special62'
        }
      ]
    }
  },
  {
    id: '62',
    type: 'special62',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Select Hover Points',
      categories: [
        {
          label: 'Basic Overlaid Area Chart',
          type: 'special58'
        },

        {
          label: 'Overlaid Area Chart Without Boundary Lines',
          type: 'special59'
        },
        {
          label: 'Stacked Area Chart',
          type: 'special60'
        },
        {
          label: 'Normalized Stacked Area Chart',
          type: 'special61'
        },
        {
          label: 'Select Hover Points',
          type: 'special62'
        }
      ]
    }
  },
  {
    id: '63',
    type: 'special63',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Basic Horizontal Bar Chart',
      categories: [
        {
          label: 'Basic Horizontal Bar Chart',
          type: 'special63'
        },

        {
          label: 'Colored Bar Chart',
          type: 'special64'
        },
        {
          label: 'Bar Chart with Line Plot',
          type: 'special65'
        }
      ]
    }
  },
  {
    id: '64',
    type: 'special64',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Colored Bar Chart',
      categories: [
        {
          label: 'Basic Horizontal Bar Chart',
          type: 'special63'
        },

        {
          label: 'Colored Bar Chart',
          type: 'special64'
        },
        {
          label: 'Bar Chart with Line Plot',
          type: 'special65'
        }
      ]
    }
  },
  {
    id: '65',
    type: 'special65',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Bar Chart with Line Plot',
      categories: [
        {
          label: 'Basic Horizontal Bar Chart',
          type: 'special63'
        },

        {
          label: 'Colored Bar Chart',
          type: 'special64'
        },
        {
          label: 'Bar Chart with Line Plot',
          type: 'special65'
        }
      ]
    }
  },
  {
    id: '66',
    type: 'special66',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Basic Point Cloud' }
  },
  {
    id: '67',
    type: 'special67',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Styled Point Cloud' }
  },
  // { id: '81', type: 'special81', isHidden: true, position: { x: 350, y: 0 }, data: { text: 'Merge Node' } },
  {
    id: '68',
    type: 'special68',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'Basic Sunburst chart',
      categories: [
        {
          label: 'Basic Sunburst chart',
          type: 'special68'
        },
        {
          label: 'SunBurst BranchValues',
          type: 'special69'
        },
        {
          label: 'SunBurst with Repeated Labels',
          type: 'special70'
        },
        {
          label: 'SunBurst with large number of slices',
          type: 'special71'
        },
        {
          label: 'orientation inside subchart sectors',
          type: 'special72'
        }
      ]
    }
  },
  {
    id: '69',
    type: 'special69',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'SunBurst BranchValues',
      categories: [
        {
          label: 'Basic Sunburst chart',
          type: 'special68'
        },
        {
          label: 'SunBurst BranchValues',
          type: 'special69'
        },
        {
          label: 'SunBurst with Repeated Labels',
          type: 'special70'
        },
        {
          label: 'SunBurst with large number of slices',
          type: 'special71'
        },
        {
          label: 'orientation inside subchart sectors',
          type: 'special72'
        }
      ]
    }
  },
  {
    id: '70',
    type: 'special70',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'SunBurst with Repeated Labels',
      categories: [
        {
          label: 'Basic Sunburst chart',
          type: 'special68'
        },
        {
          label: 'SunBurst BranchValues',
          type: 'special69'
        },
        {
          label: 'SunBurst with Repeated Labels',
          type: 'special70'
        },
        {
          label: 'SunBurst with large number of slices',
          type: 'special71'
        },
        {
          label: 'orientation inside subchart sectors',
          type: 'special72'
        }
      ]
    }
  },
  {
    id: '71',
    type: 'special71',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'SunBurst with large number of slices',
      categories: [
        {
          label: 'Basic Sunburst chart',
          type: 'special68'
        },
        {
          label: 'SunBurst BranchValues',
          type: 'special69'
        },
        {
          label: 'SunBurst with Repeated Labels',
          type: 'special70'
        },
        {
          label: 'SunBurst with large number of slices',
          type: 'special71'
        },
        {
          label: 'orientation inside subchart sectors',
          type: 'special72'
        }
      ]
    }
  },
  {
    id: '72',
    type: 'special72',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: {
      text: 'orientation inside subchart sectorst',
      categories: [
        {
          label: 'Basic Sunburst chart',
          type: 'special68'
        },
        {
          label: 'SunBurst BranchValues',
          type: 'special69'
        },
        {
          label: 'SunBurst with Repeated Labels',
          type: 'special70'
        },
        {
          label: 'SunBurst with large number of slices',
          type: 'special71'
        },
        {
          label: 'Orientation inside subchart sectors',
          type: 'special72'
        }
      ]
    }
  },
  {
    id: '73',
    type: 'special73',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Sankey Diagram' }
  },
  {
    id: '77',
    type: 'special77',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Style Sankey Diagram' }
  },
  {
    id: '382',
    type: 'special382',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Duplicate Check' }
  },
  {
    id: '383',
    type: 'special383',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Fuzzy Search' }
  },
  {
    id: '384',
    type: 'special384',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Standardization' }
  },
  {
    id: '385',
    type: 'special385',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Replace Null' }
  },
  {
    id: '386',
    type: 'special386',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'InComplete' }
  },
  {
    id: '387',
    type: 'special387',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Formatted' }
  },
  {
    id: '388',
    type: 'special388',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Case Format' }
  },
  {
    id: '389',
    type: 'special389',
    isHidden: true,
    position: { x: 350, y: 0 },
    data: { text: 'Discreet Range' }
  },

  {
    id: '390',
    type: 'special390',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Number Profiler' }
  },

  {
    id: '81',
    type: 'special81',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Symmetric Error Bars',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },
  {
    id: '82',
    type: 'special82',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Bar Chart with Error Bars',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },

  {
    id: '83',
    type: 'special83',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Horizontal Error Bars',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },
  {
    id: '84',
    type: 'special84',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Asymmetric Error Bars',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },
  {
    id: '85',
    type: 'special85',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Colored and Styled Error Bars',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },
  {
    id: '86',
    type: 'special86',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Error Bars as a Percentage of the y-Value',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },
  {
    id: '87',
    type: 'special87',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Asymmetric Error Bars with a Constant Offset',
      categories: [
        {
          label: 'Basic Symmetric Error Bars',
          type: 'special81'
        },

        {
          label: 'Bar Chart with Error Bars',
          type: 'special82'
        },
        {
          label: 'Horizontal Error Bars',
          type: 'special83'
        },
        {
          label: 'Asymmetric Error Bars',
          type: 'special84'
        },

        {
          label: 'Colored and Styled Error Bars',
          type: 'special85'
        },
        {
          label: 'Error Bars as a Percentage of the y-Value',
          type: 'special86'
        },
        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special87'
        }
      ]
    }
  },
  {
    id: '88',
    type: 'special88',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '89',
    type: 'special89',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Box Plot That Displays the Underlying Data',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '90',
    type: 'special90',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Horizontal Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '91',
    type: 'special91',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Grouped Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '92',
    type: 'special92',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Box Plot Styling Outliners',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '93',
    type: 'special93',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Box Plot Styling Mean and Standard Deviation',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '94',
    type: 'special94',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Grouped Horizontal Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '95',
    type: 'special95',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Colored Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '96',
    type: 'special96',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Fully Styled Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '97',
    type: 'special97',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Rainbow Box Plot',
      categories: [
        {
          label: 'Basic Box Plot',
          type: 'special88'
        },

        {
          label: 'Box Plot That Displays the Underlying Data',
          type: 'special89'
        },
        {
          label: 'Horizontal Box Plot',
          type: 'special90'
        },
        {
          label: 'Grouped Box Plot',
          type: 'special91'
        },

        {
          label: 'Box Plot Styling Outliners',
          type: 'special92'
        },
        {
          label: 'Box Plot Styling Mean and Standard Deviation',
          type: 'special93'
        },
        {
          label: 'Grouped Horizontal Box Plot',
          type: 'special94'
        },
        {
          label: 'Colored Box Plot',
          type: 'special95'
        },
        // {
        //   label: 'Fully Styled Box Plot',
        //   type: 'special96'
        // },
        {
          label: 'Rainbow Box Plot',
          type: 'special97'
        }
      ]
    }
  },
  {
    id: '98',
    type: 'special98',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Histogram',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '99',
    type: 'special99',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Horizontal Histogram',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '100',
    type: 'special100',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Overlaid Histogram',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '101',
    type: 'special101',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Stacked Histograms',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '102',
    type: 'special102',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Colored and Styled Histograms',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '103',
    type: 'special103',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Cumulative Histogram',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '104',
    type: 'special104',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Normalized Histogram',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '105',
    type: 'special105',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Specify Binning Function',
      categories: [
        {
          label: 'Basic Histogram',
          type: 'special98'
        },

        {
          label: 'Horizontal Histogram',
          type: 'special99'
        },
        {
          label: 'Overlaid Histogram',
          type: 'special100'
        },
        {
          label: 'Stacked Histograms',
          type: 'special101'
        },

        {
          label: 'Colored and Styled Histograms',
          type: 'special102'
        },
        {
          label: 'Cumulative Histogram',
          type: 'special103'
        },
        {
          label: 'Normalized Histogram',
          type: 'special104'
        },
        {
          label: 'Specify Binning Function',
          type: 'special105'
        }
      ]
    }
  },
  {
    id: '106',
    type: 'special106',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '2D Histogram Contour Plot with Histogram Subplots',
      categories: [
        {
          label: '2D Histogram Contour Plot with Histogram Subplots',
          type: 'special106'
        },

        {
          label: '2D Histogram Contour Plot with Slider Control',
          type: 'special07'
        }
      ]
    }
  },
  {
    id: '107',
    type: 'special107',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '2D Histogram Contour Plot with Slider Control',
      categories: [
        {
          label: '2D Histogram Contour Plot with Histogram Subplots',
          type: 'special106'
        },

        {
          label: '2D Histogram Contour Plot with Slider Control',
          type: 'special07'
        }
      ]
    }
  },
  {
    id: '108',
    type: 'special108',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Filled Lines',
      categories: [
        {
          label: 'Filled Lines',
          type: 'special108'
        },

        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special08'
        }
      ]
    }
  },
  {
    id: '109',
    type: 'special109',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Asymmetric Error Bars with a Constant Offset',
      categories: [
        {
          label: 'Filled Lines',
          type: 'special108'
        },

        {
          label: 'Asymmetric Error Bars with a Constant Offset',
          type: 'special08'
        }
      ]
    }
  },
  {
    id: '110',
    type: 'special110',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Simple Contour Plot',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '111',
    type: 'special111',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Contour Plot',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '112',
    type: 'special112',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Setting X and Y Coordinates in a Contour Plot',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '113',
    type: 'special113',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Color Scale for Contour Plot',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '114',
    type: 'special114',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Customizing Size and Range of a Contour Plot Contours',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '115',
    type: 'special115',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Customizing Spacing Between X and Y Ticks',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '116',
    type: 'special116',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Connect the Gaps between Null Values in the Z Matrix',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },

  {
    id: '120',
    type: 'special120',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Smoothing Contour Lines',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '121',
    type: 'special121',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Smooth Contour Coloring',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '122',
    type: 'special122',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Contour Lines',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '123',
    type: 'special123',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Contour Line Labels',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '124',
    type: 'special124',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Custom Color Scale for Contour Plot',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '125',
    type: 'special125',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Color Bar Title',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '126',
    type: 'special126',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Color Bar size',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '127',
    type: 'special127',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Styling Color Bar Ticks for Contour Plots',
      categories: [
        {
          label: 'Simple Contour Plot',
          type: 'special110'
        },

        {
          label: 'Basic Contour Plot',
          type: 'special111'
        },
        {
          label: 'Setting X and Y Coordinates in a Contour Plot',
          type: 'special112'
        },

        {
          label: 'Color Scale for Contour Plot',
          type: 'special13'
        },
        {
          label: 'Customizing Size and Range of a Contour Plots Contours',
          type: 'special114'
        },

        {
          label: 'Customizing Spacing Between X and Y Ticks',
          type: 'special115'
        },
        {
          label: 'Connect the Gaps between Null Values in the Z Matrix',
          type: 'special116'
        },

        {
          label: 'Smoothing Contour Lines',
          type: 'special117'
        },
        {
          label: 'Smooth Contour Coloring',
          type: 'special121'
        },

        {
          label: 'Contour Lines',
          type: 'special22'
        },
        {
          label: 'Contour Line Labels',
          type: 'special123'
        },

        {
          label: 'Custom Color Scale for Contour Plot',
          type: 'special24'
        },
        {
          label: 'Color Bar Title',
          type: 'special125'
        },

        {
          label: 'Color Bar size',
          type: 'special26'
        },
        {
          label: 'Styling Color Bar Ticks for Contour Plots',
          type: 'special27'
        }
      ]
    }
  },
  {
    id: '128',
    type: 'special128',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Heatmap',
      categories: [
        {
          label: 'Basic Heatmap',
          type: 'special128'
        },

        {
          label: 'Heatmap with Categorical Axis Labels',
          type: 'special129'
        },
        {
          label: 'Annotated Heatmap',
          type: 'special130'
        },

        {
          label: 'Heatmap with Unequal Block Sizes',
          type: 'special31'
        }
      ]
    }
  },
  {
    id: '129',
    type: 'special129',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Heatmap with Categorical Axis Labels',
      categories: [
        {
          label: 'Basic Heatmap',
          type: 'special128'
        },

        {
          label: 'Heatmap with Categorical Axis Labels',
          type: 'special129'
        },
        {
          label: 'Annotated Heatmap',
          type: 'special130'
        },

        {
          label: 'Heatmap with Unequal Block Sizes',
          type: 'special31'
        }
      ]
    }
  },
  {
    id: '130',
    type: 'special130',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Annotated Heatmap',
      categories: [
        {
          label: 'Basic Heatmap',
          type: 'special128'
        },

        {
          label: 'Heatmap with Categorical Axis Labels',
          type: 'special129'
        },
        {
          label: 'Annotated Heatmap',
          type: 'special130'
        },

        {
          label: 'Heatmap with Unequal Block Sizes',
          type: 'special31'
        }
      ]
    }
  },
  {
    id: '131',
    type: 'special131',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Heatmap with Unequal Block Sizes',
      categories: [
        {
          label: 'Basic Heatmap',
          type: 'special128'
        },

        {
          label: 'Heatmap with Categorical Axis Labels',
          type: 'special129'
        },
        {
          label: 'Annotated Heatmap',
          type: 'special130'
        },

        {
          label: 'Heatmap with Unequal Block Sizes',
          type: 'special31'
        }
      ]
    }
  },
  {
    id: '132',
    type: 'special132',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Ternary Plot with Markers',
      categories: [
        {
          label: 'Basic Ternary Plot with Markers',
          type: 'special132'
        },

        {
          label: 'Soil Types Ternary Plot',
          type: 'special133'
        }
      ]
    }
  },
  {
    id: '133',
    type: 'special133',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Soil Types Ternary Plot',
      categories: [
        {
          label: 'Basic Ternary Plot with Markers',
          type: 'special108'
        },

        {
          label: 'Soil Types Ternary Plot',
          type: 'special109'
        }
      ]
    }
  },
  {
    id: '134',
    type: 'special134',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Adding Dimensions',
      categories: [
        {
          label: 'Adding Dimensions',
          type: 'special134'
        },

        {
          label: 'Basic Parallel Coordinates Plot',
          type: 'special135'
        },
        {
          label: 'Annotated Parallel Coordinates Plot',
          type: 'special136'
        },

        {
          label: 'Advanced Parallel Coordinates Plot',
          type: 'special37'
        }
      ]
    }
  },
  {
    id: '135',
    type: 'special135',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Parallel Coordinates Plot',
      categories: [
        {
          label: 'Adding Dimensions',
          type: 'special134'
        },

        {
          label: 'Basic Parallel Coordinates Plot',
          type: 'special135'
        },
        {
          label: 'Annotated Parallel Coordinates Plot',
          type: 'special136'
        },

        {
          label: 'Advanced Parallel Coordinates Plot',
          type: 'special37'
        }
      ]
    }
  },
  {
    id: '136',
    type: 'special136',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Annotated Parallel Coordinates Plot',
      categories: [
        {
          label: 'Adding Dimensions',
          type: 'special134'
        },

        {
          label: 'Basic Parallel Coordinates Plot',
          type: 'special135'
        },
        {
          label: 'Annotated Parallel Coordinates Plot',
          type: 'special136'
        },

        {
          label: 'Advanced Parallel Coordinates Plot',
          type: 'special37'
        }
      ]
    }
  },
  {
    id: '137',
    type: 'special137',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Advanced Parallel Coordinates Plot',
      categories: [
        {
          label: 'Adding Dimensions',
          type: 'special134'
        },

        {
          label: 'Basic Parallel Coordinates Plot',
          type: 'special135'
        },
        {
          label: 'Annotated Parallel Coordinates Plot',
          type: 'special136'
        },

        {
          label: 'Advanced Parallel Coordinates Plot',
          type: 'special37'
        }
      ]
    }
  },
  {
    id: '138',
    type: 'special138',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Logarithmic Axes' }
  },
  {
    id: '139',
    type: 'special139',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Waterfall Chart',
      categories: [
        {
          label: 'Basic Waterfall Chart',
          type: 'special139'
        },

        {
          label: 'Multi Category Waterfall Chart',
          type: 'special140'
        },
        {
          label: 'Horizontal Waterfall Chart',
          type: 'special141'
        },

        {
          label: 'Style Waterfall Chart',
          type: 'special42'
        }
      ]
    }
  },
  {
    id: '140',
    type: 'special140',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Multi Category Waterfall Chart',
      categories: [
        {
          label: 'Basic Waterfall Chart',
          type: 'special139'
        },

        {
          label: 'Multi Category Waterfall Chart',
          type: 'special140'
        },
        {
          label: 'Horizontal Waterfall Chart',
          type: 'special141'
        },

        {
          label: 'Style Waterfall Chart',
          type: 'special42'
        }
      ]
    }
  },
  {
    id: '141',
    type: 'special141',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Horizontal Waterfall Chart',
      categories: [
        {
          label: 'Basic Waterfall Chart',
          type: 'special139'
        },

        {
          label: 'Multi Category Waterfall Chart',
          type: 'special140'
        },
        {
          label: 'Horizontal Waterfall Chart',
          type: 'special141'
        },

        {
          label: 'Style Waterfall Chart',
          type: 'special42'
        }
      ]
    }
  },
  {
    id: '142',
    type: 'special142',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Style Waterfall Chart',
      categories: [
        {
          label: 'Basic Waterfall Chart',
          type: 'special139'
        },

        {
          label: 'Multi Category Waterfall Chart',
          type: 'special140'
        },
        {
          label: 'Horizontal Waterfall Chart',
          type: 'special141'
        },

        {
          label: 'Style Waterfall Chart',
          type: 'special42'
        }
      ]
    }
  },
  {
    id: '143',
    type: 'special143',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '144',
    type: 'special144',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '145',
    type: 'special145',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '146',
    type: 'special146',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '147',
    type: 'special147',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Simple Candlestick Chart',
      categories: [
        {
          label: 'Simple Candlestick Chart',
          type: 'special147'
        },

        {
          label: 'Candlestick Chart without Rangeslider',
          type: 'special148'
        },
        {
          label: 'Customise Candlestick Chart with Shapes and Annotations',
          type: 'special149'
        },

        {
          label: 'Customizing Candlestick Chart Colors',
          type: 'special50'
        },
        {
          label: 'Add Range Selector',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '148',
    type: 'special148',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Candlestick Chart without Rangeslider',
      categories: [
        {
          label: 'Simple Candlestick Chart',
          type: 'special147'
        },

        {
          label: 'Candlestick Chart without Rangeslider',
          type: 'special148'
        },
        {
          label: 'Customise Candlestick Chart with Shapes and Annotations',
          type: 'special149'
        },

        {
          label: 'Customizing Candlestick Chart Colors',
          type: 'special50'
        },
        {
          label: 'Add Range Selector',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '149',
    type: 'special149',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Customise Candlestick Chart with Shapes and Annotations',
      categories: [
        {
          label: 'Simple Candlestick Chart',
          type: 'special147'
        },

        {
          label: 'Candlestick Chart without Rangeslider',
          type: 'special148'
        },
        {
          label: 'Customise Candlestick Chart with Shapes and Annotations',
          type: 'special149'
        },

        {
          label: 'Customizing Candlestick Chart Colors',
          type: 'special50'
        },
        {
          label: 'Add Range Selector',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '150',
    type: 'special150',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Customizing Candlestick Chart Colors',
      categories: [
        {
          label: 'Simple Candlestick Chart',
          type: 'special147'
        },

        {
          label: 'Candlestick Chart without Rangeslider',
          type: 'special148'
        },
        {
          label: 'Customise Candlestick Chart with Shapes and Annotations',
          type: 'special149'
        },

        {
          label: 'Customizing Candlestick Chart Colors',
          type: 'special50'
        },
        {
          label: 'Add Range Selector',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '151',
    type: 'special151',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Add Range Selector',
      categories: [
        {
          label: 'Simple Candlestick Chart',
          type: 'special147'
        },

        {
          label: 'Candlestick Chart without Rangeslider',
          type: 'special148'
        },
        {
          label: 'Customise Candlestick Chart with Shapes and Annotations',
          type: 'special149'
        },

        {
          label: 'Customizing Candlestick Chart Colors',
          type: 'special50'
        },
        {
          label: 'Add Range Selector',
          type: 'special51'
        }
      ]
    }
  },
  {
    id: '152',
    type: 'special152',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Funnel Plot',
      categories: [
        {
          label: 'Basic Funnel Plot',
          type: 'special152'
        },

        {
          label: 'Setting Marker Size and Color',
          type: 'special153'
        },
        {
          label: 'Stacked Funnel',
          type: 'special154'
        },

        {
          label: 'Funnel Area Plot',
          type: 'special55'
        },
        {
          label: 'Multi Funnelarea',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '153',
    type: 'special153',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Setting Marker Size and Color',
      categories: [
        {
          label: 'Basic Funnel Plot',
          type: 'special152'
        },

        {
          label: 'Setting Marker Size and Color',
          type: 'special153'
        },
        {
          label: 'Stacked Funnel',
          type: 'special154'
        },

        {
          label: 'Funnel Area Plot',
          type: 'special55'
        },
        {
          label: 'Multi Funnelarea',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '154',
    type: 'special154',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Stacked Funnel',
      categories: [
        {
          label: 'Basic Funnel Plot',
          type: 'special152'
        },

        {
          label: 'Setting Marker Size and Color',
          type: 'special153'
        },
        {
          label: 'Stacked Funnel',
          type: 'special154'
        },

        {
          label: 'Funnel Area Plot',
          type: 'special55'
        },
        {
          label: 'Multi Funnelarea',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '155',
    type: 'special155',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Funnel Area Plot',
      categories: [
        {
          label: 'Basic Funnel Plot',
          type: 'special152'
        },

        {
          label: 'Setting Marker Size and Color',
          type: 'special153'
        },
        {
          label: 'Stacked Funnel',
          type: 'special154'
        },

        {
          label: 'Funnel Area Plot',
          type: 'special55'
        },
        {
          label: 'Multi Funnelarea',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '156',
    type: 'special156',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Multi Funnelarea',
      categories: [
        {
          label: 'Basic Funnel Plot',
          type: 'special152'
        },

        {
          label: 'Setting Marker Size and Color',
          type: 'special153'
        },
        {
          label: 'Stacked Funnel',
          type: 'special154'
        },

        {
          label: 'Funnel Area Plot',
          type: 'special55'
        },
        {
          label: 'Multi Funnelarea',
          type: 'special56'
        }
      ]
    }
  },
  {
    id: '157',
    type: 'special157',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Date Strings',
      categories: [
        {
          label: 'Date Strings',
          type: 'special157'
        },

        {
          label: 'Basic Time Series',
          type: 'special158'
        },
        {
          label: 'Manually Set Range',
          type: 'special159'
        },

        {
          label: 'Time Series with Rangeslider',
          type: 'special60'
        }
      ]
    }
  },
  {
    id: '158',
    type: 'special158',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Time Series',
      categories: [
        {
          label: 'Date Strings',
          type: 'special157'
        },

        {
          label: 'Basic Time Series',
          type: 'special158'
        },
        {
          label: 'Manually Set Range',
          type: 'special159'
        },

        {
          label: 'Time Series with Rangeslider',
          type: 'special60'
        }
      ]
    }
  },
  {
    id: '159',
    type: 'special159',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Manually Set Range',
      categories: [
        {
          label: 'Date Strings',
          type: 'special157'
        },

        {
          label: 'Basic Time Series',
          type: 'special158'
        },
        {
          label: 'Manually Set Range',
          type: 'special159'
        },

        {
          label: 'Time Series with Rangeslider',
          type: 'special60'
        }
      ]
    }
  },
  {
    id: '160',
    type: 'special160',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Time Series with Rangeslider',
      categories: [
        {
          label: 'Date Strings',
          type: 'special157'
        },

        {
          label: 'Basic Time Series',
          type: 'special158'
        },
        {
          label: 'Manually Set Range',
          type: 'special159'
        },

        {
          label: 'Time Series with Rangeslider',
          type: 'special60'
        }
      ]
    }
  },
  {
    id: '161',
    type: 'special161',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '3D Scatter Plot' }
  },
  {
    id: '162',
    type: 'special162',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Basic Ribbon Plot' }
  },
  {
    id: '163',
    type: 'special163',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Topographical 3D Surface Plot',
      categories: [
        {
          label: 'Topographical 3D Surface Plot',
          type: 'special163'
        },

        {
          label: 'Surface Plot With Contours',
          type: 'special164'
        },
        {
          label: 'Multiple 3D Surface Plots',
          type: 'special165'
        }
      ]
    }
  },
  {
    id: '164',
    type: 'special164',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Surface Plot With Contours',
      categories: [
        {
          label: 'Topographical 3D Surface Plot',
          type: 'special163'
        },

        {
          label: 'Surface Plot With Contours',
          type: 'special164'
        },
        {
          label: 'Multiple 3D Surface Plots',
          type: 'special165'
        }
      ]
    }
  },
  {
    id: '165',
    type: 'special165',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Multiple 3D Surface Plots',
      categories: [
        {
          label: 'Topographical 3D Surface Plot',
          type: 'special163'
        },

        {
          label: 'Surface Plot With Contours',
          type: 'special164'
        },
        {
          label: 'Multiple 3D Surface Plots',
          type: 'special165'
        }
      ]
    }
  },
  {
    id: '166',
    type: 'special166',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Simple 3D Mesh Plot',
      categories: [
        {
          label: 'Simple 3D Mesh Plot',
          type: 'special166'
        },

        {
          label: '3D Mesh Plot with Alphahull',
          type: 'special167'
        },
        {
          label: '3D Mesh Tetrahedronapp',
          type: 'special168'
        },
        {
          label: '3D Mesh Cube',
          type: 'special169'
        }
      ]
    }
  },
  {
    id: '167',
    type: 'special167',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Mesh Plot with Alphahull',
      categories: [
        {
          label: 'Simple 3D Mesh Plot',
          type: 'special166'
        },

        {
          label: '3D Mesh Plot with Alphahull',
          type: 'special167'
        },
        {
          label: '3D Mesh Tetrahedronapp',
          type: 'special168'
        },
        {
          label: '3D Mesh Cube',
          type: 'special169'
        }
      ]
    }
  },
  {
    id: '168',
    type: 'special168',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Mesh Tetrahedronapp',
      categories: [
        {
          label: 'Simple 3D Mesh Plot',
          type: 'special166'
        },

        {
          label: '3D Mesh Plot with Alphahull',
          type: 'special167'
        },
        {
          label: '3D Mesh Tetrahedronapp',
          type: 'special168'
        },
        {
          label: '3D Mesh Cube',
          type: 'special169'
        }
      ]
    }
  },
  {
    id: '169',
    type: 'special169',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Mesh Cube',
      categories: [
        {
          label: 'Simple 3D Mesh Plot',
          type: 'special166'
        },

        {
          label: '3D Mesh Plot with Alphahull',
          type: 'special167'
        },
        {
          label: '3D Mesh Tetrahedronapp',
          type: 'special168'
        },
        {
          label: '3D Mesh Cube',
          type: 'special169'
        }
      ]
    }
  },
  {
    id: '170',
    type: 'special170',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Line Plot',
      categories: [
        {
          label: '3D Line Plot',
          type: 'special170'
        },

        {
          label: '3D Line + Markers Plot',
          type: 'special171'
        },
        {
          label: '3D Line Spiral Plot',
          type: 'special172'
        },
        {
          label: '3D Random Walk Plot',
          type: 'special173'
        }
      ]
    }
  },
  {
    id: '171',
    type: 'special171',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Line + Markers Plot',
      categories: [
        {
          label: '3D Line Plot',
          type: 'special170'
        },

        {
          label: '3D Line + Markers Plot',
          type: 'special171'
        },
        {
          label: '3D Line Spiral Plot',
          type: 'special172'
        },
        {
          label: '3D Random Walk Plot',
          type: 'special173'
        }
      ]
    }
  },
  {
    id: '172',
    type: 'special172',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Line Spiral Plot',
      categories: [
        {
          label: '3D Line Plot',
          type: 'special170'
        },

        {
          label: '3D Line + Markers Plot',
          type: 'special171'
        },
        {
          label: '3D Line Spiral Plot',
          type: 'special172'
        },
        {
          label: '3D Random Walk Plot',
          type: 'special173'
        }
      ]
    }
  },
  {
    id: '173',
    type: 'special173',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: '3D Random Walk Plot',
      categories: [
        {
          label: '3D Line Plot',
          type: 'special170'
        },

        {
          label: '3D Line + Markers Plot',
          type: 'special171'
        },
        {
          label: '3D Line Spiral Plot',
          type: 'special172'
        },
        {
          label: '3D Random Walk Plot',
          type: 'special173'
        }
      ]
    }
  },
  {
    id: '174',
    type: 'special174',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Simple Subplot',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special89'
        }
      ]
    }
  },
  {
    id: '175',
    type: 'special175',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Custom Sized Subplot',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special89'
        }
      ]
    }
  },
  {
    id: '176',
    type: 'special176',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Multiple Subplots',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special89'
        }
      ]
    }
  },
  {
    id: '177',
    type: 'special177',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Subplots with Shared Axes',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special89'
        }
      ]
    }
  },
  {
    id: '178',
    type: 'special178',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Stacked Subplots',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special89'
        }
      ]
    }
  },
  {
    id: '179',
    type: 'special179',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Stacked Subplots with a Shared X-Axis',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special89'
        }
      ]
    }
  },
  {
    id: '180',
    type: 'special180',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Multiple Custom Sized Subplots',
      categories: [
        {
          label: 'Simple Subplot',
          type: 'special174'
        },

        {
          label: 'Custom Sized Subplot',
          type: 'special175'
        },
        {
          label: 'Multiple Subplots',
          type: 'special176'
        },

        {
          label: 'Subplots with Shared Axesime Series with Rangeslider',
          type: 'special77'
        },
        {
          label: 'Stacked Subplots',
          type: 'special78'
        },
        {
          label: 'Stacked Subplots with a Shared X-Axis',
          type: 'special79'
        },
        {
          label: 'Multiple Custom Sized Subplots',
          type: 'special80'
        }
      ]
    }
  },
  {
    id: '181',
    type: 'special181',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Simple Inset Plot' }
  },
  {
    id: '182',
    type: 'special182',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Multiple 3D Subplots' }
  },
  {
    id: '183',
    type: 'special183',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Mixed Subplots' }
  },
  {
    id: '184',
    type: 'special184',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Table and Chart Subplot' }
  },
  {
    id: '185',
    type: 'special185',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Click Event Data',
      categories: [
        {
          label: 'Click Event Data',
          type: 'special185'
        },

        {
          label: 'Binding to Click Events',
          type: 'special186'
        },
        {
          label: 'Create annotation on click event',
          type: 'special187'
        }
      ]
    }
  },
  {
    id: '186',
    type: 'special186',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Binding to Click Events',
      categories: [
        {
          label: 'Click Event Data',
          type: 'special185'
        },

        {
          label: 'Binding to Click Events',
          type: 'special186'
        },
        {
          label: 'Create annotation on click event',
          type: 'special187'
        }
      ]
    }
  },
  {
    id: '187',
    type: 'special187',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Create annotation on click event',
      categories: [
        {
          label: 'Click Event Data',
          type: 'special185'
        },

        {
          label: 'Binding to Click Events',
          type: 'special186'
        },
        {
          label: 'Create annotation on click event',
          type: 'special187'
        }
      ]
    }
  },
  {
    id: '188',
    type: 'special188',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Hover Event Data',
      categories: [
        {
          label: 'Hover Event Data',
          type: 'special188'
        },

        {
          label: 'Capturing Hover Events: Data',
          type: 'special189'
        },
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special190'
        },
        {
          label: 'Triggering Hover Events',
          type: 'special191'
        },
        {
          label: 'Coupled Hover Events',
          type: 'special192'
        },
        {
          label: 'Combined Click and Hover Events',
          type: 'special193'
        }
      ]
    }
  },
  {
    id: '189',
    type: 'special189',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Capturing Hover Events: Data',
      categories: [
        {
          label: 'Hover Event Data',
          type: 'special188'
        },

        {
          label: 'Capturing Hover Events: Data',
          type: 'special189'
        },
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special190'
        },
        {
          label: 'Triggering Hover Events',
          type: 'special191'
        },
        {
          label: 'Coupled Hover Events',
          type: 'special192'
        },
        {
          label: 'Combined Click and Hover Events',
          type: 'special193'
        }
      ]
    }
  },
  {
    id: '190',
    type: 'special190',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Capturing Hover Events: Pixels',
      categories: [
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special188'
        },

        {
          label: 'Capturing Hover Events: Data',
          type: 'special189'
        },
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special190'
        },
        {
          label: 'Triggering Hover Events',
          type: 'special191'
        },
        {
          label: 'Coupled Hover Events',
          type: 'special192'
        },
        {
          label: 'Combined Click and Hover Events',
          type: 'special193'
        }
      ]
    }
  },
  {
    id: '191',
    type: 'special191',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Triggering Hover Events',
      categories: [
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special188'
        },

        {
          label: 'Capturing Hover Events: Data',
          type: 'special189'
        },
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special190'
        },
        {
          label: 'Triggering Hover Events',
          type: 'special191'
        },
        {
          label: 'Coupled Hover Events',
          type: 'special192'
        },
        {
          label: 'Combined Click and Hover Events',
          type: 'special193'
        }
      ]
    }
  },
  {
    id: '192',
    type: 'special192',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Coupled Hover Events',
      categories: [
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special188'
        },

        {
          label: 'Capturing Hover Events: Data',
          type: 'special189'
        },
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special190'
        },
        {
          label: 'Triggering Hover Events',
          type: 'special191'
        },
        {
          label: 'Coupled Hover Events',
          type: 'special192'
        },
        {
          label: 'Combined Click and Hover Events',
          type: 'special193'
        }
      ]
    }
  },
  {
    id: '193',
    type: 'special193',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Combined Click and Hover Events',
      categories: [
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special188'
        },

        {
          label: 'Capturing Hover Events: Data',
          type: 'special189'
        },
        {
          label: 'Capturing Hover Events: Pixels',
          type: 'special190'
        },
        {
          label: 'Triggering Hover Events',
          type: 'special191'
        },
        {
          label: 'Coupled Hover Events',
          type: 'special192'
        },
        {
          label: 'Combined Click and Hover Events',
          type: 'special193'
        }
      ]
    }
  },
  {
    id: '194',
    type: 'special194',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Binding to Zoom Events' }
  },
  {
    id: '195',
    type: 'special195',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Disabling Zoom Events for X Axis',
      categories: [
        {
          label: 'Disabling Zoom Events for X Axis',
          type: 'special195'
        },

        {
          label: 'Disabling Zoom Events for X and Y Axis',
          type: 'special196'
        }
      ]
    }
  },
  {
    id: '196',
    type: 'special196',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Disabling Zoom Events for X and Y Axis',
      categories: [
        {
          label: 'Disabling Zoom Events for X Axis',
          type: 'special195'
        },

        {
          label: 'Disabling Zoom Events for X and Y Axis',
          type: 'special196'
        }
      ]
    }
  },
  {
    id: '197',
    type: 'special197',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Filter' }
  },
  {
    id: '198',
    type: 'special198',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Groupby' }
  },
  {
    id: '199',
    type: 'special199',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Aggregations',
      categories: [
        {
          label: 'Aggregations',
          type: 'special199'
        },

        {
          label: 'Aggregate Functions',
          type: 'special200'
        },

        {
          label: 'Histogram Binning',
          type: 'special201'
        },
        {
          label: 'Mapping with Aggregates',
          type: 'special202'
        }
      ]
    }
  },
  {
    id: '200',
    type: 'special200',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Aggregate Functions',
      categories: [
        {
          label: 'Aggregations',
          type: 'special199'
        },

        {
          label: 'Aggregate Functions',
          type: 'special200'
        },

        {
          label: 'Histogram Binning',
          type: 'special201'
        },
        {
          label: 'Mapping with Aggregates',
          type: 'special202'
        }
      ]
    }
  },
  {
    id: '201',
    type: 'special201',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Histogram Binning',
      categories: [
        {
          label: 'Aggregations',
          type: 'special199'
        },

        {
          label: 'Aggregate Functions',
          type: 'special200'
        },

        {
          label: 'Histogram Binning',
          type: 'special201'
        },
        {
          label: 'Mapping with Aggregates',
          type: 'special202'
        }
      ]
    }
  },
  {
    id: '202',
    type: 'special202',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Mapping with Aggregates',
      categories: [
        {
          label: 'Aggregations',
          type: 'special199'
        },

        {
          label: 'Aggregate Functions',
          type: 'special200'
        },

        {
          label: 'Histogram Binning',
          type: 'special201'
        },
        {
          label: 'Mapping with Aggregates',
          type: 'special202'
        }
      ]
    }
  },
  {
    id: '203',
    type: 'special203',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Filter and Group By',
      categories: [
        {
          label: 'Filter and Group By',
          type: 'special203'
        },

        {
          label: 'Filter and Aggregates',
          type: 'special204'
        },

        {
          label: 'All Transforms',
          type: 'special205'
        }
      ]
    }
  },
  {
    id: '204',
    type: 'special204',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Filter and Aggregates',
      categories: [
        {
          label: 'Filter and Group By',
          type: 'special203'
        },

        {
          label: 'Filter and Aggregates',
          type: 'special204'
        },

        {
          label: 'All Transforms',
          type: 'special205'
        }
      ]
    }
  },
  {
    id: '205',
    type: 'special205',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'All Transforms',
      categories: [
        {
          label: 'Filter and Group By',
          type: 'special203'
        },

        {
          label: 'Filter and Aggregates',
          type: 'special204'
        },

        {
          label: 'All Transforms',
          type: 'special205'
        }
      ]
    }
  },
  {
    id: '207',
    type: 'special207',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '208',
    type: 'special208',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Add Two Dropdown Menus to a Chart',
      categories: [
        {
          label: 'Add Two Dropdown Menus to a Chart',
          type: 'special208'
        },

        {
          label: 'Bind dropdown events to charts',
          type: 'special209'
        }
      ]
    }
  },
  {
    id: '209',
    type: 'special209',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Bind dropdown events to charts',
      categories: [
        {
          label: 'Add Two Dropdown Menus to a Chart',
          type: 'special208'
        },

        {
          label: 'Bind dropdown events to charts',
          type: 'special209'
        }
      ]
    }
  },
  {
    id: '210',
    type: 'special210',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Restyle Button Single Attribute',
      categories: [
        {
          label: 'Restyle Button Single Attribute',
          type: 'special210'
        },

        {
          label: 'Restyle Button Multiple Attributes',
          type: 'special211'
        },
        {
          label: 'Relayout Button',
          type: 'special212'
        },
        {
          label: 'Update Button',
          type: 'special213'
        },
        {
          label: 'Animate Button',
          type: 'special214'
        },
        {
          label: 'Style the buttons',
          type: 'special215'
        }
      ]
    }
  },
  {
    id: '211',
    type: 'special211',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Restyle Button Multiple Attributes',
      categories: [
        {
          label: 'Restyle Button Single Attribute',
          type: 'special210'
        },

        {
          label: 'Restyle Button Multiple Attributes',
          type: 'special211'
        },
        {
          label: 'Relayout Button',
          type: 'special212'
        },
        {
          label: 'Update Button',
          type: 'special213'
        },
        {
          label: 'Animate Button',
          type: 'special214'
        },
        {
          label: 'Style the buttons',
          type: 'special215'
        }
      ]
    }
  },
  {
    id: '212',
    type: 'special212',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Relayout Button',
      categories: [
        {
          label: 'Restyle Button Single Attribute',
          type: 'special210'
        },

        {
          label: 'Restyle Button Multiple Attributes',
          type: 'special211'
        },
        {
          label: 'Relayout Button',
          type: 'special212'
        },
        {
          label: 'Update Button',
          type: 'special213'
        },
        {
          label: 'Animate Button',
          type: 'special214'
        },
        {
          label: 'Style the buttons',
          type: 'special215'
        }
      ]
    }
  },
  {
    id: '213',
    type: 'special213',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Update Button',
      categories: [
        {
          label: 'Restyle Button Single Attribute',
          type: 'special210'
        },

        {
          label: 'Restyle Button Multiple Attributes',
          type: 'special211'
        },
        {
          label: 'Relayout Button',
          type: 'special212'
        },
        {
          label: 'Update Button',
          type: 'special213'
        },
        {
          label: 'Animate Button',
          type: 'special214'
        },
        {
          label: 'Style the buttons',
          type: 'special215'
        }
      ]
    }
  },
  {
    id: '214',
    type: 'special214',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Animate Button',
      categories: [
        {
          label: 'Restyle Button Single Attribute',
          type: 'special210'
        },

        {
          label: 'Restyle Button Multiple Attributes',
          type: 'special211'
        },
        {
          label: 'Relayout Button',
          type: 'special212'
        },
        {
          label: 'Update Button',
          type: 'special213'
        },
        {
          label: 'Animate Button',
          type: 'special214'
        },
        {
          label: 'Style the buttons',
          type: 'special215'
        }
      ]
    }
  },
  {
    id: '215',
    type: 'special215',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Style the buttons',
      categories: [
        {
          label: 'Restyle Button Single Attribute',
          type: 'special210'
        },

        {
          label: 'Restyle Button Multiple Attributes',
          type: 'special211'
        },
        {
          label: 'Relayout Button',
          type: 'special212'
        },
        {
          label: 'Update Button',
          type: 'special213'
        },
        {
          label: 'Animate Button',
          type: 'special214'
        },
        {
          label: 'Style the buttons',
          type: 'special215'
        }
      ]
    }
  },
  {
    id: '216',
    type: 'special216',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic Slider',
      categories: [
        {
          label: 'Basic Slider',
          type: 'special216'
        },

        {
          label: 'Bind Components to the Appearance of a Plot',
          type: 'special219'
        },
        {
          label: 'Add a Play Button to Control a Slider',
          type: 'special220'
        }
      ]
    }
  },
  {
    id: '217',
    type: 'special217',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '218',
    type: 'special218',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '219',
    type: 'special219',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Bind Components to the Appearance of a Plot',
      categories: [
        {
          label: 'Basic Slider',
          type: 'special216'
        },

        {
          label: 'Bind Components to the Appearance of a Plot',
          type: 'special219'
        },
        {
          label: 'Add a Play Button to Control a Slider',
          type: 'special220'
        }
      ]
    }
  },
  {
    id: '220',
    type: 'special220',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Add a Play Button to Control a Slider',
      categories: [
        {
          label: 'Basic Slider',
          type: 'special216'
        },

        {
          label: 'Bind Components to the Appearance of a Plot',
          type: 'special219'
        },
        {
          label: 'Add a Play Button to Control a Slider',
          type: 'special220'
        }
      ]
    }
  },
  {
    id: '221',
    type: 'special221',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Lasso Selection' }
  },
  {
    id: '222',
    type: 'special222',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Basic Range Slider on TimeSeries' }
  },
  {
    id: '223',
    type: 'special223',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Animating the Data',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  {
    id: '224',
    type: 'special224',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Animating the Layout',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  {
    id: '225',
    type: 'special225',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Defining Named Frames with addFrames',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  {
    id: '226',
    type: 'special226',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Animating Sequences of Frames',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  {
    id: '227',
    type: 'special227',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Animating Many Frames Quickly',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  {
    id: '228',
    type: 'special228',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Object Constancy',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  {
    id: '229',
    type: 'special229',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Frame Groups and Animation Modes',
      categories: [
        {
          label: 'Animating the Data',
          type: 'special223'
        },

        {
          label: 'Animating the Layout',
          type: 'special224'
        },
        {
          label: 'Defining Named Frames with addFrames',
          type: 'special225'
        },
        {
          label: 'Animating Sequences of Frames',
          type: 'special226'
        },
        {
          label: 'Animating Many Frames Quickly',
          type: 'special227'
        },
        {
          label: 'Object Constancy',
          type: 'special228'
        },
        {
          label: 'Frame Groups and Animation Modes',
          type: 'special229'
        }
        // {
        //   label: 'Animating with a Slider',
        //   type: 'special230'
        // }
      ]
    }
  },
  // {
  //   id: '230',
  //   type: 'special230',
  //   isHidden: true,
  //   position: { x: 350, y: 100 },
  //   data: { text: 'Animating with a Slider' }
  // },
  {
    id: '231',
    type: 'special231',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Filled-Area-Animation',
      categories: [
        {
          label: 'Filled-Area-Animation',
          type: 'special231'
        },

        {
          label: 'Multiple Trace Filled-Area',
          type: 'special232'
        }
      ]
    }
  },
  {
    id: '232',
    type: 'special232',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Multiple Trace Filled-Area',
      categories: [
        {
          label: 'Filled-Area-Animation',
          type: 'special231'
        },

        {
          label: 'Multiple Trace Filled-Area',
          type: 'special232'
        }
      ]
    }
  },
  {
    id: '233',
    type: 'special233',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Map Animations',
      categories: [
        {
          label: 'Filled-Area-Animation',
          type: 'special231'
        },

        {
          label: 'Multiple Trace Filled-Area',
          type: 'special232'
        }
      ]
    }
  },
  {
    id: '234',
    type: 'special234',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Basic TreeMaps'
      // categories: [
      //   {
      //     label: 'Basic TreeMaps',
      //     type: 'special234'
      //   },

      //   {
      //     label: 'Set Different Attributes in TreeMap',
      //     type: 'special235'
      //   },
      //   {
      //     label: 'Set Color of Treemap Sectors',
      //     type: 'special236'
      //   },
      //   {
      //     label: 'Nested Layers in Treemap',
      //     type: 'special237'
      //   }
      // ]
    }
  },
  {
    id: '235',
    type: 'special235',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Set Different Attributes in TreeMap',
      categories: [
        {
          label: 'Basic TreeMaps',
          type: 'special234'
        },

        {
          label: 'Set Different Attributes in TreeMap',
          type: 'special235'
        },
        {
          label: 'Set Color of Treemap Sectors',
          type: 'special236'
        },
        {
          label: 'Nested Layers in Treemap',
          type: 'special237'
        }
      ]
    }
  },
  {
    id: '236',
    type: 'special236',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Set Color of Treemap Sectors',
      categories: [
        {
          label: 'Basic TreeMaps',
          type: 'special234'
        },

        {
          label: 'Set Different Attributes in TreeMap',
          type: 'special235'
        },
        {
          label: 'Set Color of Treemap Sectors',
          type: 'special236'
        },
        {
          label: 'Nested Layers in Treemap',
          type: 'special237'
        }
      ]
    }
  },
  {
    id: '237',
    type: 'special237',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Nested Layers in Treemap',
      categories: [
        {
          label: 'Basic TreeMaps',
          type: 'special234'
        },

        {
          label: 'Set Different Attributes in TreeMap',
          type: 'special235'
        },
        {
          label: 'Set Color of Treemap Sectors',
          type: 'special236'
        },
        {
          label: 'Nested Layers in Treemap',
          type: 'special237'
        }
      ]
    }
  },
  {
    id: '238',
    type: 'special238',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'A Contour and Scatter Plot of the Method of Steepest Descent',
      categories: [
        {
          label: 'A Contour and Scatter Plot of the Method of Steepest Descent',
          type: 'special238'
        },

        {
          label: 'Line Chart and a Bar Chart',
          type: 'special239'
        }
      ]
    }
  },
  {
    id: '239',
    type: 'special239',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'Line Chart and a Bar Chart',
      categories: [
        {
          label: 'A Contour and Scatter Plot of the Method of Steepest Descent',
          type: 'special238'
        },

        {
          label: 'Line Chart and a Bar Chart',
          type: 'special239'
        }
        // {
        //   label: 'WebGL with 100,000 points',
        //   type: 'special240'
        // },
        // {
        //   label: 'WebGL with 1 Million points',
        //   type: 'special241'
        // },
        // {
        //   label: 'WebGL with many traces',
        //   type: 'special242'
        // }
      ]
    }
  },
  {
    id: '240',
    type: 'special240',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'WebGL with 100,000 points',
      categories: [
        {
          label: 'WebGL with 100,000 points',
          type: 'special240'
        },
        {
          label: 'WebGL with 1 Million points',
          type: 'special241'
        },
        {
          label: 'WebGL with many traces',
          type: 'special242'
        }
      ]
    }
  },
  {
    id: '241',
    type: 'special241',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'WebGL with 1 Million points',
      categories: [
        {
          label: 'WebGL with 100,000 points',
          type: 'special240'
        },
        {
          label: 'WebGL with 1 Million points',
          type: 'special241'
        },
        {
          label: 'WebGL with many traces',
          type: 'special242'
        }
      ]
    }
  },
  {
    id: '242',
    type: 'special242',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: {
      text: 'WebGL with many traces',
      categories: [
        {
          label: 'WebGL with 100,000 points',
          type: 'special240'
        },
        {
          label: 'WebGL with 1 Million points',
          type: 'special241'
        },
        {
          label: 'WebGL with many traces',
          type: 'special242'
        }
      ]
    }
  },
  {
    id: '243',
    type: 'special243',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: 'Animating with a Slider' }
  },
  {
    id: '244',
    type: 'special244',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '245',
    type: 'special245',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '246',
    type: 'special246',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '247',
    type: 'special247',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '248',
    type: 'special248',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '249',
    type: 'special249',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '250',
    type: 'special250',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '251',
    type: 'special251',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '252',
    type: 'special252',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '253',
    type: 'special253',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '254',
    type: 'special254',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  },
  {
    id: '255',
    type: 'special255',
    isHidden: true,
    position: { x: 350, y: 100 },
    data: { text: '' }
  }
]

export const chartTypes = [
  {
    basicCharts: [
      {
        type: 'Scatter Plots'
      },
      {
        type: 'Line Charts'
      },
      {
        type: 'Bar Charts'
      },
      {
        type: 'Pie Charts'
      },
      {
        type: 'Bubble Charts'
      },
      {
        type: 'Dot Plots'
      },
      {
        type: 'Filled Area Plots'
      },
      {
        type: 'Horizontal Bar Charts'
      },
      {
        type: 'Sunburst Charts'
      },
      {
        type: 'Sankey Diagrams'
      },
      {
        type: 'Point Cloud'
      },
      {
        type: 'Treemaps'
      },
      {
        type: 'Multiple Chart Types'
      },
      {
        type: 'WebGL'
      }
    ],
    statisticalCharts: [
      {
        type: 'Error Bars'
      },
      {
        type: 'Box Plots'
      },
      {
        type: 'Histograms'
      },
      {
        type: '2D Density Plots'
      },
      {
        type: 'Continuous Error Bars'
      }
    ],
    scientificCharts: [
      {
        type: 'Contour Plots'
      },
      {
        type: 'Heatmaps'
      },
      {
        type: 'Parallel Coordinates Plot'
      },
      {
        type: 'Log Plots'
      }
    ],
    financialCharts: [
      {
        type: 'Waterfall Charts'
      },
      {
        type: 'CandleSticks Charts'
      },
      {
        type: 'Funnel Area Charts'
      },
      {
        type: 'Time Series'
      }
    ],
    threeDcharts: [
      {
        type: '3D Scatter Plots'
      },
      {
        type: '3D Surface Plots'
      },
      {
        type: '3D Mesh Plots'
      },
      {
        type: '3D Line Plots'
      }
    ],
    subPlots: [
      {
        type: 'Subplots'
      },
      {
        type: 'Inset Plots'
      },
      {
        type: '3D Subplots'
      },
      {
        type: 'Mixed SubPlots'
      },
      {
        type: 'Table SubPlots'
      }
    ],
    customChartEvents: [
      {
        type: 'Click Events'
      },
      {
        type: 'Hover Events'
      },
      {
        type: 'Zoom Events'
      },
      {
        type: 'Disable Zoom Events'
      }
    ],
    maps: [
      {
        type: 'Bubble Maps'
      },
      {
        type: 'Chropleth Maps'
      }
    ],
    transforms: [
      {
        type: 'Filter'
      },
      // {
      //   type: 'Groupby'
      // },
      {
        type: 'Aggregations'
      }
    ],
    customControls: [
      {
        type: 'DropDown Events'
      },
      {
        type: 'Button Events'
      },
      {
        type: 'Slider Events'
      },
      {
        type: 'Lasso Selection'
      },
      {
        type: 'Range Slider and Selector'
      }
    ],
    animations: [
      {
        type: 'Animations'
      },
      // {
      //   type: 'Adding Sliders to Animations'
      // },
      {
        type: 'Filled Area Animation'
      }
    ]
  }
]

export const chartMainTypes = [
  {
    type: 'Basic Charts'
  },
  {
    type: 'Statistical Charts'
  },
  {
    type: 'Scientific Charts'
  },
  {
    type: 'Financial Charts'
  },
  {
    type: '3D Charts'
  },
  {
    type: 'Subplots'
  },
  {
    type: 'Custom Chart Events'
  },
  {
    type: 'Maps'
  },
  {
    type: 'Transforms'
  },
  {
    type: 'Add Custom Controls'
  },
  {
    type: 'Animations'
  }
]

export const visualizationOptions = {
  section1: [
    {
      title: 'Line And Scatter Plot',
      subtitle: 'Displays a Barchart of given x and y column names.',
      img: '/LineAndScatterPlot.png',
      type: 'special20'
    },
    {
      title: 'Data Labels Hover',
      subtitle: 'Displays a histogram of a given column name.',
      img: '/DataLabelsAndHover.png',
      type: 'special21'
    },
    {
      title: 'Data Labels on The Plot',
      subtitle: 'Displays a scatterplot of given x and y column names.',
      img: '/DataLabelsOnThePlot.png',
      type: 'special22'
    },
    {
      title: 'Scatter Plot with a Color Dimension',
      subtitle: 'Displays a scatterplot of given x and y column names.',
      img: '/ScatterPlotWithColorDimention.png',
      type: 'special23'
    }
  ],

  section2: [
    {
      title: 'Time Series',
      subtitle:
        'Displays a Timeseries line chart of given x and y column names.'
    },
    {
      title: 'Binning',
      subtitle: 'Customise data source for Bin chart'
    },
    {
      title: 'Number Profiler',
      subtitle:
        'Take user input to do the aggregate profiling (Sum, Count, Minimum, Maximum and Average)'
    }
  ],
  section3: [
    {
      title: 'Basic Line Plot',
      subtitle: '',
      img: '/LineChart01.png',
      type: 'special24'
    },
    {
      title: 'Line and Scatter Plot',
      subtitle: '',
      img: '/LineChart02.png',
      type: 'special25'
    },
    {
      title: 'Adding Names to Line and Scatter Plot',
      subtitle: '',
      img: '/LineChart03.png',
      type: 'special26'
    },
    {
      title: 'Line and Scatter Styling',
      subtitle: '',
      img: '/LineChart04.png',
      type: 'special27'
    },
    {
      title: 'Styled Line Plot',
      subtitle: '',
      img: '/LineChart05.png',
      type: 'special28'
    },
    {
      title: 'Colored and Styled Scatter Plot',
      subtitle: '',
      img: '/LineChart06.png',
      type: 'special29'
    },
    {
      title: 'Line Shape Options for Interpolation',
      subtitle: '',
      img: '/LineChart07.png',
      type: 'special30'
    },
    {
      title: 'Graph and Axes Titles',
      subtitle: '',
      img: '/LineChart08.png',
      type: 'special31'
    },
    {
      title: 'Line Dash',
      subtitle: '',
      img: '/LineChart09.png',
      type: 'special32'
    },
    {
      title: 'Connect Gaps Between Data',
      subtitle: '',
      img: '/LineChart10.png',
      type: 'special33'
    }
    // {
    //   title: 'Labelling Lines with Annotations',
    //   subtitle: '',
    //   img: '/LineChart11.png',
    //   onpress: () => {
    //     console.log('..')
    //   }
    // }
  ],

  section5: [
    {
      title: 'Basic Bar Chart',
      subtitle: '',
      img: '/Barchart01.png',
      type: 'special34'
    },
    {
      title: 'Grouped Bar Chart',
      subtitle: '',
      img: '/Barchart02.png',
      type: 'special35'
    },
    {
      title: 'Stacked Bar Chart',
      subtitle: '',
      img: '/Barchart03.png',
      type: 'special36'
    },
    {
      title: 'Bar chart with Hover Text',
      subtitle: '',
      img: '/Barchart04.png',
      type: 'special37'
    },
    {
      title: 'Bar Charts with Direct Labels',
      subtitle: '',
      img: '/Barchart05.png',
      type: 'special38'
    },
    {
      title: 'Grouped Bar Chart with Direct Labels',
      subtitle: '',
      img: '/Barchart06.png',
      type: 'special39'
    },
    {
      title: 'Bar Chart with Rotated Labels',
      subtitle: '',
      img: '/Barchart07.png',
      type: 'special40'
    },
    {
      title: 'Customizing Individual Bar Colors',
      subtitle: '',
      img: '/Barchart08.png',
      type: 'special41'
    },
    {
      title: 'Customizing Individual Bar Widths',
      subtitle: '',
      img: '/Barchart09.png',
      type: 'special42'
    },
    {
      title: 'Customizing Individual Bar Base',
      subtitle: '',
      img: '/Barchart10.png',
      type: 'special43'
    },
    {
      title: 'Colored and Styled Bar Chart',
      subtitle: '',
      img: '/Barchart11.png',
      type: 'special44'
    },
    {
      title: 'Waterfall Bar Chart',
      subtitle: '',
      img: '/Barchart12.png',
      type: 'special45'
    },
    {
      title: 'Bar Chart with Relative Barmode',
      subtitle: '',
      img: '/Barchart13.png',
      type: 'special46'
    }
  ],
  section4: [
    {
      title: 'Box Plot',
      subtitle: '',
      img: '/boxPlot01.png'
    },
    {
      title: 'K mean Cluster',
      subtitle: ''
    },
    {
      title: 'Data Source Comparision',
      subtitle: ''
    }
  ],
  section4: [
    {
      title: 'Edit Mode Show Record',
      subtitle: ''
    }
  ],
  section6: [
    {
      title: 'Basic Pie Chart',
      subtitle: '.',
      img: '/Pie01.png',
      type: 'special47'
    },
    {
      title: 'Pie Chart Subplots',
      subtitle: '',
      img: '/Pie02.png',
      type: 'special48'
    },
    {
      title: 'Donut Chart',
      subtitle: '',
      img: '/Pie03.png',
      type: 'special49'
    },
    {
      title: 'Automatically Adjust Margins',
      subtitle: '',
      img: '/Pie04.png',
      type: 'special50'
    },
    {
      title: 'Control Text Orientation Inside Pie Chart Sectors',
      subtitle: '',
      img: '/Pie05.png',
      type: 'special51'
    }
  ],
  section7: [
    {
      title: 'Marker Size on Bubble Charts',
      subtitle: '.',
      img: '/Bubble01.png',
      type: 'special52'
    },
    {
      title: 'Marker Size and Color on Bubble Charts',
      subtitle: '.',
      img: '/Bubble02.png',
      type: 'special53'
    },
    {
      title: 'Hover Text on Bubble Charts',
      subtitle: '.',
      img: '/Bubble03.png',
      type: 'special54'
    },
    {
      title: 'Bubble Size Scaling on Charts',
      subtitle: '.',
      img: '/Bubble04.png',
      type: 'special55'
    },
    {
      title: 'Marker Size, Color, and Symbol as an Array',
      subtitle: '.',
      img: '/Bubble05.png',
      type: 'special56'
    }
  ],
  section8: [
    {
      title: 'Categorical Dot Plot',
      subtitle: '.',
      img: '/dot.png',
      type: 'special57'
    }
  ],
  section9: [
    {
      title: 'Basic Overlaid Area Chart',
      subtitle: '.',
      img: '/FilledArea01.png',
      type: 'special58'
    },
    {
      title: 'Overlaid Area Chart Without Boundary Line',
      subtitle: '.',
      img: '/FilledArea02.png',
      type: 'special59'
    },
    {
      title: 'Stacked Area Chart',
      subtitle: '.',
      img: '/FilledArea03.png',
      type: 'special60'
    },
    {
      title: 'Normalized Stacked Area Chart',
      subtitle: '.',
      img: '/FilledArea04.png',
      type: 'special61'
    },
    {
      title: 'Select Hover Points',
      subtitle: '.',
      img: '/FilledArea05.png',
      type: 'special62'
    }
  ],
  section10: [
    {
      title: 'Basic Horizontal Bar Chart',
      subtitle: '.',
      img: '/HorizontalBar01.png',
      type: 'special63'
    },
    {
      title: 'Colored Bar Chart',
      subtitle: '.',
      img: '/HorizontalBar02.png',
      type: 'special64'
    },
    {
      title: 'Bar Chart with Line Plot',
      subtitle: '.',
      img: '/HorizontalBar03.png',
      type: 'special65'
    }
  ],
  section11: [
    {
      title: 'Basic Sunburst chart',
      subtitle: '.',
      img: '/sunburst01.png',
      type: 'special68'
    },
    {
      title: 'Branchvalues',
      subtitle: '.',
      img: '/sunburst02.png',
      type: 'special69'
    },
    {
      title: 'Sunburst with Repeated Labels',
      subtitle: '.',
      img: '/sunburst03.png',
      type: 'special70'
    },
    {
      title: 'Large Number of Slices',
      subtitle: '.',
      img: '/sunburst05.png',
      type: 'special71'
    },
    {
      title: 'Control Text Orientation Inside Sunburst Chart Sectors',
      subtitle: '.',
      img: '/sunburst06.png',
      type: 'special72'
    }
  ],
  section12: [
    {
      title: 'Sankey Diagrams',
      subtitle: '.',
      img: '/sankey01.png',
      type: 'special73'
    },
    // {
    //   title: 'Create Sankey Canvas',
    //   subtitle: '.',
    //   img: '/sankey02.png',
    //   onpress: () => {
    //     onAddNode('special74', '74')
    //   }
    // },
    // {
    //   title: 'Add Nodes',
    //   subtitle: '.',
    //   img: '/sankey03.png',
    //   onpress: () => {
    //     onAddNode('special75', '75')
    //   }
    // },
    // {
    //   title: 'Add Links',
    //   subtitle: '.',
    //   img: '/sankey03.png',
    //   onpress: () => {
    //     onAddNode('special76', '76')
    //   }
    // },
    {
      title: 'Style Sankey Diagram',
      subtitle: '.',
      img: '/sankey05.png',
      type: 'special77'
    }
    // {
    //   title: 'Define Node Position',
    //   subtitle: '.',
    //   img: '/sankey02.png',
    //   onpress: () => {
    //     onAddNode('special78', '78')
    //   }
    // }
  ],
  section13: [
    {
      title: 'Basic Point Cloud',
      subtitle: '.',
      img: '/POintCloud01.png',
      type: 'special66'
    },
    {
      title: 'Styled Point Cloud',
      subtitle: '.',
      img: '/POintCloud02.png',
      type: 'special67'
    }
    // {
    //   title: 'Advanced Point Cloud',
    //   subtitle: '.',
    //   img: '/POintCloud03.png',
    //   onpress: () => {
    //     onAdd11()
    //   }
    // }
  ],
  section14: [
    {
      title: 'Basic TreeMaps',
      subtitle: '.',
      img: '/treemap01.png',
      type: 'special234'
    },
    {
      title: 'Set Different Attributes in TreeMap',
      subtitle: '.',
      img: '/treemap02.png',
      type: 'special235'
    },
    {
      title: 'Set Color of Treemap Sectorss',
      subtitle: '.',
      img: '/treemap03.png',
      type: 'special236'
    },
    {
      title: 'Nested Layers in Treemap',
      subtitle: '.',
      img: '/treemap04.png',
      type: 'special237'
    }
  ],
  section15: [
    {
      title: 'A Contour and Scatter Plot of the Method of Steepest Descent',
      subtitle: '.',
      img: '/MultipleChartType01.png',
      type: 'special238'
    },
    {
      title: 'Line Chart and a Bar Chart',
      subtitle: '.',
      img: '/MultipleChartType02.png',
      type: 'special239'
    }
  ],
  section16: [
    {
      title: 'WebGL',
      subtitle: '.',
      img: '/WebglWithSVG01.png',
      type: 'special240'
    },
    {
      title: 'WebGL with 1 Million points',
      subtitle: '.',
      img: '/WebglWithSVG02.png',
      type: 'special241'
    },
    {
      title: 'WebGL with many traces',
      subtitle: '.',
      img: '/WebglWithSVG03.png',
      type: 'special242'
    }
  ],
  section17: [
    {
      title: 'Basic Symmetric Error Bars',
      subtitle: '.',
      img: '/error01.png',
      type: 'special81'
    },
    {
      title: 'Bar Chart with Error Bars',
      subtitle: '.',
      img: '/error02.png',
      type: 'special82'
    },
    {
      title: 'Horizontal Error Bars',
      subtitle: '.',
      img: '/error03.png',
      type: 'special83'
    },
    {
      title: 'Asymmetric Error Bars',
      subtitle: '.',
      img: '/error04.png',
      type: 'special84'
    },
    {
      title: 'Colored and Styled Error Bars',
      subtitle: '.',
      img: '/error05.png',
      type: 'special85'
    },
    {
      title: 'Error Bars as a Percentage of the y-Value',
      subtitle: '.',
      img: '/error06.png',
      type: 'special86'
    },
    {
      title: 'Asymmetric Error Bars with a Constant Offset',
      subtitle: '.',
      img: '/error07.png',
      type: 'special87'
    }
  ],
  section18: [
    {
      title: 'Basic Box Plot',
      subtitle: '.',
      img: '/boxPlot01.png',
      type: 'special88'
    },
    {
      title: 'Box Plot That Displays the Underlying Data',
      subtitle: '.',
      img: '/boxPlot02.png',
      type: 'special89'
    },
    {
      title: 'Horizontal Box Plot',
      subtitle: '.',
      img: '/boxPlot03.png',
      type: 'special90'
    },
    {
      title: 'Grouped Box Plot',
      subtitle: '.',
      img: '/boxPlot04.png',
      type: 'special91'
    },
    {
      title: 'Box Plot Styling Outliners',
      subtitle: '.',
      img: '/boxPlot05.png',
      type: 'special92'
    },
    {
      title: 'Box Plot Styling Mean and Standard Deviation',
      subtitle: '.',
      img: '/boxPlot06.png',
      type: 'special93'
    },
    {
      title: 'Grouped Horizontal Box Plot',
      subtitle: '.',
      img: '/boxPlot07.png',
      type: 'special94'
    },
    {
      title: 'Colored Box Plot',
      subtitle: '.',
      img: '/boxPlot08.png',
      type: 'special95'
    },
    // {
    //   title: 'Fully Styled Box Plot',
    //   subtitle: '.',
    //   img: '/boxPlot09.png',
    //   onpress: () => {
    //     onAddNode('special96', '96')
    //   }
    // },
    {
      title: 'Rainbow Box Plot',
      subtitle: '.',
      img: '/boxPlot10.png',
      type: 'special97'
    }
  ],
  section19: [
    {
      title: 'Basic Histogram',
      subtitle: '.',
      img: '/hitogram01.png',
      type: 'special98'
    },
    {
      title: 'Horizontal Histogram',
      subtitle: '.',
      img: '/hitogram02.png',
      type: 'special99'
    },
    {
      title: 'Overlaid Histogram',
      subtitle: '.',
      img: '/hitogram03.png',
      type: 'special100'
    },
    {
      title: 'Stacked Histogram',
      subtitle: '.',
      img: '/hitogram04.png',
      type: 'special101'
    },
    {
      title: 'Colored and Styled Histograms',
      subtitle: '.',
      img: '/hitogram05.png',
      type: 'special102'
    },
    {
      title: 'Cumulative Histogram',
      subtitle: '.',
      img: '/hitogram06.png',
      type: 'special103'
    },
    {
      title: 'Normalized Histogram',
      subtitle: '.',
      img: '/hitogram07.png',
      type: 'special104'
    },
    {
      title: 'Specify Binning Function',
      subtitle: '.',
      img: '/hitogram08.png',
      type: 'special105'
    }
  ],
  section20: [
    {
      title: '2D Histogram Contour Plot with Histogram Subplots',
      subtitle: '.',
      img: '/2dDensityPlot01.png',
      type: 'special106'
    },
    {
      title: '2D Histogram Contour Plot with Slider Control',
      subtitle: '.',
      img: '/2dDensityPlot02.png',
      type: 'special107'
    }
  ],
  section21: [
    {
      title: 'Filled Lines',
      subtitle: '.',
      img: '/ContinuesErrorBars01.png',
      type: 'special108'
    },
    {
      title: 'Asymmetric Error Bars with a Constant Offset',
      subtitle: '.',
      img: '/ContinuesErrorBars02.png',
      type: 'special109'
    }
  ],
  section22: [
    {
      title: 'Simple Contour Plot',
      subtitle: '.',
      img: '/ContourPlot01.png',
      type: 'special110'
    },
    {
      title: 'Basic Contour Plot',
      subtitle: '.',
      img: '/ContourPlot02.png',
      type: 'special111'
    },
    {
      title: 'Setting X and Y Coordinates in a Contour Plot',
      subtitle: '.',
      img: '/ContourPlot03.png',
      type: 'special112'
    },
    {
      title: 'Color Scale for Contour Plot',
      subtitle: '.',
      img: '/ContourPlot04.png',
      type: 'special113'
    },
    {
      title: 'Customizing Size and Range of Contours',
      subtitle: '.',
      img: '/ContourPlot05.png',
      type: 'special114'
    },
    {
      title: 'Customizing Spacing Between X and Y Ticks',
      subtitle: '.',
      img: '/ContourPlot06.png',
      type: 'special115'
    },
    {
      title: 'Connect the Gaps between Null Values in the Z Matrix',
      subtitle: '.',
      img: '/ContourPlot07.png',
      type: 'special116'
    },
    {
      title: 'Smoothing Contour Lines',
      subtitle: '.',
      img: '/ContourPlot08.png',
      type: 'special120'
    },
    {
      title: 'Smooth Contour Coloring',
      subtitle: '.',
      img: '/ContourPlot08.png',
      type: 'special121'
    },
    {
      title: 'Contour Lines',
      subtitle: '.',
      img: '/ContourPlot09.png',
      type: 'special122'
    },
    {
      title: 'Contour Line Labels',
      subtitle: '.',
      img: '/ContourPlot10.png',
      type: 'special123'
    },
    {
      title: 'Custom Color scale for Contour Plot',
      subtitle: '.',
      img: '/ContourPlot11.png',
      type: 'special124'
    },
    {
      title: 'Color Bar Title',
      subtitle: '.',
      img: '/ContourPlot12.png',
      type: 'special125'
    },
    {
      title: 'Color Bar size',
      subtitle: '.',
      img: '/ContourPlot13.png',
      type: 'special126'
    },
    {
      title: 'Styling Color Bar Ticks for Contour Plots',
      subtitle: '.',
      img: '/ContourPlot14.png',
      type: 'special127'
    }
  ],
  section23: [
    {
      title: 'Basic Heatmap',
      subtitle: '.',
      img: '/HeatMaps01.png',
      type: 'special128'
    },
    {
      title: 'Heatmap with Categorical Axis Labels',
      subtitle: '.',
      img: '/HeatMaps02.png',
      type: 'special129'
    },
    {
      title: 'Annotated Heatmap',
      subtitle: '.',
      img: '/HeatMaps03.png',
      type: 'special130'
    },
    {
      title: 'Heatmap with Unequal Block Sizes',
      subtitle: '.',
      img: '/HeatMaps04.png',
      type: 'special131'
    }
  ],
  section24: [
    // {
    //   title: 'Basic Ternary Plot with Markers',
    //   subtitle: '.',
    //   img: '/HeatMaps05.png',
    //   onpress: () => {
    //     onAddNode('special132', '132')
    //   }
    // },
    // {
    //   title: 'Soil Types Ternary Plot',
    //   subtitle: '.',
    //   img: '/HeatMaps06.png',
    //   onpress: () => {
    //     onAddNode('special133', '133')
    //   }
    // }
  ],
  section25: [
    {
      title: 'Adding Dimensions',
      subtitle: '.',
      img: '/ParallelCoordinatePlot01.png',
      type: 'special134'
    },
    {
      title: 'Basic Parallel Coordinates Plot',
      subtitle: '.',
      img: '/ParallelCoordinatePlot02.png',
      type: 'special135'
    },
    {
      title: 'Annotated Parallel Coordinates Plot',
      subtitle: '.',
      img: '/ParallelCoordinatePlot03.png',
      type: 'special136'
    },
    {
      title: 'Advanced Parallel Coordinates Plot',
      subtitle: '.',
      img: '/ParallelCoordinatePlot04.png',
      type: 'special137'
    }
  ],
  section26: [
    {
      title: 'Logarithmic Axes',
      subtitle: '.',
      img: '/LogPlots01.png',
      type: 'special138'
    }
  ],
  section27: [
    {
      title: 'Basic Waterfall Chart',
      subtitle: '.',
      img: '/WaterfallCharts01.png',
      type: 'special139'
    },
    {
      title: 'Multi Category Waterfall Chart',
      subtitle: '.',
      img: '/WaterfallCharts02.png',
      type: 'special140'
    },
    {
      title: 'Horizontal Waterfall Chart',
      subtitle: '.',
      img: '/WaterfallCharts03.png',
      type: 'special141'
    },
    {
      title: 'Style Waterfall Chart',
      subtitle: '.',
      img: '/WaterfallCharts04.png',
      type: 'special142'
    }
  ],
  section28: [
    {
      title: 'A Single Angular Gauge Chart',
      subtitle: '.',
      img: '/Indicators01.png',
      type: 'special143'
    },
    {
      title: 'Bullet Gauge',
      subtitle: '.',
      img: '/Indicators02.png',
      type: 'special144'
    },
    {
      title: 'Showing Information above Your Chart',
      subtitle: '.',
      img: '/Indicators03.png',
      type: 'special145'
    },
    {
      title: 'Data Cards/ Big Numbers',
      subtitle: '.',
      img: '/Indicators04.png',
      type: 'special146'
    }
  ],
  section29: [
    {
      title: 'Simple Candlestick Chart',
      subtitle: '.',
      img: '/CandleStickChart01.png',
      type: 'special147'
    },
    {
      title: 'Candlestick Chart without Rangeslider',
      subtitle: '.',
      img: '/CandleStickChart02.png',
      type: 'special148'
    },
    {
      title: 'Customise Candlestick Chart with Shapes and Annotations',
      subtitle: '.',
      img: '/CandleStickChart03.png',
      type: 'special149'
    },
    {
      title: 'Customizing Candlestick Chart Colors',
      subtitle: '.',
      img: '/CandleStickChart04.png',
      type: 'special150'
    },
    {
      title: 'Add Range Selector',
      subtitle: '.',
      img: '/CandleStickChart05.png',
      type: 'special151'
    }
  ],
  section30: [
    {
      title: 'Basic Funnel Plot',
      subtitle: '.',
      img: '/FunnelAndFunnelAreaChart01.png',
      type: 'special152'
    },
    {
      title: 'Setting Marker Size and Color',
      subtitle: '.',
      img: '/FunnelAndFunnelAreaChart02.png',
      type: 'special153'
    },
    {
      title: 'Stacked Funnel',
      subtitle: '.',
      img: '/FunnelAndFunnelAreaChart03.png',
      type: 'special154'
    },
    {
      title: 'Funnel Area Plot',
      subtitle: '.',
      img: '/FunnelAndFunnelAreaChart04.png',
      type: 'special155'
    },
    {
      title: 'Multi Funnel area',
      subtitle: '.',
      img: '/FunnelAndFunnelAreaChart05.png',
      type: 'special156'
    }
  ],
  section31: [
    {
      title: 'Date Strings',
      subtitle: '.',
      img: '/TimeSeries01.png',
      type: 'special157'
    },
    {
      title: 'Basic Time Series',
      subtitle: '.',
      img: '/TimeSeries02.png',
      type: 'special158'
    },
    {
      title: 'Manually Set Range',
      subtitle: '.',
      img: '/TimeSeries03.png',
      type: 'special159'
    },
    {
      title: 'Time Series with Rangeslider',
      subtitle: '.',
      img: '/TimeSeries04.png',
      type: 'special160'
    }
  ],
  section32: [
    {
      title: '3D Scatter Plot',
      subtitle: '.',
      img: '/3DScatterPlot01.png',
      type: 'special161'
    },
    {
      title: 'Basic Ribbon Plot',
      subtitle: '.',
      img: '/BasicLinePlot.png',
      type: 'special162'
    }
  ],
  sectionSub3D: [
    {
      title: 'Topographical 3D Surface Plot',
      subtitle: '.',
      img: '/3DSurfacePlots01.png',
      type: 'special163'
    },
    {
      title: 'Surface Plot With Contours',
      subtitle: '.',
      img: '/3DSurfacePlots02.png',
      type: 'special164'
    },
    {
      title: 'Multiple 3D Surface Plots',
      subtitle: '.',
      img: '/3DSurfacePlots03.png',
      type: 'special165'
    }
  ],
  section33: [
    {
      title: 'Simple 3D Mesh Plot',
      subtitle: '.',
      img: '/3DMeshPlots01.png',
      type: 'special166'
    },
    {
      title: '3D Mesh Plot with Alphahull',
      subtitle: '.',
      img: '/3DMeshPlots02.png',
      type: 'special167'
    },
    {
      title: '3D Mesh Tetrahedron',
      subtitle: '.',
      img: '/3DMeshPlots03.png',
      type: 'special168'
    },
    {
      title: '3D Mesh Cube',
      subtitle: '.',
      img: '/3DMeshPlots04.png',
      type: 'special169'
    }
  ],
  section34: [
    {
      title: '3D Line Plot',
      subtitle: '.',
      img: '/3DLinePlot01.png',
      type: 'special170'
    },
    {
      title: '3D Line + Markers Plot',
      subtitle: '.',
      img: '/3DLinePlot02.png',
      type: 'special171'
    },
    {
      title: '3D Line Spiral Plot',
      subtitle: '.',
      img: '/3DLinePlot03.png',
      type: 'special172'
    },
    {
      title: '3D Random Walk Plot',
      subtitle: '.',
      img: '/3DLinePlot04.png',
      type: 'special173'
    }
  ],
  section35a: [
    {
      title: 'Simple Subplot',
      subtitle: '.',
      img: '/SubPlots01.png',
      type: 'special174'
    },
    {
      title: 'Custom Sized Subplot',
      subtitle: '.',
      img: '/SubPlots02.png',
      type: 'special175'
    },
    {
      title: 'Multiple Subplots',
      subtitle: '.',
      img: '/SubPlots03.png',
      type: 'special176'
    },
    {
      title: 'Subplots with Shared Axes',
      subtitle: '.',
      img: '/SubPlots04.png',
      type: 'special177'
    },
    {
      title: 'Stacked Subplots',
      subtitle: '.',
      img: '/SubPlots05.png',
      type: 'special178'
    },
    {
      title: 'Stacked Subplots with a Shared X-Axis',
      subtitle: '.',
      img: '/SubPlots06.png',
      type: 'special179'
    },
    {
      title: 'Multiple Custom Sized Subplots',
      subtitle: '.',
      img: '/SubPlots07.png',
      type: 'special180'
    }
  ],
  section35: [
    {
      title: 'Simple Inset Plot',
      subtitle: '.',
      img: '/InsetPlots01.png',
      type: 'special181'
    }
  ],
  section36: [
    {
      title: 'Multiple 3D Subplots',
      subtitle: '.',
      img: '/3DSubPlots01.png',
      type: 'special182'
    }
  ],
  section37: [
    {
      title: 'Mixed Subplots',
      subtitle: '.',
      img: '/3DMixedSubPlots.png',
      type: 'special183'
    }
  ],
  section38: [
    {
      title: 'Table and Chart Subplot',
      subtitle: '.',
      img: '/TableSubPlots01.png',
      type: 'special184'
    }
  ],
  section39: [
    // {
    //   title: 'Click Event Data',
    //   subtitle: '.',
    //   img: '/ClickEvents01.png',
    //   onpress: () => {
    //     onAddNode('special185', '185')
    //   }
    // },
    {
      title: 'Binding to Click Events',
      subtitle: '.',
      img: '/ClickEvents02.png',
      type: 'special186'
    },
    {
      title: 'Create annotation on click event',
      subtitle: '.',
      img: '/LineAndScatterPlot.png',
      type: 'special187'
    }
  ],
  section40: [
    // {
    //   title: 'Hover Event Data',
    //   subtitle: '.',
    //   img: '/HoverEvents01.png',
    //   onpress: () => {
    //     onAddNode('special188', '188')
    //   }
    // },
    {
      title: 'Capturing Hover Events: Data',
      subtitle: '.',
      img: '/HoverEvents02.png',
      type: 'special189'
    },
    {
      title: 'Capturing Hover Events: Pixels',
      subtitle: '.',
      img: '/HoverEvents03.png',
      type: 'special190'
    },
    {
      title: 'Triggering Hover Events',
      subtitle: '.',
      img: '/HoverEvents04.png',
      type: 'special191'
    },
    {
      title: 'Coupled Hover Events',
      subtitle: '.',
      img: '/HoverEvents05.png',
      type: 'special192'
    },
    {
      title: 'Combined Click and Hover Events',
      subtitle: '.',
      img: '/LineAndScatterPlot.png',
      type: 'special193'
    }
  ],
  section41: [
    {
      title: 'Binding to Zoom Events',
      subtitle: '.',
      img: '/ZoomEvents01.png',
      type: 'special194'
    }
  ],
  section42: [
    {
      title: 'Disabling Zoom Events for X Axis',
      subtitle: '.',
      img: '/ZoomEventsDisable01.png',
      type: 'special195'
    },
    {
      title: 'Disabling Zoom Events for X and Y Axis',
      subtitle: '.',
      img: '/ZoomEventsDisable02.png',
      type: 'special196'
    }
  ],
  section43: [
    {
      title: 'Filter',
      subtitle: '.',
      img: '/Filter01.png',
      type: 'special197'
    },
    {
      title: 'Groupby',
      subtitle: '.',
      img: '/GroupBy01.png',
      type: 'special198'
    }
  ],
  section44: [
    {
      title: 'Aggregations',
      subtitle: '.',
      img: '/Aggregations01.png',
      type: 'special199'
    },
    {
      title: 'Aggregate Functions',
      subtitle: '.',
      img: '/Aggregations02.png',
      type: 'special200'
    },
    {
      title: 'Histogram Binning',
      subtitle: '.',
      img: '/Aggregations03.png',
      type: 'special201'
    }
    // {
    //   title: 'Mapping with Aggregates',
    //   subtitle: '.',
    //   img: '/Aggregations04.png',
    //   onpress: () => {
    //     onAddNode('special202', '202')
    //   }
    // }
  ],
  section45: [
    // {
    //   title: 'Filter and Group By',
    //   subtitle: '.',
    //   img: '/MultipleTransform01.png',
    //   onpress: () => {
    //     onAddNode('special203', '203')
    //   }
    // },
    // {
    //   title: 'Filter and Aggregates',
    //   subtitle: '.',
    //   img: '/MultipleTransform03.png',
    //   onpress: () => {
    //     onAddNode('special204', '204')
    //   }
    // },
    // {
    //   title: 'All Transforms',
    //   subtitle: '.',
    //   img: '/MultipleTransform03.png',
    //   onpress: () => {
    //     onAddNode('special205', '205')
    //   }
    // }
  ],
  section46: [
    {
      title: 'Add Two Dropdown Menus to a Chart with Plotly.js',
      subtitle: '.',
      img: '/DropDownEvents01.png',
      type: 'special208'
    }

    // {
    //   title: 'Bind dropdown events to Plotly.js charts',
    //   subtitle: '.',
    //   img: '/DropDownEvents02.png',
    //   onpress: () => {
    //     onAddNode('special209', '209')
    //   }
    // }
  ],
  section47: [
    {
      title: 'Add Two Dropdown Menus to a Chart with Plotly.js',
      subtitle: '.',
      img: '/LineAndScatterPlot.png',
      type: 'special210'
    },
    {
      title: 'Bind dropdown events to Plotly.js charts',
      subtitle: '.',
      img: '/LineAndScatterPlot.png',
      type: 'special211'
    }
  ],
  section48: [
    {
      title: 'Restyle Button Single Attribute',
      subtitle: '.',
      img: '/ButtonEvents01.png',
      type: 'special210'
    },
    {
      title: 'Restyle Button Multiple Attributes',
      subtitle: '.',
      img: '/ButtonEvents02.png',
      type: 'special211'
    },
    {
      title: 'Relayout Button',
      subtitle: '.',
      img: '/ButtonEvents02.png',
      type: 'special212'
    },
    {
      title: 'Update Button',
      subtitle: '.',
      img: '/ButtonEvents03.png',
      type: 'special213'
    },
    // {
    //   title: 'Animate Button',
    //   subtitle: '.',
    //   img: '/ButtonEvents04.png',
    //   onpress: () => {
    //     onAddNode('special214', '214')
    //   }
    // },
    {
      title: 'Style the buttons',
      subtitle: '.',
      img: '/ButtonEvents05.png',
      type: 'special215'
    }
  ],
  section49: [
    {
      title: 'Basic Slider',
      subtitle: '.',
      img: '/SliderEvents01.png',
      type: 'special216'
    },
    {
      title: 'Bind Components to the Appearance of a Plot',
      subtitle: '.',
      img: '/SliderEvents02.png',
      type: 'special219'
    },
    {
      title: 'Add a Play Button to Control a Slider',
      subtitle: '.',
      img: '/SliderEvents03.png',
      type: 'special220'
    }
  ],
  section50a: [
    {
      title: 'Lasso Selection',
      subtitle: '.',
      img: '/LassoSelection01.png',
      type: 'special221'
    }
  ],
  section50: [
    {
      title: 'Basic Range Slider on Time Series',
      subtitle: '.',
      img: '/RangeSliderAndSelector01.png',
      type: 'special222'
    }
  ],
  section51: [
    // {
    //   title: 'Animating the Data',
    //   subtitle: '.',
    //   img: '/Animation01.png',
    //   onpress: () => {
    //     onAddNode('special223', '223')
    //   }
    // },
    {
      title: 'Animating the Layout',
      subtitle: '.',
      img: '/Animation02.png',
      type: 'special224'
    },
    {
      title: 'Defining Named Frames with Plotly.addFrames',
      subtitle: '.',
      img: '/Animation03.png',
      type: 'special225'
    },
    {
      title: 'Animating Sequences of Frames',
      subtitle: '.',
      img: '/Animation04.png',
      type: 'special226'
    },
    {
      title: 'Animating Many Frames Quickly',
      subtitle: '.',
      img: '/Animation05.png',
      type: 'special227'
    },
    {
      title: 'Object Constancy',
      subtitle: '.',
      img: '/Animation06.png',
      type: 'special228'
    },
    {
      title: 'Frame Groups and Animation Modes',
      subtitle: '.',
      img: '/Animation07.png',
      type: 'special229'
    }
    // {
    //   title: 'Animating with a Slider',
    //   subtitle: '.',
    //   img: '/LineAndScatterPlot.png',
    //   type: 'special230'
    // }
  ],
  // section52: [
  //   {
  //     title: 'Animating with a Slider',
  //     subtitle: '.',
  //     img: '/AddingSliderToAnimations.png',
  //     type: 'special243'
  //   }
  // ],
  section53: [
    {
      title: 'Filled-Area-Animation',
      subtitle: '.',
      img: '/FielledAreaAnimation01.png',
      type: 'special231'
    },
    {
      title: 'Multiple Trace Filled-Area',
      subtitle: '.',
      img: '/FielledAreaAnimation02.png',
      type: 'special232'
    }
  ],
  section54: [
    {
      title: 'Map Animations',
      subtitle: '.',
      img: '/MapAnimation01.png',
      type: 'special233'
    }
  ],
  section55: [
    {
      title: 'Europe Bubble Map',
      subtitle: '.',
      img: '/MapAnimation01.png',
      type: 'special256'
    },
    {
      title: 'USA Bubble Map',
      subtitle: '.',
      img: '/MapAnimation01.png',
      type: 'special257'
    },
    {
      title: 'World Bubble Map',
      subtitle: '.',
      img: '/MapAnimation01.png',
      type: 'special259'
    }
  ],
  section56: [
    {
      title: 'USA Choropleth Map',
      subtitle: '.',
      img: '/MapAnimation01.png',
      type: 'special258'
    },
    {
      title: 'World Choropleth Map',
      subtitle: '.',
      img: '/MapAnimation01.png',
      type: 'special260'
    }
  ]
}
