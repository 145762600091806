// history
export const History = require('history').createBrowserHistory();

// view
// export { View } from './view/view';
// export { Modal } from './modal/modal';
// export { Notification } from './notification/notification';
export { Animate } from './animate/animate';

// context
// export { ViewContext } from './view/view';
// export { AuthContext, AuthProvider } from '../app/auth';

// layout
// export { AppLayout } from './layout/app/app';
// export { OnboardingLayout } from './layout/onboarding/onboarding';
// export { MissionControlLayout } from './layout/missioncontrol';
// export { AuthLayout } from './layout/auth/auth';
// export { HomeLayout } from './layout/home/home';
// export { Grid } from './grid/grid';

// nav
// export { AppNav } from './nav/app/app';
// export { SubNav } from './nav/sub/sub';
// export { AccountNav } from './nav/sub/account';
// export { Breadcrumbs } from './nav/breadcrumbs/breadcrumbs';
// export { HoverNav } from './nav/hover/hover';

// progress
// export { ProgressBar } from './progress/bar/bar';
// export { ProgressSteps } from './progress/steps/steps';

// stats & charts
// export { Stat } from './stat/stat';
//export { Chart } from './chart/chart';

// form
// export { Form } from './form/form';
// export { PaymentForm } from './form/form';
// export { Switch } from './form/switch/switch';
// export { Checkbox } from './form/checkbox/checkbox';
// export { Radio } from './form/radio/radio';
// export { Error } from './form/error/error';
// export { Fieldset } from './form/fieldset/fieldset';
// export { CardInput } from './form/input/card';
// export { EmailInput } from './form/input/email';
// export { HiddenInput } from './form/input/hidden';
// export { DateInput } from './form/input/date';
// export { NumberInput } from './form/input/number';
// export { PasswordInput } from './form/input/password';
// export { PhoneInput } from './form/input/phone';
// export { TextInput } from './form/input/text';
// export { FileInput } from './form/file/file';
// export { URLInput } from './form/input/url';
// export { Label } from './form/label/label';
// export { Legend } from './form/fieldset/legend';
// export { Select } from './form/select/select';
// export { FormHeader } from './form/header';

// list
// export { List } from './list/list';
// export { CheckList } from './list/checklist/checklist';


// homepage
// export { HomeNav } from './nav/home/home';
// export { Testimonial } from './testimonial/testimonial';
// export { Hero } from './hero/hero';
// export { Features } from './features/features';
// export { PricePlans } from './pricing/plans';
// export { Footer } from './footer/footer';
// export { Row } from './layout/row/row';
// export { Content } from './layout/row/content';


// the rest
// export { Article } from './article/article';
export { Button } from './button/button';
// export { Link } from './link/link';
// export { SocialShare } from './social/share';
// export { SocialSignin } from './social/signin';
export { Loader } from './loader/loader';
// export { TabView} from './tabview/tabview';
// export { Header } from './header/header';
export { Card } from './card/card';
// export { Logo } from './logo/logo';
// export { Image } from './image/image';
// export { Table } from './table/table';
// export { Badge } from './badge/badge';
export { Icon } from './icon/icon'
// export { Helper } from './helper/helper';
// export { Search } from './search/search';
// export { Onboarding } from './onboarding/onboarding';
// export { ContactForm } from './contact/contact';
// export { Feedback } from './feedback/feedback';
// export { CreditCard } from './card/credit';
// export { Paginate } from './paginate/paginate';
// export { User } from './user/user';