import React, { useEffect, useState } from 'react'
import { Handle } from 'react-flow-renderer'
import { BarChart } from 'react-chartkick'
import Plot from 'react-plotly.js'
import configData from '../../config.json'

var annotations2 = []
export default function FullyStyledBoxPlotBlock (props) {
  const removeStyle = {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    color: 'white'
  }

const [text, setText] = useState(false)
  const [annotations, setAnnotations] = useState([])

   useEffect(() => {
    annotations2=[]
    setAnnotations([])
  }, [props.chartSubType])

  useEffect(() => {
    // console.log('www?',props.existingWidget,props.addedAnnotations)
    if(props.annotedChartType === props.chartSubType){
    annotations2=props.addedAnnotations
    setAnnotations(props.addedAnnotations)
    }
 
  }, [props.existingWidget])

   const annotate = e => {
  if(props.operationIndex2 != 18)
    return
    
    var annote = {}
    annote.x = e.points[0].x
    annote.y = e.points[0].y
    annote.xref = 'x'
    annote.yref = 'y'
    annote.text = props.annotation
    annote.showarrow = true
    annote.arrowhead = 7
    annote.ax = 0
    annote.ay = -40

    annotations2.push({ ...annote }) 
    setAnnotations(annotations2)
    setText(!text)
  }
  var data = []
  var y = []

  var result

  const [data3, setData3] = useState()
  useEffect(() => {
    y.push(props.y)
    for (var i = 0; i < props.x && props.x.length; i++) {
      result = {
        type: 'box',
        y: y[i],
        name: props.x[i],
        boxpoints: 'all',
        jitter: 0.5,
        whiskerwidth: 0.2,
        fillcolor: 'cls',
        marker: {
          size: 2,
          color: props.color1
        },
        line: {
          width: 1
        }
      }
      data.push(result)
      setData3(data)
    }
  }, [])
  
useEffect(() => {
    //  console.log('added??', annotations)
      if ( props.getAnnotations)
      props.getAnnotations(annotations)
    }, [text])

return props.block ? (
    <>
      <Handle
        type='target'
        position='left'
        id='a'
        isValidConnection={connection => connection.source === '1'}
        onConnect={params => console.log('handle onConnect', params)}
        style={
          props.data
            ? {
                left: '-10px',
                top: '51%',
                borderRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                height: '300px',
                width: '20px',
                backgroundColor: configData.NODE_COLORS.HANDLE
              }
            : {
                left: '-10px',
                top: '53%',
                borderRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                height: '112px',
                width: '20px',
                backgroundColor: configData.NODE_COLORS.HANDLE
              }
        }
      ></Handle>
      {props.close == 1 && (
        <span className='remove' style={removeStyle} onClick={props.setNodeDsp}>
          x
        </span>
      )}
      <div
        style={
          props.data
            ? {
                background: configData.NODE_COLORS.BODY,
                color: '#FFF',
                height: '300px',
                width: '300px',
                margin: 10
              }
            : {
                background: '#e8aa7c',
                color: '#FFF',
                height: '110px',
                width: '220px',
                margin: 10
              }
        }
      >
        <h3 style={{ borderBottom: '1px solid #FFF', padding: 10 }}>
          Fully Styled Box Plot
        </h3>
        {props.data ? null : (
          <p style={{ marginLeft: 10, marginBottom: 0 }}>
            {'<- Connect to dataset'}
          </p>
        )}
        <div style={{ marginTop: -10 }}>
          <div style={{ overflow: 'hidden', height: 300 }}>
            <Plot
              style={{ marginTop: -75 }}
              // data={[
              //   {
              //     y: props.x,
              //     boxpoints: 'all',
              //     jitter: 0.5,
              //     whiskerwidth: 0.2,
              //     fillcolor: 'cls',
              //     marker: {
              //       size: 2
              //     },
              //     line: {
              //       width: 1
              //     },
              //     name: ''
              //   }
              // ]}
              data={data3}
              layout={{
                width: 300,
                height: 290,
                yaxis: {
                  title: props.yName,

                  autorange: true,
                  showgrid: true,
                  zeroline: true,
                  dtick: 5,
                  gridcolor: 'rgb(255, 255, 255)',
                  gridwidth: 1,
                  zerolinecolor: 'rgb(255, 255, 255)',
                  zerolinewidth: 2
                },
                xaxis: {
                  title: props.xName
                },

                margin: {
                  l: 40,
                  r: 30,
                  b: 80,
                  t: 100
                },
                paper_bgcolor: 'rgb(243, 243, 243)',
                plot_bgcolor: 'rgb(243, 243, 243)',
                showlegend: false
              }}
              config={{
                displaylogo: false,
                displayModeBar: true
              }}
            />
          </div>
        </div>
      </div>

      <Handle
        type='source'
        position='right'
        id='a'
        style={
          props.data
            ? {
                right: '-10px',
                top: '51%',
                borderRadius: 0,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                height: '300px',
                width: '20px',
                backgroundColor: configData.NODE_COLORS.HANDLE
              }
            : {
                right: '-10px',
                top: '53%',
                borderRadius: 0,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                height: '112px',
                width: '20px',
                backgroundColor: configData.NODE_COLORS.HANDLE
              }
        }
      ></Handle>
    </>
  ) : (
    <div>
      <Plot
        style={{ marginTop: -75 }}
        // data={[
        //   {
        //     y: props.x,
        //     boxpoints: 'all',
        //     jitter: 0.5,
        //     whiskerwidth: 0.2,
        //     fillcolor: 'cls',
        //     marker: {
        //       size: 2
        //     },
        //     line: {
        //       width: 1
        //     },
        //     name: ''
        //   }
        // ]}
        data={data3}
        layout={{
          width: 300,
          height: 290,
          yaxis: {
            title: props.yName,
            autorange: true,
            showgrid: true,
            zeroline: true,
            dtick: 5,
            gridcolor: 'rgb(255, 255, 255)',
            gridwidth: 1,
            zerolinecolor: 'rgb(255, 255, 255)',
            zerolinewidth: 2
          },
          xaxis: {
            title: props.xName
          },
          margin: {
            l: 40,
            r: 30,
            b: 80,
            t: 100
          },
          paper_bgcolor: 'rgb(243, 243, 243)',
          plot_bgcolor: 'rgb(243, 243, 243)',
          showlegend: false
        }}
        config={{
          displaylogo: false,
          displayModeBar: true
        }}
      />
    </div>
  )
}
