import React, { useState, useRef, useCallback, useEffect } from "react";
import moment from "moment";
import { useHistory, Prompt, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { SketchPicker } from "react-color";
import "../App.css";
import configData from "../config.json";
import Spreadsheet from "react-spreadsheet";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { elementsCustom } from "../../src/graphData";
import { chartTypes } from "../../src/graphData";
import { chartMainTypes } from "../../src/graphData";
import { visualizationOptions } from "../../src/graphData";
import FuzzySearch from "fuzzy-search";
import ReactDOMServer from "react-dom/server";
import Pdf from "react-to-pdf";
import { CSVLink } from "react-csv";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AutoMLEngine from "./AutoMLEngine";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

//icons
import IconButton from "@mui/material/IconButton";
import TableRows from "@mui/icons-material/TableRows";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import IntegrationInstructions from "@mui/icons-material/IntegrationInstructions";
import Summarize from "@mui/icons-material/Summarize";
import ChangeCircle from "@mui/icons-material/ChangeCircle";
import TableView from "@mui/icons-material/TableView";
import Segment from "@mui/icons-material/Segment";
import DateRange from "@mui/icons-material/DateRange";
import NoteAdd from "@mui/icons-material/NoteAdd";
import Mode from "@mui/icons-material/Mode";
import Palette from "@mui/icons-material/Palette";
import BackupTable from "@mui/icons-material/BackupTable";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MergeIcon from "@mui/icons-material/Merge";
import GroupsIcon from "@mui/icons-material/Groups";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import EditIcon from "@mui/icons-material/Edit";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AlbumIcon from "@mui/icons-material/Album";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CloseIcon from "@mui/icons-material/Close";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PrintIcon from "@mui/icons-material/Print";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";

import { ReactGrid, Highlight } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import GradingIcon from "@mui/icons-material/Grading";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import HighlightIcon from "@mui/icons-material/Highlight";

//components
import CustomNodeLineandScatterPlot from "../Components/lineCharts/CustomNodeLineandScatterPlot";
import SimpleInsetGraphBlock from "../Components/SubPlots/InsetPlots/SimpleInsetGraphBlock";
import CustomNodeDataLabelsHover from "../Components/scatterPlots/CustomNodeDataLabelsHover";
import CustomNodeScatterPlotWithColorDimension from "../Components/scatterPlots/CustomNodeScatterPlotWithColorDimension";
import CustomNodeBasicLinePlot from "../Components/lineCharts/CustomNodeBasicLinePlot";
import CustomNodeLineandScatterPlotWithNames from "../Components/lineCharts/CustomNodeLineandScatterPlotWithNames";
import CustomLineandScatterStyling from "../Components/lineCharts/CustomLineandScatterStyling";
import CustomNodeStyledLinePlot from "../Components/lineCharts/CustomNodeStyledLinePlot";
import CustomNodeColoredandStyledScatterPlot from "../Components/lineCharts/CustomNodeColoredandStyledScatterPlot";
import CustomNodeLineShapeOptionsforInterpolation from "../Components/lineCharts/CustomNodeLineShapeOptionsforInterpolation";
import CustomNodeLineDash from "../Components/lineCharts/CustomNodeLineDash";
import CustomNodeBasicBarChart from "../Components/barCharts/CustomNodeBasicBarChart";
import CustomNodeGroupedBarChart from "../Components/barCharts/CustomNodeGroupedBarChart";
import CustomNodeBarChartwithHoverText from "../Components/barCharts/CustomNodeBarChartwithHoverText";
import CustomNodeBarChartwithDirectLabels from "../Components/barCharts/CustomNodeBarChartwithDirectLabels";
import CustomNodeGroupedBarChartwithDirectLabels from "../Components/barCharts/CustomNodeGroupedBarChartwithDirectLabels";
import CustomNodeCustomizingIndividualBarColors from "../Components/barCharts/CustomNodeCustomizingIndividualBarColors";
import CustomNodeCustomizingIndividualBarWidths from "../Components/barCharts/CustomNodeCustomizingIndividualBarWidths";
import CustomNodeCustomizingIndividualBarBase from "../Components/barCharts/CustomNodeCustomizingIndividualBarBase";
import CustomNodeColoredandStyledBarChart from "../Components/barCharts/CustomNodeColoredandStyledBarChart";
import CustomNodeBarChartwithRelativeBarmode from "../Components/barCharts/CustomNodeBarChartwithRelativeBarmode";
import CustomNodeBasicPieChart from "../Components/pieCharts/CustomNodeBasicPieChart";
import CustomNodePieChartSubplots from "../Components/pieCharts/CustomNodePieChartSubplots";
import CustomNodeDonutChart from "../Components/pieCharts/CustomNodeDonutChart";
import ControlTextOrientationInsidePieChartSectors from "../Components/pieCharts/ControlTextOrientationInsidePieChartSectors";
import CustomNodeBubbleSizeonBubbleChart from "../Components/bubbleCharts/CustomNodeBubbleSizeBubbleChart";
import CustomNodeMarkerSizeandColorBubbleChart from "../Components/bubbleCharts/CustomNodeMarkerSizeandColorBubbleChart";
import CustomNodeHoverTextonBubbleChart from "../Components/bubbleCharts/CustomNodeHoverTextonBubbleChart";
import CustomNodeMarkerSizeColorandSymbolasArray from "../Components/bubbleCharts/CustomNodeMarkerSizeColorandSymbolasArray";
import CustomNodeBasicOverlaidAreaChart from "../Components/filledAreaPlots/CustomNodeBasicOverlaidAreaChart";
import CustomNodeOverlaidAreaChartWithoutBoundaryLines from "../Components/filledAreaPlots/CustomNodeOverlaidAreaChartWithoutBoundaryLines";
import CustomNodeStackedAreaChart from "../Components/filledAreaPlots/CustomNodeStackedAreaChart";
import CustomNodeNormalizedStackedAreaChart from "../Components/filledAreaPlots/CustomNodeNormalizedStackedAreaChart";
import CustomNodeSelectHoverPoints from "../Components/filledAreaPlots/CustomNodeSelectHoverPoints";
import CustomNodeBarChartwithLinePlot from "../Components/HorizontalBarCharts/CustomNodeBarChartwithLinePlot";
import CustomNodeStyledPointCloud from "../Components/PointCloud/CustomNodeStyledPointCloud";
import BasicSunBurstChart from "../Components/SunburstCharts/BasicSunBurstChart";
import BranchValues from "../Components/SunburstCharts/BranchValues";
import SunburstWithRepetedLabels from "../Components/SunburstCharts/SunburstWithRepetedLabels";
import SunburstLargeNumberSlices from "../Components/SunburstCharts/SunburstLargeNumberSlices";
import SunburstTextOrientaion from "../Components/SunburstCharts/SunburstTextOrientaion";
import SankeyDiagrams from "../Components/SankeyDiagrams/SankeyDiagrams";
import CreateSankeyCanvas from "../Components/SankeyDiagrams/CreateSankeyCanvas";
import AddNodesBlock from "../Components/SankeyDiagrams/AddNodesBlock";
import AddLinksBlock from "../Components/SankeyDiagrams/AddLinksBlock";
import DefineNodePositionBlock from "../Components/SankeyDiagrams/DefineNodePositionBlock";
import StyleSankeyDiagramBlock from "../Components/SankeyDiagrams/StyleSankeyDiagramBlock";
import BasicSymmetricErrorBarsBlock from "../Components/ErrorBars/BasicSymmetricErrorBarsBlock";
import BarChartwithErrorBarsBlock from "../Components/ErrorBars/BarChartwithErrorBarsBlock";
import HorizontalErrorBarsBlock from "../Components/ErrorBars/HorizontalErrorBarsBlock";
import AsymmetricErrorBarsBlock from "../Components/ErrorBars/AsymmetricErrorBarsBlock";
import ColoredandStyledErrorBarsBlock from "../Components/ErrorBars/ColoredandStyledErrorBarsBlock";
import ErrorBarsasaPercentageoftheyValueBlock from "../Components/ErrorBars/ErrorBarsasaPercentageoftheyValueBlock";
import AsymmetricErrorBarswithaConstantOffsetBlock from "../Components/ContinuousErrorBars/AsymmetricErrorBarswithaConstantOffsetBlock";
import BoxPlotThatDisplayesUnderlyingdataBlock from "../Components/boxPlots/BoxPlotThatDisplayesUnderlyingdataBlock";
import BoxPlotStylingOutliersBlock from "../Components/boxPlots/BoxPlotStylingOutliersBlock";
import BoxPlotStylingMeanandStandardDeviationBlock from "../Components/boxPlots/BoxPlotStylingMeanandStandardDeviationBlock";
import GroupedHorizontalBoxPlotBlock from "../Components/boxPlots/GroupedHorizontalBoxPlotBlock";
import ColoredBoxPlotBlock from "../Components/boxPlots/ColoredBoxPlotBlock";
import RainBowBoxPlotBlock from "../Components/boxPlots/RainBowBoxPlotBlock";
import HorizontalHistogramBlock from "../Components/histograms/HorizontalHistogramBlock";
import OverlaidHistogramBlock from "../Components/histograms/OverlaidHistogramBlock";
import StackedHistogramBlock from "../Components/histograms/StackedHistogramBlock";
import NormalizedHistogramBlock from "../Components/histograms/NormalizedHistogramBlock";
import SpecifyBinningFunctionBlock from "../Components/histograms/SpecifyBinningFunctionBlock";
import TwoDHistogramContourPlotwithHistogramSubplotsBlock from "../Components/2dDensityPLots/2DHistogramContourPlotwithHistogramSubplotsBlock";
import SimpleContourPlotBlock from "../Components/ContourPlots/SimpleContourPlotBlock";
import BasicContourPlotBlock from "../Components/ContourPlots/BasicContourPlotBlock";
import SettingXandYCoordinatesinaContourPlotBlock from "../Components/ContourPlots/SettingXandYCoordinatesinaContourPlotBlock";
import ColorscaleforContourPlotBlock from "../Components/ContourPlots/ColorscaleforContourPlotBlock";
import CustomizingSpacingBetweenXandYTicksBlock from "../Components/ContourPlots/CustomizingSpacingBetweenXandYTicksBlock";
import ConnecttheGapsbetweenNullValuesintheZMatrixBlock from "../Components/ContourPlots/ConnecttheGapsbetweenNullValuesintheZMatrixBlock";
import SmoothingContourLinesBlock from "../Components/ContourPlots/SmoothingContourLinesBlock";
import SmoothContourColoringBlock from "../Components/ContourPlots/SmoothContourColoringBlock";
import ContourLineLabelsBlock from "../Components/ContourPlots/ContourLineLabelsBlock";
import ContourLinesBlock from "../Components/ContourPlots/ContourLinesBlock";
import CustomColorscaleforContourPlotBlock from "../Components/ContourPlots/CustomColorscaleforContourPlotBlock";
import ColorBarTitleBlock from "../Components/ContourPlots/ColorBarTitleBlock";
import ColorBarSizeBlock from "../Components/ContourPlots/ColorBarSizeBlock";
import StylingColorBarTicksforContourPlotsBlock from "../Components/ContourPlots/StylingColorBarTicksforContourPlotsBlock";
import BasicHeatmapBlock from "../Components/Heatmaps/BasicHeatmapBlock";
import HeatmapwithCategoricalAxisLabelsBlock from "../Components/Heatmaps/HeatmapwithCategoricalAxisLabelsBlock";
import BasicTernaryPlotwithMarkersBlock from "../Components/TerneryPlots/BasicTernaryPlotwithMarkersBlock";
import SoilTypesTernaryPlotBlock from "../Components/TerneryPlots/SoilTypesTernaryPlotBlock";
import AddingDimensionsBlock from "../Components/ParallelCoordinatesPlot/AddingDimensionsBlock";
import BasicParallelCoordinatesPlotBlock from "../Components/ParallelCoordinatesPlot/BasicParallelCoordinatesPlotBlock";
import AnnotatedParallelCoordinatesPlotBlock from "../Components/ParallelCoordinatesPlot/AnnotatedParallelCoordinatesPlotBlock";
import AdvancedParallelCoordinatesPlotBlock from "../Components/ParallelCoordinatesPlot/AdvancedParallelCoordinatesPlotBlock";
import LogarithmicAxesBlock from "../Components/LogPlots/LogarithmicAxesBlock";
import BasicWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/BasicWaterfallChartBlock";
import MultiCategoryWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/MultiCategoryWaterfallChartBlock";
import HorizontalWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/HorizontalWaterfallChartBlock";
import StyleWaterfallChartBlock from "../Components/FinancialCharts/WaterfallCharts/StyleWaterfallChartBlock";
import SimpleCandleStickChartBlock from "../Components/CandleStickCharts/SimpleCandleStickChartBlock";
import CandlestickChartwithoutRangesliderBlock from "../Components/CandleStickCharts/CandlestickChartwithoutRangesliderBlock";
import CustomiseCandlestickChartwithShapesandAnnotationsBlock from "../Components/CandleStickCharts/CustomiseCandlestickChartwithShapesandAnnotationsBlock";
import CustomizingCandlestickChartColorsBlock from "../Components/CandleStickCharts/CustomizingCandlestickChartColorsBlock";
import AddRangeselectorBlock from "../Components/CandleStickCharts/AddRangeselectorBlock";
import BasicFunnelPlotBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/BasicFunnelPlotBlock";
import SettingMarkerSizeandColorBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/SettingMarkerSizeandColorBlock";
import StackedFunnelBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/StackedFunnelBlock";
import FunnelareaPlotBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/FunnelareaPlotBlock";
import MultiFunnelareaBlock from "../Components/FinancialCharts/FunnelandFunnelAreaCharts/MultiFunnelareaBlock";
import DateStringsBlock from "../Components/FinancialCharts/TimeSeries/DateStringsBlock";
import BasicTimeSeriesBlock from "../Components/FinancialCharts/TimeSeries/BasicTimeSeriesBlock";
import ManuallySetRangeBlock from "../Components/FinancialCharts/TimeSeries/ManuallySetRangeBlock";
import TimeSerieswithRangesliderBlock from "../Components/FinancialCharts/TimeSeries/TimeSerieswithRangesliderBlock";
import ThreeDScatterPlotBlock from "../Components/3DCharts/3DScatterPlot/ThreeDScatterPlotBlock";
import BasicRibbonPlotBlock from "../Components/3DCharts/BasicRibbonPlot/BasicRibbonPlotBlock";
import Topographical3DSurfacePlotBlock from "../Components/3DCharts/3DSurfacePlots/Topographical3DSurfacePlotBlock";
import SurfacePlotWithContoursBlock from "../Components/3DCharts/3DSurfacePlots/SurfacePlotWithContoursBlock";
import Multiple3DSurfacePlotsBlock from "../Components/3DCharts/3DSurfacePlots/Multiple3DSurfacePlotsBlock";
import Simple3DMeshPlotBlock from "../Components/3DCharts/3DMeshPlots/Simple3DMeshPlotBlock";
import ThreeDMeshPlotwithAlphahullBlock from "../Components/3DCharts/3DMeshPlots/3DMeshPlotwithAlphahullBlock";
import ThreeDMeshTetrahedronBlock from "../Components/3DCharts/3DMeshPlots/3DMeshTetrahedronBlock";
import ThreeDMeshCubeBlock from "../Components/3DCharts/3DMeshPlots/3DMeshCubeBlock";
import ThreeDLinewithMarkersPlotBlock from "../Components/3DCharts/3DLinePlots/3DLinewithMarkersPlotBlock";
import ThreeDLineSpiralPlotBlock from "../Components/3DCharts/3DLinePlots/3DLineSpiralPlotBlock";
import ThreeDRandomWalkPlotBlock from "../Components/3DCharts/3DLinePlots/3DRandomWalkPlotBlock";
import SimpleSubplotBlock from "../Components/SubPlots/SubPlots/SimpleSubplotBlock";
import CustomSizedSubplotBlock from "../Components/SubPlots/SubPlots/CustomSizedSubplotBlock";
import MultipleSubplotsBlock from "../Components/SubPlots/SubPlots/MultipleSubplotsBlock";
import SubplotswithSharedAxesBlock from "../Components/SubPlots/SubPlots/SubplotswithSharedAxesBlock";
import StackedSubplotsBlock from "../Components/SubPlots/SubPlots/StackedSubplotsBlock";
import StackedSubplotswithaSharedXAxisBlock from "../Components/SubPlots/SubPlots/StackedSubplotswithaSharedXAxisBlock";
import MultipleCustomSizedSubplotsBlock from "../Components/SubPlots/SubPlots/MultipleCustomSizedSubplotsBlock";
import Multiple3DSubplotsBlock from "../Components/SubPlots/3DSubPLots/Multiple3DSubplotsBlock";
import MixedSubPlotsBlock from "../Components/SubPlots/MixedSubPlots/MixedSubPlotsBlock";
import TableandChartSubplotBlock from "../Components/SubPlots/TableSubplots/TableandChartSubplotBlock";
import ClickEventDataBlock from "../Components/CustomChartEvents/ClickEvents/ClickEventDataBlock";
import BindingToClickEventBlock from "../Components/CustomChartEvents/ClickEvents/BindingToClickEventBlock";
import HoverEventDataBlock from "../Components/CustomChartEvents/HoverEvents/HoverEventDataBlock";
import CapturingHoverEventsDataBlock from "../Components/CustomChartEvents/HoverEvents/CapturingHoverEventsDataBlock";
import CapturingHoverEventsPixelsBlock from "../Components/CustomChartEvents/HoverEvents/CapturingHoverEventsPixelsBlock";
import TriggeringHoverEventsBlock from "../Components/CustomChartEvents/HoverEvents/TriggeringHoverEventsBlock";
import CoupledHoverEventsBlock from "../Components/CustomChartEvents/HoverEvents/CoupledHoverEventsBlock";
import CombinedClickandHoverEventsBlock from "../Components/CustomChartEvents/HoverEvents/CombinedClickandHoverEventsBlock";
import DisablingZoomEventsforXAxisBlock from "../Components/CustomChartEvents/DisableZoomEvents/DisablingZoomEventsforXAxisBlock";
import DisablingZoomEventsforXandYAxisBlock from "../Components/CustomChartEvents/DisableZoomEvents/DisablingZoomEventsforXandYAxisBlock";
import FilterBlock from "../Components/Transforms/FilterBlock";
import GroupByBlock from "../Components/Transforms/GroupBy/GroupByBlock";
import AggregationsBlock from "../Components/Transforms/Aggregations/AggregationsBlock";
import AggregateFunctionsBlock from "../Components/Transforms/Aggregations/AggregateFunctionsBlock";
import HistogramBinningBlock from "../Components/Transforms/Aggregations/HistogramBinningBlock";
import MappingWithAggregatesBlock from "../Components/Transforms/Aggregations/MappingWithAggregatesBlock";
import FilterAndGroupbyBlock from "../Components/Transforms/MultipleTransforms/FilterAndGroupbyBlock";
import FilterAndAggregatesBlock from "../Components/Transforms/MultipleTransforms/FilterAndAggregatesBlock";
import AllTransformsBlock from "../Components/Transforms/MultipleTransforms/AllTransformsBlock";
import AddTwoDropdownMenustoaChartBlock from "../Components/CustomControls/DropDownEvents/AddTwoDropdownMenustoaChartBlock";
import BinddropdowneventstochartsBlock from "../Components/CustomControls/DropDownEvents/BinddropdowneventstochartsBlock";
import RestyleButtonSingleAttributeBlock from "../Components/CustomControls/ButtonEvents/RestyleButtonSingleAttributeBlock";
import RestyleButtonMultipleAttributesBlock from "../Components/CustomControls/ButtonEvents/RestyleButtonMultipleAttributesBlock";
import RelayoutButtonBlock from "../Components/CustomControls/ButtonEvents/RelayoutButtonBlock";
import UpdateButtonBlock from "../Components/CustomControls/ButtonEvents/UpdateButtonBlock";
import AnimateButtonBlock from "../Components/CustomControls/ButtonEvents/AnimateButtonBlock";
import StylethebuttonsBlock from "../Components/CustomControls/ButtonEvents/StylethebuttonsBlock";
import BasicSliderBlock from "../Components/CustomControls/SliderEvents/BasicSliderBlock";
import BindComponentstotheAppearanceofaPlotBlock from "../Components/CustomControls/SliderEvents/BindComponentstotheAppearanceofaPlotBlock";
import AddaPlayButtontoControlaSliderBlock from "../Components/CustomControls/SliderEvents/AddaPlayButtontoControlaSliderBlock";
import LassoSelectionBlock from "../Components/CustomControls/LassoSelection/LassoSelectionBlock";
import BasicRangeSlideronTimeSeriesBlock from "../Components/CustomControls/RangeSliderandSelector/BasicRangeSlideronTimeSeriesBlock";
import AnimatingtheDataBlock from "../Components/Animations/Animations/AnimatingtheDataBlock";
import AnimatingtheLayoutBlock from "../Components/Animations/Animations/AnimatingtheLayoutBlock";
import DefiningNamedFramesaddFramesBlock from "../Components/Animations/Animations/DefiningNamedFramesaddFramesBlock";
import AnimatingSequencesofFramesBlock from "../Components/Animations/Animations/AnimatingSequencesofFramesBlock";
import AnimatingManyFramesQuicklyBlock from "../Components/Animations/Animations/AnimatingManyFramesQuicklyBlock";
import ObjectConstancyBlock from "../Components/Animations/Animations/ObjectConstancyBlock";
import FrameGroupsandAnimationModesBlock from "../Components/Animations/Animations/FrameGroupsandAnimationModesBlock";
//import AnimatingwithaSliderBlock from '../Components/Animations/Animations/AnimatingwithaSliderBlock'
import AnimatingwithaSliderBlock from "../Components/Animations/AddingSliderstoAnimations/AnimatingwithaSliderBlock";
import FilledAreaAnimationBlock from "../Components/Animations/FilledAreaAnimation/FilledAreaAnimationBlock";
import MultipleTraceFilledAreaBlock from "../Components/Animations/FilledAreaAnimation/MultipleTraceFilledAreaBlock";
import MapAnimationsBlock from "../Components/Animations/MapAnimation/MapAnimationsBlock";
import BasicTreemapsBlock from "../Components/TreeMaps/BasicTreemapsBlock";
import SetDifferentAttributesinTreeMapBlock from "../Components/TreeMaps/SetDifferentAttributesinTreeMapBlock";
import SetColorofTreemapSectorsBlock from "../Components/TreeMaps/SetColorofTreemapSectorsBlock";
import NestedLayersinTreemapBlock from "../Components/TreeMaps/NestedLayersinTreemapBlock";
import ContourandScatterPlotoftheMethodofSteepestDescentBlock from "../Components/MultipleChartTypes/ContourandScatterPlotoftheMethodofSteepestDescentBlock";
import LineChartandBarChartBlock from "../Components/MultipleChartTypes/LineChartandBarChartBlock";
import WebGLwithOneLakhpointsBlock from "../Components/WebGL/WebGLwithOneLakhpointsBlock";
import WebGLwithOneMillionpointsBlock from "../Components/WebGL/WebGLwithOneMillionpointsBlock";
import WebGLwithmanytracesBlock from "../Components/WebGL/WebGLwithmanytracesBlock";
import CustomNodeGraphandAxesTitles from "../Components/lineCharts/CustomNodeGraphandAxesTitles";
import CustomNodeDataLabelsOnThePlot from "../Components/scatterPlots/CustomNodeDataLabelsOnThePlot";
import CustomNodeConnectGapsBetweenData from "../Components/lineCharts/CustomNodeConnectGapsBetweenData";
import CustomNodeWaterfallBarChart from "../Components/barCharts/CustomNodeWaterfallBarChart";
import CustomNodeStackedBarChart from "../Components/barCharts/CustomNodeStackedBarChart";
import CustomNodeAutomaticallyAdjustMargin from "../Components/pieCharts/CustomNodeAutomaticallyAdjustMargin";
import CustomNodeCategoricalDotPlot from "../Components/DotPlot/CustomNodeCategoricalDotPlot";
import CustomNodeBasicHorizontalBarChart from "../Components/HorizontalBarCharts/CustomNodeBasicHorizontalBarChart";
import CustomNodeBasicPointCloud from "../Components/PointCloud/CustomNodeBasicPointCloud";
import BasicBoxPlotBlock from "../Components/boxPlots/BasicBoxPlotBlock";
import HorizontalBoxPlotBlock from "../Components/boxPlots/HorizontalBoxPlotBlock";
import GroupedBoxPlotBlock from "../Components/boxPlots/GroupedBoxPlotBlock";
import FullyStyledBoxPlotBlock from "../Components/boxPlots/FullyStyledBoxPlotBlock";
import ColoredandStyledHistogramBlock from "../Components/histograms/ColoredandStyledHistogramBlock";
import CumulativeHistogramBlock from "../Components/histograms/CumulativeHistogramBlock";
import TwoDHistogramContourPlotwithSliderControlBlock from "../Components/2dDensityPLots/2DHistogramContourPlotwithSliderControlBlock";
import FilledLinesBlock from "../Components/ContinuousErrorBars/FilledLinesBlock";
import CustomizingSizeandRangeofaContourPlotContoursBlock from "../Components/ContourPlots/CustomizingSizeandRangeofaContourPlotContoursBlock";
import AnnotatedHeatmapBlock from "../Components/Heatmaps/AnnotatedHeatmapBlock";
import HeatmapwithUnequalBlockSizesBlock from "../Components/Heatmaps/HeatmapwithUnequalBlockSizesBlock";
import CreateannotationonclickeventBlock from "../Components/CustomChartEvents/ClickEvents/CreateannotationonclickeventBlock";
import BasicHistogramBlock from "../Components/histograms/BasicHistogramBlock";
import BindingtoZoomEventsBlock from "../Components/CustomChartEvents/ZoomEvents/BindingtoZoomEventsBlock";
import ThreeDLinePlotBlock from "../Components/3DCharts/3DLinePlots/3DLinePlotBlock";
import EuropeBubbleMap from "../Components/Maps/BubbleMaps/EuropeBubbleMap";
import USABubbleMap from "../Components/Maps/BubbleMaps/USABubbleMap";
import USAChoroplethMap from "../Components/Maps/ChoroplethMaps/USAChoroplethMap";
import WorldChoroplethMap from "../Components/Maps/ChoroplethMaps/WorldChoroplethMap";
import WorldBubbleMap from "../Components/Maps/BubbleMaps/WorldBubbleMap";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

var actions2Box;
const actions = [
  { id: 0, icon: <FilterAltIcon />, name: "Filter" },
  { id: 1, icon: <MergeIcon />, name: "Merge" },
  { id: 2, icon: <GroupsIcon />, name: "Group" },
  { id: 3, icon: <LineAxisIcon />, name: "Slice" },
  { id: 4, icon: <ImportExportIcon />, name: "Sort" },
  { id: 5, icon: <EditIcon />, name: "Rename Columns" },
  { id: 6, icon: <ContentCopyIcon />, name: "Duplicate" },
  { id: 7, icon: <ScreenSearchDesktopIcon />, name: "Fuzzy Search" },
  { id: 8, icon: <AlbumIcon />, name: "Standardization" },
  { id: 9, icon: <FindReplaceIcon />, name: "Replace Null" },
  { id: 10, icon: <DangerousIcon />, name: "Incomplete" },
  { id: 11, icon: <FormatTextdirectionLToRIcon />, name: "Formatted" },
  { id: 12, icon: <FormatShapesIcon />, name: "Case Format" },
  { id: 13, icon: <AssessmentIcon />, name: "Discrete Range" },
];

var actions2 = [
  { id: 14, icon: <ChangeCircle />, name: "Change Data Source" },
  { id: 15, icon: <TableView />, name: "Data Table" },
  { id: 16, icon: <BackupTable />, name: "Change Dataset" },
  { id: 17, icon: <Palette />, name: "Change Colors" },
  { id: 18, icon: <NoteAdd />, name: "Annotation" },
  { id: 19, icon: <Mode />, name: "Formulas" },
  { id: 20, icon: <Segment />, name: "Select Graph Filter" },
  { id: 21, icon: <DateRange />, name: "YOY" },
  { id: 22, icon: <Summarize />, name: "Widget Overview" },
  { id: 23, icon: <AddBoxIcon />, name: "Graph Filter" },
  { id: 24, icon: <DriveFileRenameOutlineIcon />, name: "Edit Chart Title" },
];

const dateFilterOptions = [
  { id: 1, name: "is greater than" },
  { id: 2, name: "is greater than or equal to" },
  { id: 3, name: "is less than" },
  { id: 4, name: "is less than or equal to" },
];

const widgetmodalstyle = {
  position: "relative",
  top: "30%",
  left: "30%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  height: 320,
  width: 500,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  borderRadius: "4px",
};

const editChartTitleModalStyle = {
  position: "relative",
  top: "35%",
  left: "30%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  height: 180,
  width: 500,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};
const BeastModeFunctionTypes = [
  {
    name: "All",
  },
  {
    name: "Common",
  },
  {
    name: "Aggregate",
  },
  {
    name: "Mathematical",
  },
  {
    name: "Logical",
  },
  {
    name: "String",
  },
  {
    name: "Date and Time",
  },
];
const commonFunctions = [
  {
    id: 4,
    name: "AVG",
  },
  {
    id: 8,
    name: "COUNT",
  },
  {
    id: 36,
    name: "MAX",
  },
  {
    id: 37,
    name: "MIN",
  },
  {
    id: 59,
    name: "SUM",
  },
];

const aggregateFunctions = [
  {
    id: 4,
    name: "AVG",
  },
  {
    id: 6,
    name: "CIELING",
  },
  {
    id: 8,
    name: "COUNT",
  },
  {
    id: 9,
    name: "COUNT DISTINCT",
  },
  {
    id: 25,
    name: "FLOOR",
  },
  {
    id: 36,
    name: "MAX",
  },
  {
    id: 37,
    name: "MIN",
  },
  {
    id: 59,
    name: "SUM",
  },
  {
    id: 60,
    name: "SUM DISTINCT",
  },
];

const mathematicalFunctions = [
  {
    id: 1,
    name: "ABS",
  },
  {
    id: 39,
    name: "MOD",
  },
  {
    id: 46,
    name: "POWER",
  },
  {
    id: 48,
    name: "RAND",
  },
  {
    id: 51,
    name: "ROUND",
  },
];

const logicalFunctions = [
  {
    id: 29,
    name: "IFNULL",
  },
  {
    id: 43,
    name: "NULLIF",
  },
];

const stringFunctions = [
  {
    id: 7,
    name: "CONCAT",
  },
  {
    id: 31,
    name: "INSTR",
  },
  {
    id: 33,
    name: "LEFT",
  },
  {
    id: 34,
    name: "LENGTH",
  },
  {
    id: 35,
    name: "LOWER",
  },
  {
    id: 49,
    name: "REPLACE",
  },
  {
    id: 50,
    name: "RIGHT",
  },
  {
    id: 57,
    name: "SUBSTRING",
  },
  {
    id: 68,
    name: "TRIM",
  },
  {
    id: 70,
    name: "UPPER",
  },
];

const dateAndTimeFunctions = [
  {
    id: 2,
    name: "ADDDATE",
  },
  {
    id: 3,
    name: "ADDTIME",
  },
  {
    id: 10,
    name: "CURDATE",
  },
  {
    id: 11,
    name: "CURRENT_DATE",
  },
  {
    id: 12,
    name: "CURRENT_TIME",
  },
  {
    id: 13,
    name: "CURRENT_TIMESTAMP",
  },
  {
    id: 14,
    name: "CURTIME",
  },
  {
    id: 15,
    name: "DATE",
  },
  {
    id: 16,
    name: "DATE_ADD",
  },
  {
    id: 17,
    name: "DATE_FORMAT",
  },
  {
    id: 18,
    name: "DATE_SUB",
  },
  {
    id: 19,
    name: "DATE_DIFF",
  },
  {
    id: 20,
    name: "DAY",
  },
  {
    id: 21,
    name: "DAYNAME",
  },
  {
    id: 22,
    name: "DAYOFMONTH",
  },
  {
    id: 23,
    name: "DAYOFWEEK",
  },
  {
    id: 24,
    name: "DAYOFYEAR",
  },
  {
    id: 26,
    name: "FROM_DAYS",
  },
  {
    id: 27,
    name: "FROM_UNIXTIME",
  },
  {
    id: 28,
    name: "HOUR",
  },
  {
    id: 32,
    name: "LAST_DAY",
  },
  {
    id: 38,
    name: "MINUTE",
  },
  {
    id: 40,
    name: "MONTH",
  },
  {
    id: 41,
    name: "MONTH_NAME",
  },
  {
    id: 42,
    name: "NOW",
  },
  {
    id: 44,
    name: "PERIOD_ADD",
  },
  {
    id: 45,
    name: "PERIOD_DIFF",
  },
  {
    id: 47,
    name: "QUARTER",
  },
  {
    id: 52,
    name: "SEC_TO_TIME",
  },
  {
    id: 53,
    name: "SECOND",
  },
  {
    id: 55,
    name: "STR_TO_DATE",
  },
  {
    id: 56,
    name: "SUBDATE",
  },
  {
    id: 58,
    name: "SUBTIME",
  },
  {
    id: 61,
    name: "SYSDATE",
  },
  {
    id: 62,
    name: "TIME",
  },
  {
    id: 63,
    name: "TIME_FORMAT",
  },
  {
    id: 64,
    name: "TIME_TO_SEC",
  },
  {
    id: 65,
    name: "TIMEDIFF",
  },
  {
    id: 66,
    name: "TIMESTAMP",
  },
  {
    id: 67,
    name: "TO_DAYS",
  },
  {
    id: 69,
    name: "UNIX_TIMESTAMP",
  },
  {
    id: 72,
    name: "WEEK",
  },
  {
    id: 73,
    name: "WEEKDAY",
  },
  {
    id: 74,
    name: "WEEKOFYEAR",
  },
  {
    id: 75,
    name: "YEAR",
  },
  {
    id: 76,
    name: "YEARWEEK",
  },
];

var result,
  xAxis,
  yAxis,
  zAxis,
  s,
  l,
  condition = 0,
  input,
  column = 0,
  value,
  j,
  databases,
  columns3,
  columns4,
  tempColumnValues,
  flag2 = 0,
  flag3 = 0,
  opPerformed = 0,
  showL,
  hideEditchart = 0,
  timesSave = 0,
  timesDrill = 0,
  usedSaveAs = 0,
  columnXIndex,
  columnYIndex,
  columnZIndex,
  annotations;

var x = [],
  y = [],
  z = [],
  c1 = [],
  c2 = [],
  c3 = [],
  c4 = [],
  c5 = [],
  c6 = [],
  c7 = [],
  c8 = [],
  c9 = [],
  c10 = [],
  c11 = [],
  c12 = [],
  c13 = [],
  c14 = [],
  c15 = [],
  c16 = [],
  c17 = [],
  c18 = [],
  c19 = [],
  c20 = [],
  newColumn = [],
  sortType = -1;
var columnsbeforeupdate;
var optionId;
var invalidFormula = 0;
var widgetId,
  clickedEdit = 0;
var operationsBox = [],
  box6 = [],
  performedBox = [],
  chartDetails,
  chartHtml,
  chartData,
  caseType = 0,
  usedFormulas = 0,
  getStats = 0,
  column1,
  column2;
let box1 = [];
export default function DemoDashboard(props) {
  var min,
    max,
    avg,
    count,
    cards = [];
  let height, width;
  height = window.innerHeight;
  width = window.innerWidth;

  const modalstyle2 = {
    position: "relative",
    top: "10%",
    left: "8%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    height: height - 90,
    width: width - 200,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    borderRadius: "4px",
  };

  const modalstyle3 = {
    position: "relative",
    top: "10%",
    left: "40%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    height: height - 100,
    width: 350,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    borderRadius: "4px",
  };
  const beastModeFunctions = [
    {
      id: 1,
      name: "ABS",
      description: `Returns the absolute value for all values in a numeric column.In other words, any negative values become positive, and positive values stay the same. This is valuable when you want to see aggregation without considering positive and negative values.
      ABS(column)`,
    },
    {
      id: 2,
      name: "ADDDATE",
      description: `Adds date or datetime values (as intervals) to date values in a date column.
      ADDDATE(DateCol,interval)`,
    },
    {
      id: 3,
      name: "ADDTIME",
      description: `Adds a specified number of seconds to all values in a time column. For example, adding 15 seconds to 8:05 would return 8:20.
      ADDTIME(Time,25)`,
    },
    {
      id: 4,
      name: "AVG",
      description: `Returns the average value for each series in a column.
      AVG(column)`,
    },

    {
      id: 6,
      name: "CEILING",
      description: `Returns the highest value for each series in a column.
      This function differs from the MAX function in that CEILING is rounded whereas MAX is not rounded.
      CEILING(column)`,
    },
    {
      id: 7,
      name: "CONCAT",
      description: `Combines strings from two or more string columns.
      CONCAT(column 1,' ',column 2)`,
    },
    {
      id: 8,
      name: "COUNT",
      description: `Returns the number of row values in a column.
      COUNT(column)`,
    },
    {
      id: 9,
      name: "COUNT DISTINCT",
      description: `Returns the number of row values in a column.
      COUNTDINSTINCT(column)`,
    },
    {
      id: 10,
      name: "CURDATE",
      description: `Returns the current date.
      CURDATE()`,
    },
    {
      id: 11,
      name: "CURRENT_DATE",
      description: `Returns the current date.
      CURRENT_DATE()`,
    },
    {
      id: 12,
      name: "CURRENT_TIME",
      description: `Returns the current time.
      CURRENT_TIME()`,
    },
    {
      id: 13,
      name: "CURRENT_TIMESTAMP",
      description: `Returns the value of current date and time in YYYY-MM-DD HH:MM:SS format.
      CURRENT_TIMESTAMP()`,
    },

    {
      id: 14,
      name: "CURTIME",
      description: `Returns the current time.
      CURTIME()`,
    },
    {
      id: 15,
      name: "DATE",
      description: `Extracts and returns the dates from datetime values.
      DATE(DateCol)`,
    },
    {
      id: 16,
      name: "DATE_ADD",
      description: `Adds date or datetime values (as intervals) to date values in a date column.
      DATE_ADD(DateCol,interval)`,
    },
    {
      id: 17,
      name: "DATE_FORMAT",
      description: `Formats dates in a date/time column into a specific format.
      DATE_FORMAT(DateCol,%y)`,
    },
    {
      id: 18,
      name: "DATE_SUB",
      description: `Subtracts date or datetime values (as intervals) to date values in a date column.
      DATE_SUB(DateCol,interval)`,
    },
    {
      id: 19,
      name: "DATE_DIFF",
      description: `Returns the number of days between two dates from datetime values.
      DATEDIFF(DateCol 1,DateCol 2)`,
    },
    {
      id: 20,
      name: "DAY",
      description: `Returns the numerical day of the month for all values in a date/time column.
      DAY(DateCol)`,
    },
    {
      id: 21,
      name: "DAYNAME",
      description: `Returns the name of the day of the week for all values in a date/time column.
      DAYNAME(DateCol)`,
    },
    {
      id: 22,
      name: "DAYOFMONTH",
      description: `Returns the numerical day of the month for all values in a date/time column.
      DAYOFMONTH(DateCol)`,
    },
    {
      id: 23,
      name: "DAYOFWEEK",
      description: `Returns the numerical day of the week for all values in a date/time column.
      (e.g. '2' for 'Monday').
      DAYOFWEEK(DateCol).`,
    },
    {
      id: 24,
      name: "DAYOFYEAR",
      description: `Returns the numerical day of the year for all values in a date/time column.
      (e.g. '226' for the 226th day of the year).
      DAYOFYEAR(DateCol)`,
    },
    {
      id: 25,
      name: "FLOOR",
      description: `Returns the lowest value for each series in a numeric column. This function differs from the MIN function in that FLOOR is rounded whereas MIN is not.
      FLOOR(column)`,
    },
    {
      id: 26,
      name: "FROM_DAYS",
      description: `Converts day numbers into dates.
      FROM_DAYS(column)`,
    },
    {
      id: 27,
      name: "FROM_UNIXTIME",
      description: `Returns a date time value from UNIX Time.
      FROM_UNIXTIME(unix_timestamp, [format])`,
    },
    {
      id: 28,
      name: "HOUR",
      description: `Returns the hour for all values in a date/time column.
      (e.g. the time '3:36' would return '3').
      HOUR(DateCol)`,
    },
    {
      id: 29,
      name: "IFNULL",
      description: `Used in logical statements in which you want to specify a replacement for null values.
      IFNULL(column,0)`,
    },
    {
      id: 31,
      name: "INSTR",
      description: `Returns the position of the first occurrence of substring substr in string str.
      INSTR(column, substr)`,
    },
    {
      id: 32,
      name: "LAST_DAY",
      description: `Aggregates values for each month in a date/time column and returns each aggregation as the last day of each month.
      LAST_DAY(DateCol)`,
    },
    {
      id: 33,
      name: "LEFT",
      description: `Returns the leftmost len characters from the string str, or NULL if any argument is NULL.
      LEFT(str, len)`,
    },
    {
      id: 34,
      name: "LENGTH",
      description: `Returns the length of the string str, measured in characters.
      LENGTH(str)`,
    },
    {
      id: 35,
      name: "LOWER",
      description: `Converts strings from one or more string columns into lower-case.
      LOWER(column)`,
    },
    {
      id: 36,
      name: "MAX",
      description: `Returns the highest value for each series in a numeric column. This function differs from the CEILING function in that MAX is not rounded whereas CEILING is rounded.
      MAX(column)`,
    },
    {
      id: 37,
      name: "MIN",
      description: `Returns the lowest value for each series in a numeric column. This function differs from the FLOOR function in that MIN is not rounded whereas FLOOR is rounded.
      MIN(column)`,
    },
    {
      id: 38,
      name: "MINUTE",
      description: `Returns the value for each minute in a time column. If there is more than one instance of a particular minute in the column, the values for those minutes are aggregated.
      MINUTE(column)`,
    },
    {
      id: 39,
      name: "MOD",
      description: `Returns the remainder of each value in a numeric column divided by some specified number (dividend).
      MOD(column,2)`,
    },
    {
      id: 40,
      name: "MONTH",
      description: `Returns the month number (e.g. '9' for September) for all values in a date/time column.
      MONTH(DateCol)`,
    },
    {
      id: 41,
      name: "MONTHNAME",
      description: `Returns the name of the month (e.g. 'September' rather than '9') for all values in a date/time column.
      MONTHNAME(DateCol)`,
    },
    {
      id: 42,
      name: "NOW",
      description: `Returns the value of current date and time in YYYY-MM-DD HH:MM:SS format.
      NOW()`,
    },
    {
      id: 43,
      name: "NULLIF",
      description: `Returns null if the value in the first column equals the value in the second column; otherwise returns the value in the first column.
      NULLIF(column 1,column 2)`,
    },
    {
      id: 44,
      name: "PERIOD_ADD",
      description: `Adds the specified number of months to the values in a date column. For this to work, the date values must be months in the format YYYYMM.
      PERIOD_ADD(DateCol,6)`,
    },
    {
      id: 45,
      name: "PERIOD_DIFF",
      description: `Returns the number of months between months in two date columns. For this to work, the date values must be months in the format YYYYMM.
      PERIOD_DIFF(DateCol 1,DateCol 2)`,
    },
    {
      id: 46,
      name: "POWER",
      description: `Returns each value in a numeric column raised to a given power. If the column contains multiple series, the values are summed for each series.
      POWER(column,2)`,
    },
    {
      id: 47,
      name: "QUARTER",
      description: `Aggregates date value data into quarters in a year.
      QUARTER(DateCol)`,
    },
    {
      id: 48,
      name: "RAND",
      description: `Returns random values between 0 and 1.
      RAND(column)`,
    },
    {
      id: 49,
      name: "REPLACE",
      description: `Returns the string str with all occurrences of the string from_str replaced by the string to_str.
      REPLACE(column, from_str, to_str)`,
    },
    {
      id: 50,
      name: "RIGHT",
      description: `Returns the rightmost len characters from the string str, or NULL if any argument is NULL.
      RIGHT(column,len)`,
    },
    {
      id: 51,
      name: "ROUND",
      description: `Returns values in a numeric column rounded to the nearest specified decimal place. If you do not include a decimal place value in the function, returned values are rounded to the nearest whole number.
      ROUND(column)`,
    },
    {
      id: 52,
      name: "SEC_TO_TIME",
      description: `Converts seconds into hours, minutes, and seconds.
      For example, 3489 would be converted into 00:58:09.
      SEC_TO_TIME(column)`,
    },
    {
      id: 53,
      name: "SECOND",
      description: `Returns the value for each second in a time column.
      If there is more than one instance of a particular second in the column, the values for those seconds are aggregated.
      SECOND(column)`,
    },
    // {
    //   id: 54,
    //   name: 'STDDEV_POP'
    // },
    {
      id: 55,
      name: "STR_TO_DATE",
      description: `Converts string from a given string column into datetime values.
      You specify the column and the current date format used in those columns.
      STR_TO_DATE(DateCol,YYYY-DD-MM)`,
    },
    {
      id: 56,
      name: "SUBDATE",
      description: `Subtracts date or datetime values (as intervals) to date values in a date column.
      SUBDATE(DateCol,interval)`,
    },
    {
      id: 57,
      name: "SUBSTRING",
      description: `Extracts and returns a specified number of characters from all of the values in a string column.
      SUBSTRING(column,1,3)`,
    },
    {
      id: 58,
      name: "SUBTIME",
      description: `Subtracts a specified number of seconds from all values in a time column. For example, subtracting 30 seconds from 8:05:45 would return 8:05:15.
      SUBTIME(column,15)`,
    },
    {
      id: 59,
      name: "SUM",
      description: `Returns the sum of each series in a numeric column.
      SUM(column)`,
    },
    {
      id: 60,
      name: "SUM DISTINCT",
      description: `Returns the sum of unique values in a numeric column.
      SUMDISTINCT(column)`,
    },
    {
      id: 61,
      name: "SYSDATE",
      description: `Returns the current date and time in YYYY-MM-DD HH:MM:SS format, as in 2014-04-03T19:25:29.
      SYSDATE()`,
    },
    {
      id: 62,
      name: "TIME",
      description: `Extracts the times from datetime values.
      TIME(DateCol)`,
    },
    {
      id: 63,
      name: "TIME_FORMAT",
      description: `Formats time in a datetime column into a specific format.
      TIME_FORMAT(Date,format e.g HH:mm:ss a)`,
    },
    {
      id: 64,
      name: "TIME_TO_SEC",
      description: `Returns an elapsed number of seconds for all values in a date/time column.
      TIME_TO_SEC(DateCol)`,
    },
    {
      id: 65,
      name: "TIMEDIFF",
      description: `Returns the difference between values in two date/time columns, expressed as a time value.
      TIMEDIFF(Time 1,Time 2)
      `,
    },
    {
      id: 66,
      name: "TIMESTAMP",
      description: `Returns values in a date column as datetime values.
      TIMESTAMP(DateCol)`,
    },
    {
      id: 67,
      name: "TO_DAYS",
      description: `Returns the number of days since year 0 for all values in a date/time column.
      TO_DAYS(DateCol)`,
    },
    {
      id: 68,
      name: "TRIM",
      description: `Trims leading and trailing spaces for all values in string columns.
      TRIM(column)`,
    },
    {
      id: 69,
      name: "UNIX_TIMESTAMP",
      description: `If called with no argument, returns a Unix timestamp (seconds since '1970-01-01 00:00:00' UTC). The return value is an integer if no argument is given or the argument does not include a fractional seconds part, or DECIMAL if an argument is given that includes a fractional seconds part.
      UNIX_TIMESTAMP(), UNIX_TIMESTAMP(date)`,
    },
    {
      id: 70,
      name: "UPPER",
      description: `Converts strings from one or more string columns into upper-case.
      UPPER(column)`,
    },

    {
      id: 72,
      name: "WEEK",
      description: `This function returns the week number for date.
      WEEK(DateCol)`,
    },
    {
      id: 73,
      name: "WEEKDAY",
      description: `Returns the numerical day of the week for all values in a date/time column (e.g. '2' for 'Monday'). It works as same as DAYOFWEEK function.
      WEEKDAY(DateCol)`,
    },
    {
      id: 74,
      name: "WEEKOFYEAR",
      description: `Returns the calendar week of the date as a number in the range from 1 to 53.
      WEEKOFYEAR(date)`,
    },
    {
      id: 75,
      name: "YEAR",
      description: `Returns the year for all values in a date/time column.
      YEAR(DateCol)`,
    },
    {
      id: 76,
      name: "YEARWEEK",
      description: `Returns the year and week number for all values in a date/time column in the format YYYYWW (for example, 201232 for the 32nd week of the year 2012).
      YEARWEEK(DateCol)`,
    },
  ];
  const location = useLocation();
  const { subscription_id } = useParams();
  const [performed, setPerformed] = useState(false);
  const [finalData, setFinalData] = useState(props.data);
  const [operationPerformed, setOperationPerformed] = useState(false);
  const [operationIndex, setOperationIndex] = useState();
  const [operationIndex2, setOperationIndex2] = useState();
  const [operationIndexForDisplay, setOperationIndexForDisplay] = useState([]);
  const [operationIndexForDisplay2, setOperationIndexForDisplay2] = useState(
    []
  );
  const [selectAnyChart, setSelectAnyChart] = useState(true);
  const [graph, setGraph] = useState(0);
  const [category, setCategory] = useState(
    props.existingWidget === false ? 0 : -1
  );
  const [subCategory, setSubCategory] = useState();
  const [chartType, setchartType] = useState(
    props.existingWidget === false ? "Bar Charts" : ""
  );
  const [chartSubType, setchartSubType] = useState(
    props.existingWidget === false ? "special34" : ""
  );
  const [chartTitle, setChartTitle] = useState("Basic Bar chart");
  const [newChartTitle, setNewChartTitle] = useState("Basic Bar chart");
  const [invalidFormula2, setInvalidFormula2] = useState();

  const [annotedChartType, setAnnotedChartType] = useState();

  const [showDescription, setShowDescription] = useState(false);

  const [noWidgetTitle, setNoWidgetTitle] = useState(false);
  const [checkedColumn, setCheckedColumn] = useState(false);

  const [columnX, setColumnX] = useState(0);
  const [columnY, setColumnY] = useState(1);
  const [columnZ, setColumnZ] = useState(2);
  const [xName, setXName] = useState(props.columns[0]);
  const [yName, setYName] = useState(props.columns[1]);
  const [zName, setZName] = useState(props.columns[2]);
  const [columnXData, setColumnXData] = useState(props.x);
  const [columnYData, setColumnYData] = useState(props.y);
  const [columnZData, setColumnZData] = useState(props.y);
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);
  const [hoverIndex2, setHoverIndex2] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(1);
  const [dataTable, setDataTable] = useState(false);
  const [updatedData, setUpdatedData] = useState();

  const [columns, setColumns] = useState(props.columns);
  const [columnValues, setColumnValues] = useState();
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");
  const [picker1Visible, setPicker1Visible] = useState(false);
  const [picker2Visible, setPicker2Visible] = useState(false);
  const [picker3Visible, setPicker3Visible] = useState(false);
  const [addAnnotation, setAddAnnotaion] = useState(false);
  const [annotation, setAnnotation] = useState("");

  const [flag, setFlag] = useState(0);
  const [dupCheck, setDupCheck] = useState(0);
  const [replaceInput, setReplaceInput] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [fuzzyCount, setFuzzyCount] = useState(0);
  const [inComplete, setInComplete] = useState(false);
  const [checked, setChecked] = useState([]);
  const [case1, setCase] = useState([]);

  const [isColumnNumeric, setIsColumnNumeric] = useState(false);
  const [input1, setInput1] = useState("");
  const [min1, setMin1] = useState("");
  const [max1, setMax1] = useState("");
  const [avg1, setAvg1] = useState("");
  const [count1, setCount1] = useState("");
  const [sum1, setSum1] = useState("");
  const [selectedAll, setselectedAll] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [search, setSearch] = useState(false);
  const [isBeastMode, setIsBeastMode] = useState(false);
  const [isCreateSegment, setIsCreateSegment] = useState(false);
  const [segmentName, setSegmentName] = useState("");
  const [notEnteredFilterName, setNotEnteredFilterName] = useState(false);

  const [beastModeFunction, setBeastModeFunction] = useState(0);
  const [field, setField] = useState(0);
  const [newFieldName, setNewFieldName] = useState("");
  const [formula, setFormula] = useState("");
  const [allCards, setAllCards] = useState([]);
  const [isSaveWidget, setIsSaveWidget] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetDesc, setWidgetDesc] = useState("");
  const [functionType, setFunctionType] = useState(0);
  const [beastFunctions, setBeastFunctions] = useState(beastModeFunctions);
  const [functionHover, setFunctionHover] = useState(false);
  const [functionHoverIndex, setFunctionHoverIndex] = useState();
  const [findex, setFIndex] = useState();
  const [searchedFunction, setSearchedFunction] = useState("");
  const [search2, setSearch2] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [columnHover, setColumnHover] = useState(false);
  const [columnHoverIndex, setColumnHoverIndex] = useState();
  const [dateInput1, setDateInput1] = useState("");
  const [dateInput2, setDateInput2] = useState("");
  const [segments, setSegments] = useState([]);
  const [operationPerformedIndex, setOperationPerformedIndex] = useState();
  const [dateColumn, setDateColumn] = useState(0);
  const [scolumn, setScolumn] = useState();
  const [scolumn2, setScolumn2] = useState();
  const [selectedSegment, setSelectedSegment] = useState("");
  const [yoyOption, setYoyOption] = useState();

  const [sortType1, setSortType1] = useState(-1);
  const [newColumnName, setnewColumnName] = useState("");
  const [fuzzyInput, setfuzzyInput] = useState("");
  const [regexValue, setRegexValue] = useState("");

  const [widgetSaved, setWidgetSaved] = useState(false);
  const [widgetUpdated, setWidgetUpdated] = useState(false);
  const [databeforeupdate, setdatabeforeupdate] = useState();
  const [legendColumn, setLegendColumn] = useState();
  const [isLegend, setIsLegend] = useState(true);
  const [useLegend, setUseLegend] = useState(false);
  const [legendAdded, setLegendAdded] = useState(false);
  const [isExport, setisExport] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isExportPDF, setisExportPDF] = useState(false);
  const [isExportCSV, setisExportCSV] = useState(false);
  const [isExportHTML, setisExportHTML] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  const [editChartTitleModal, setEditChartTitleModal] = useState(false);

  const [alignment, setAlignment] = React.useState("data");
  const [isWidgetSaved, setIsWidgetSaved] = useState(false);

  const [isSavedModal, setIsSaveModal] = useState(false);
  const [xNumeric, setXNumeric] = useState(props.xaxisNumeric);
  const [yNumeric, setYNumeric] = useState(props.yaxisNumeric);
  const [zNumeric, setZNumeric] = useState(props.zaxisNumeric);

  const [noChartTitle, setNoChartTitle] = useState(false);

  const [dataset1, setDataset1] = useState();
  const [dataset2, setDataset2] = useState();
  const [addedannotations, setAddedAnnotations] = useState([]);

  const [containNull, setContainNull] = useState(0);
  const [rowsData, setrowsData] = React.useState();
  const [columnData, setColumnData] = React.useState();

  const [editMode, setEditMode] = useState(false);
  const [columnResize, setColumnResize] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [columnSelection, setColumnSelection] = useState(false);
  const [rowSelection, setRowSelection] = useState(false);
  const [fillHandle, setFillHandle] = useState(false);
  const [contextMenu, setContextMenu] = useState(false);

  const [highlight, setHighlight] = React.useState();
  const [highlightOn, setHighlightOn] = React.useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#067AB4",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#067AB4",
    },
  }));

  const handleFocus = (d) => {
    // console.log('focues', d)
    if (highlightOn) {
      d.borderColor = "#00ff00";
      box1.push(d);
      // console.log('focuesbox', box1)
      setHighlight(box1);
    }
  };

  useEffect(() => {
    console.log("ROWS>", rowsData);
    if (rowsData) convertData(rowsData);
  }, [rowsData]);

  const convertData = (data) => {
    let box2 = [];
    data.map((e, i) => {
      let box = [];
      // console.log('iiio', e)
      e.cells.map((e2) => {
        box.push(e2.text);
      });
      box2.push(box);
    });
    // console.log('data<<<', box2)
    setUpdatedData(box2);

    box2.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    xAxis = x;
    yAxis = y;
    zAxis = z;
    // setColumnX(0);
    // setColumnY(1);
    // setColumnZ(2);
    setColumnXData(x);
    setColumnYData(y);
    setColumnZData(z);
    opPerformed = 1;
    setOperationPerformed(true);
  };

  const setUpDataForGrid = (data) => {
    console.log("check DATA>>", data);
    let columns = data[0];
    let columnsBox = [];
    let cells = [];
    columns?.map((e) => {
      columnsBox.push({
        columnId: e,
        width: 150,
        resizable: true,
        reorderable: true,
      });
      cells.push({
        type: "header",
        text: e,
      });
    });

    console.log("b1");
    setColumnData(columnsBox);

    let rowsBox = [];

    rowsBox.push({
      rowId: "header",
      cells: cells,
    });
    let c = 0;
    console.log("b2");
    let data1 = [...data];
    data1.shift();
    data1.map((e) => {
      let cells1 = [];
      e?.map((e2) => {
        cells1.push({
          id: c,
          type: "text",
          text: typeof e2 === "string" ? e2 : e2?.toString(),
        });

        // console.log('In ExploreCols', cells1)
      });

      rowsBox.push({
        rowId: c,
        cells: cells1,
      });
      c++;
    });

    console.log("In ExploreCols", rowsBox);
    setrowsData(rowsBox);
  };
  const handleChange = (event, newAlignment) => {
    // console.log('toggle-', newAlignment)
    setAlignment(newAlignment);
  };
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      if (isExportCSV) {
        setisExportCSV(false);
      }
      if (isExportPDF) {
        setisExportPDF(false);
      }
      if (isExportHTML) {
        setisExportHTML(false);
      }
      if (isPrint) {
        setIsPrint(false);
      }
      setisExport(false);
    } else {
      if (isExportCSV) {
        setisExportCSV(false);
      }
      if (isExportPDF) {
        setisExportPDF(false);
      }
      if (isExportHTML) {
        setisExportHTML(false);
      }
      if (isPrint) {
        setIsPrint(false);
      }
      setisExport(false);
    }
  };
  const exportModalstyle = {
    position: "relative",
    top: "30%",
    left: "30%",
    // transform: 'translate(-50%, -50%)',
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    height: isExportCSV ? 170 : 230,
    width: 500,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    borderRadius: "4px",
  };

  const exportModalstyle2 = {
    position: "relative",
    top: "40%",
    left: "30%",
    // transform: 'translate(-50%, -50%)',
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    height: isExportCSV ? 170 : isPrint ? 90 : 230,
    width: 500,
    alignItems: "center",
    overflow: "auto",
    borderRadius: "4px",
  };
  const history = useHistory();
  const ref = React.createRef();
  const ref2 = React.createRef();
  const csvLink = useRef();

  const handleChange1 = ({ hex }) => {
    setColor1(hex);
  };
  const handleChange2 = ({ hex }) => {
    setColor2(hex);
  };
  const handleChange3 = ({ hex }) => {
    setColor3(hex);
  };

  const printDiv = () => {
    // console.log('ref.current', document.body.innerHTML)
    var pageHTML = ref.current.outerHTML;
    //  var printContents = document.getElementById('capture').innerHTML
    //var originalContents = document.body.innerHTML

    // document.body.innerHTML = pageHTML
    var openWindow = window.open("", "title", "attributes");
    openWindow.document.write(pageHTML);
    openWindow.document.close();
    openWindow.focus();
    openWindow.print();
    openWindow.close();

    //  window.print()
    //   document.body.innerHTML = originalContents
  };
  const exportCSV = () => {
    //  <CSVDownload data={data && data} target='_blank' />
    console.log("CSV");
    csvLink.current.link.click();
  };

  const _exportPdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      //  document.body.appendChild(canvas) // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4", false);
      pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
      pdf.save(fileName + ".pdf");
    });
  };

  const exportToHtml = () => {
    chartDetails = {};
    chartDetails.chartTitle = chartTitle;
    chartDetails.columnX = xName;
    chartDetails.columnY = yName;
    chartDetails.columnZ = zName;
    chartDetails.columnXData = columnXData;
    chartDetails.columnYData = columnYData;
    chartDetails.columnZData = columnZData;

    console.log("chartDetails", chartDetails);

    // let pageHTML = ref2.current.outerHTML
    //     let pageHTML = `<head>
    // <!-- Load plotly.js into the DOM -->
    // <script src='https://cdn.plot.ly/plotly-2.14.0.min.js'></script>
    // </head>

    // <body>
    // <div id='myDiv'><!-- Plotly chart will be drawn inside this DIV --></div>
    // <script>
    //   var trace1 = {
    // x: ${JSON.stringify(columnXData)},
    // y: ${JSON.stringify(columnYData)},
    // ${chartSubType === 'special24' ? 'xyz' : 'mode'}:  '${
    //       chartSubType === 'special20'
    //         ? 'lines+markers'
    //         : chartSubType === 'special22'
    //         ? 'markers+text'
    //         : 'markers'
    //     }',
    //  ${chartSubType === 'special21' ? 'type' : 'xyz'}:'${
    //       chartSubType === 'special21' ? 'scatter' : 'line'
    //     }',
    // name: 'Color1',
    // text: ${JSON.stringify(columnXData)} ,
    // marker: { size: ${chartSubType === 'special23' ? 15 : 12} },
    // color: ${color1}
    // };

    // var trace2 = {
    // x: ${JSON.stringify(columnXData)},
    // y: ${JSON.stringify(columnYData)},
    //     ${chartSubType === 'special24' ? 'xyz' : 'mode'}:'${
    //       chartSubType === 'special20'||   chartSubType === 'special27'
    //         ? 'lines+markers'
    //         : chartSubType === 'special22'
    //         ? 'markers+text'
    //         : 'markers'
    //     }',
    //     ${chartSubType === 'special21' ? 'type' : 'xyz'}:'${
    //       chartSubType === 'special21' ? 'scatter' : 'line'
    //     }',
    // name: 'Color2',
    // text: ${JSON.stringify(columnXData)},
    // marker: { size: 12 },
    // color: ${color2}
    // };

    // var data = [ trace1, trace2 ];

    // var layout = {

    // xaxis: {
    //   title: '${xName}'
    // },
    // yaxis: {
    //   title:  '${yName}'
    // },
    // title: '${chartTitle}'
    // };

    // Plotly.newPlot('myDiv', data, layout);

    // </script>
    // </body>`
    let pageHTML = `<head>
<!-- Load plotly.js into the DOM -->
<script src='https://cdn.plot.ly/plotly-2.14.0.min.js'></script>
</head>

<body>
<div id='myDiv'><!-- Plotly chart will be drawn inside this DIV --></div>
<script>
  

var data = ${JSON.stringify(chartData)};

var layout = {
 
xaxis: {
  title: '${xName}'
},
yaxis: {
  title:  '${yName}'
},
title: '${chartTitle}'
};

Plotly.newPlot('myDiv', data, layout);

</script>
</body>`;
    // console.log(pageHTML)
    chartHtml = pageHTML;
    let data = new Blob([pageHTML], { type: "data:attachment/text," });
    let csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName + ".html");
    tempLink.click();
  };

  const handleCallback = (childData) => {
    console.log("childdata", childData);
    chartData = childData;
  };

  const fetchAnnotations = (annots) => {
    annotations = annots;
    console.log("annots", annotations);
    saveWidgetOperation(26, annotations);
  };
  const viewSegments = () => {
    axios
      .post(
        configData.API_URL + "personalAccount/database/segment_viewall",
        {
          account_id: localStorage.getItem("account_id").toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        console.log("segment_viewall", response.data.data);
        setSegments(response.data.data);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 401) {
            if (window.confirm("Session expired. Please log in again.")) {
              // Optional: handle the OK button click, e.g., redirect to login page
              window.location.href = "/login";
            }
          }
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };

  const saveSegment = () => {
    console.log("saving segment", result);
    axios
      .post(
        configData.API_URL + "personalAccount/database/segment_save",
        {
          account_id: localStorage.getItem("account_id").toString(),
          name: segmentName,
          // description:dashboardDesc,
          data: opPerformed && result ? result : props.data2,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        console.log("response", response.data);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 401) {
            if (window.confirm("Session expired. Please log in again.")) {
              // Optional: handle the OK button click, e.g., redirect to login page
              window.location.href = "/login";
            }
          }
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };

  const handleDateFilter = (e) => {
    setYoyOption(e.target.value);
    optionId = e.target.value;
    saveWidgetOperation(e.target.value);
  };

  const handleDateFilter2 = () => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);

    if (updatedData) result = updatedData;
    else result = props.data2;
    let dataContainer = result;

    let result4 = dataContainer.filter((item, index) => {
      if (dataContainer[index][column]) {
        let dateValue = moment(dataContainer[index][column]).format(
          "YYYY-MM-DD"
        );
        if (optionId == 1) return dateValue > dateInput1;
        else if (optionId == 2) return dateValue >= dateInput1;
        else if (optionId == 3) return dateValue < dateInput1;
        else if (optionId == 4) return dateValue <= dateInput1;
      }
    });
    // console.log('date filtered', result)

    result4.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    xAxis = x;
    yAxis = y;
    zAxis = z;
    setColumnX(0);
    setColumnY(1);
    setColumnZ(2);
    setColumnXData(x);
    setColumnYData(y);
    setColumnZData(z);

    //  setUpdatedData(result4)
    convert(result4);
    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);
    // saveWidgetOperation(e.target.value)
  };
  const handleBeastMode = (mode) => {
    usedFormulas = 1;
    if (updatedData) var arr = updatedData;
    else arr = props.data2;

    console.log("checkDATA?", updatedData);

    if (beastModeFunction == 1) {
      //ABS
      let extractColumn = formula.substring(4, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(Math.abs(arr[k][columnIndex]));
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 2) {
      //ADDDATE

      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      if (extractColumn === "") {
        invalidFormula = 1;
        return;
      }
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      if (extractColumn === "") invalidFormula = 1;
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .add(mod, "d")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 3) {
      //ADDTIME
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .add(mod, "seconds")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 10) {
      //CURDATE
      // console.log(moment().format('MMMM d, YYYY'))
      if (mode === 1) {
        invalidFormula = 0;
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment().format("MMMM DD, YYYY"));
        }

        console.log("done", arr);
        //// if (flag === 1 || props.existingWidget === false) columns.pop();

        columns.push(newFieldName);

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 11) {
      //CURRENT_DATE

      if (mode === 1) {
        invalidFormula = 0;
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment().format("MMMM DD, YYYY"));
        }
        // console.log('columns>', columns)
        // if (flag === 1 || props.existingWidget === false) columns.pop();

        columns.push(newFieldName);
        // console.log('columns>>', columns)

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 12) {
      //CURRENT_TIME

      if (mode === 1) {
        invalidFormula = 0;
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment().format("HH:mm:ss a"));
        }
        // if (flag === 1 || props.existingWidget === false) columns.pop();

        //  console.log('columns>', columns)
        columns.push(newFieldName);
        // console.log('columns>>', columns)

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 13) {
      //CURRENT_TIMESTAMP

      if (mode === 1) {
        invalidFormula = 0;
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment().format("YYYY/MM/DD HH:mm:ss"));
        }
        // if (flag === 1 || props.existingWidget === false) columns.pop();

        columns.push(newFieldName);

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 14) {
      //CURTIME
      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let currentTime = moment();

      console.log("beforecheckBeast", arr, props.existingWidget);
      invalidFormula = 0;
      if (mode === 1) {
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment(currentTime).format("HH:MM:SS"));
        }
        console.log("COLS??", columns);
        // if (flag === 1 || props.existingWidget === false) columns.pop();
        columns.push(newFieldName);

        console.log("checkBeast", arr);
        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }

    if (beastModeFunction == 15) {
      //DATE
      let extractColumn = formula.substring(5, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("MMMM DD, YYYY"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 16) {
      //DATE_ADD
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(9, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );

      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          let part2Mod = formula.split(",")[1];
          let mod = part2Mod.substring(0, part2Mod.length - 1);
          console.log("mod--", mod);
          let columnIndex = columns.findIndex(
            (element) => element === extractColumn
          );
          // console.log('columnIndex--', columnIndex)
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .add(mod, "d")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 17) {
      //DATE_FORMAT
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(12, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              arr[k].push(moment(arr[k][columnIndex]).format(mod));
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 18) {
      //DATE_SUB
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(9, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;
            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .subtract(mod, "d")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 19) {
      //DATE_DIFF
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(10, formula.length - 1);
      console.log("columnname1-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let extractColumn2 = part2Mod.substring(0, part2Mod.length - 1);
      console.log("columnname2-", extractColumn2);
      let columnIndex1 = columns.findIndex(
        (element) => element === extractColumn
      );
      let columnIndex2 = columns.findIndex(
        (element) => element === extractColumn2
      );

      // console.log('columnIndex--', columnIndex)
      if (columnIndex1 == -1 || columnIndex2 == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex1] == "" || arr[k][columnIndex2] == "") {
              arr[k].push("Nan");
            } else {
              let monthDifference = moment(arr[k][columnIndex1]).diff(
                arr[k][columnIndex2],
                "days"
              );
              arr[k].push(monthDifference);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }

    if (beastModeFunction == 29) {
      //IFNULL
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(7, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;
            if (
              arr[k][columnIndex] == "" ||
              arr[k][columnIndex] == " " ||
              arr[k][columnIndex] == null ||
              arr[k][columnIndex] == "Null" ||
              arr[k][columnIndex] == "null"
            ) {
              arr[k][columnIndex] = mod;
            } else {
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 43) {
      //NULLIF
      let part1 = formula.split(",")[0];

      let extractColumn = part1.substring(7, formula.length - 1);
      console.log("columnname1-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let extractColumn2 = part2Mod.substring(0, part2Mod.length - 1);
      console.log("columnname2-", extractColumn2);
      let columnIndex1 = columns.findIndex(
        (element) => element === extractColumn
      );
      let columnIndex2 = columns.findIndex(
        (element) => element === extractColumn2
      );
      if (columnIndex1 == -1 || columnIndex2 == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex1] == "" || arr[k][columnIndex2] == "") {
              arr[k].push("Null");
            } else if (arr[k][columnIndex1] == arr[k][columnIndex2]) {
              arr[k].push("Null");
            } else {
              arr[k].push(arr[k][columnIndex1]);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 65) {
      //TIMEDIFF
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(9, formula.length - 1);
      console.log("columnname1-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let extractColumn2 = part2Mod.substring(0, part2Mod.length - 1);
      console.log("columnname2-", extractColumn2);
      let columnIndex1 = columns.findIndex(
        (element) => element === extractColumn
      );
      let columnIndex2 = columns.findIndex(
        (element) => element === extractColumn2
      );
      if (columnIndex1 == -1 || columnIndex2 == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          // console.log('columnIndex--', columnIndex)
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex1] == "" || arr[k][columnIndex2] == "") {
              arr[k].push("Nan");
            } else {
              let diff = moment.duration(
                moment(arr[k][columnIndex1]).diff(moment(arr[k][columnIndex2]))
              );
              let days = parseInt(diff.asDays());
              let hours = parseInt(diff.asHours());
              hours = hours - days * 24;
              arr[k].push(hours);
            }
          }

          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 20) {
      //numerical Day

      let extractColumn = formula.substring(4, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("DD"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 21) {
      //DAYNAME
      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("dddd"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 22) {
      //DAYOFMONTH

      let extractColumn = formula.substring(11, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("DD"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 23) {
      //DAYOFWEEK

      let extractColumn = formula.substring(10, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).weekday());
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 24) {
      //DAYOFYEAR

      let extractColumn = formula.substring(10, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).dayOfYear());
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 26) {
      //FROM_DAYS
      let extractColumn = formula.substring(10, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("DD/MM/YYYY"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }

    if (beastModeFunction == 28) {
      //HOUR

      let extractColumn = formula.substring(5, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("HH"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 32) {
      //LAST_DAY

      let extractColumn = formula.substring(9, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(
              moment(arr[k][columnIndex]).endOf("month").format("MMMM DD, YYYY")
            );
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 38) {
      //MINUTE

      let extractColumn = formula.substring(7, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("mm"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 40) {
      //MONTH number

      let extractColumn = formula.substring(6, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).month() + 1);
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 41) {
      //MONTHNAME
      let extractColumn = formula.substring(10, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("MMMM"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 42) {
      //NOW
      if (mode === 1) {
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment().format("YYYY/MM/DD HH:mm:ss"));
        }
        // if (flag === 1 || props.existingWidget === false) columns.pop();

        columns.push(newFieldName);
        console.log("updated columns", columns);

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 44) {
      //PERIOD_ADD
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(11, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .add(mod, "months")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 45) {
      //PERIOD_DIFF
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(12, formula.length - 1);
      console.log("columnname1-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let extractColumn2 = part2Mod.substring(0, part2Mod.length - 1);
      console.log("columnname2-", extractColumn2);
      let columnIndex1 = columns.findIndex(
        (element) => element === extractColumn
      );
      let columnIndex2 = columns.findIndex(
        (element) => element === extractColumn2
      );

      // console.log('columnIndex--', columnIndex)
      if (columnIndex1 == -1 || columnIndex2 == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex1] == "" || arr[k][columnIndex2] == "") {
              arr[k].push("Nan");
            } else {
              let monthDifference = moment(arr[k][columnIndex1]).diff(
                arr[k][columnIndex2],
                "months"
              );
              arr[k].push(monthDifference);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 47) {
      //QUARTER
      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let month = moment(arr[k][columnIndex]).month() + 1;
            arr[k].push(Math.ceil(month / 3));
          }
          //console.log('cols-', columns)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 52) {
      //SEC_TO_TIME
      let extractColumn = formula.substring(12, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let formattedSeconds = moment()
              .startOf("day")
              .seconds(parseInt(arr[k][columnIndex]))
              .format("HH:mm:ss");
            arr[k].push(formattedSeconds);
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 55) {
      //STR_TO_DATE
      let part1 = formula.split(",")[0];
      let extractColumn = part1.substring(12, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            if (arr[k][columnIndex] == "" || arr[k][columnIndex] == null) {
              arr[k].push(null);
            } else {
              let newDate = moment(arr[k][columnIndex]).format(mod);
              //console.log('ss', newDate)
              arr[k].push(newDate);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 56) {
      //SUBDATE
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;
            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .subtract(mod, "d")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 58) {
      //SUBTIME
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              let d1 = moment(arr[k][columnIndex])
                .subtract(mod, "seconds")
                .format("YYYY/MM/DD HH:mm:ss");
              arr[k].push(d1);
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 61) {
      //SYSDATE
      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment().format("YYYY/MM/DD HH:mm:ss"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 62) {
      //TIME
      let extractColumn = formula.substring(5, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("yyyy:mmdd"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 63) {
      //TIME_FORMAT
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(12, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            let d1, d2;

            if (arr[k][columnIndex] == "") {
              arr[k].push("Nan");
            } else {
              arr[k].push(moment(arr[k][columnIndex]).format(mod));
            }
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 66) {
      //TIMESTAMP
      let extractColumn = formula.substring(10, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("MMMM DD, YYYY"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }

    if (beastModeFunction == 64) {
      //TIME_TO_SEC
      let extractColumn = formula.substring(12, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(
              moment
                .duration(moment(arr[k][columnIndex]).format("HH:mm:ss"))
                .asSeconds()
            );
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 67) {
      //TO_DAYS
      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(
              Math.abs(
                moment("0001-01-01", "YYYY-MM-DD")
                  .startOf("day")
                  .diff(
                    moment(arr[k][columnIndex], "YYYY-MM-DD").startOf("day"),
                    "days"
                  )
              ) + 1
            );
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 69) {
      //UNIX_TIMESTAMP
      if (mode === 1) {
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(moment().unix());
        }
        // if (flag === 1 || props.existingWidget === false) columns.pop();

        columns.push(newFieldName);

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 72) {
      //WEEK
      let extractColumn = formula.substring(5, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("w"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 73) {
      //WEEKDAY

      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).weekday());
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 75) {
      //YEAR
      let extractColumn = formula.substring(5, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("YYYY"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 76) {
      //YEARWEEK
      let extractColumn = formula.substring(9, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(moment(arr[k][columnIndex]).format("YYYYWW"));
          }
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 8) {
      //COUNT
      let extractColumn = formula.substring(6, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          let c = 0;
          for (let k = 0, j = 0; k < arr.length; k++) {
            if (arr[k][columnIndex]) c = 1;
            arr[k].push(c);
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 9) {
      //COUNT DISTINCT
      let extractColumn = formula.substring(15, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          let c = 0;
          for (let k = 0, j = 0; k < arr.length; k++) {
            if (arr[k][columnIndex]) c = 1;
            arr[k].push(c);
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 6) {
      //CIELING
      let extractColumn = formula.substring(8, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(Math.ceil(arr[k][columnIndex]));
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 25) {
      //Floor
      let extractColumn = formula.substring(6, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(Math.floor(arr[k][columnIndex]));
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 70) {
      //UPPER
      let extractColumn = formula.substring(6, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].toUpperCase());
          }
          console.log("done--", arr);

          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 35) {
      //LOWER
      let extractColumn = formula.substring(6, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].toLowerCase());
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 68) {
      //TRIM
      let extractColumn = formula.substring(5, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].trim());
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 34) {
      //str LENGTH
      //   console.log('before--', columns, arr)
      let extractColumn = formula.substring(7, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].length);
          }
          //  console.log('done--', columns, arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 31) {
      //INSTR
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(6, formula.length);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      //  console.log('mod--', mod)
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      //    console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].indexOf(mod));
          }
          // console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 33) {
      //LEFT
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(5, formula.length);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      //  console.log('mod--', mod)
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      //    console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].substring(0, parseInt(mod)));
          }
          //  console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 50) {
      //RIGHT
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(6, formula.length);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      //  console.log('mod--', mod)
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          //    console.log('columnIndex--', columnIndex)
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(
              arr[k][columnIndex].substring(
                arr[k][columnIndex].length - mod,
                arr[k][columnIndex].length
              )
            );
          }
          //   console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 57) {
      //SUBSTRING
      let part1 = formula.split(",")[0];
      let extractColumn = part1.substring(10, formula.length);
      console.log("columnname--", extractColumn);

      let part2 = formula.split(",")[1];
      console.log("part2-", part2);

      let part3 = formula.split(",")[2];
      console.log("part3-", part3);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          let columnIndex = columns.findIndex(
            (element) => element === extractColumn
          );
          console.log("columnIndex--", columnIndex);
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(
              arr[k][columnIndex].substring(parseInt(part2), parseInt(part3))
            );
          }
          //  console.log('array', arr)

          //  console.log('columns check--', columns)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          //   console.log('columns--', columns)
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 49) {
      //REPLACE
      let part1 = formula.split(",")[0];
      let extractColumn = part1.substring(8, formula.length);
      console.log("columnname--", extractColumn);

      let part2 = formula.split(",")[1];
      console.log("part2-", part2);

      let part3 = formula.split(",")[2];
      let extractpart3 = part3.substring(0, part3.length - 1);
      console.log("part3-", extractpart3);

      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex].replace(part2, extractpart3));
          }
          //  console.log('array', arr)

          //  console.log('columns check--', columns)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          //   console.log('columns--', columns)
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 46) {
      //POWER
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(6, formula.length);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(Math.pow(arr[k][columnIndex], parseInt(mod)));
          }
          // console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 48) {
      //RANDOM
      if (mode === 1) {
        for (let k = 0, j = 0; k < arr.length; k++) {
          arr[k].push(Math.random());
        }
        //   console.log('done--', arr)
        // if (flag === 1 || props.existingWidget === false) columns.pop();

        console.log("columns--", columns);
        columns.push(newFieldName);
        console.log("check columns--", columns);

        convert(arr, 3);
        setUpDataForGrid(arr);
      }
    }
    if (beastModeFunction == 51) {
      //ROUND
      let extractColumn = formula.substring(6, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(Math.round(arr[k][columnIndex]));
          }

          //console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 7) {
      //CONCAT
      let part1 = formula.split(",")[0];
      let extractColumn = part1.substring(7, formula.length);
      console.log("column1-", extractColumn);

      let part2 = formula.split(",")[1];
      console.log("part2-", part2);

      let part3 = formula.split(",")[2];
      let extractpart3 = part3.substring(0, part3.length - 1);
      console.log("part3-", extractpart3);
      //  console.log('column2-', part3)
      let columnIndex1 = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("column1Index--", columnIndex1);
      let columnIndex2 = columns.findIndex(
        (element) => element === extractpart3
      );
      console.log("column2Index--", columnIndex2);
      if (columnIndex1 == -1 || columnIndex2 == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex1].concat(" ", arr[k][columnIndex2]));
          }
          //  console.log('array', arr)

          //  console.log('columns check--', columns)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          //   console.log('columns--', columns)
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 39) {
      //MOD
      let part1 = formula.split(",")[0];
      //console.log('mod--', mod)
      let extractColumn = part1.substring(4, formula.length);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let part2Mod = formula.split(",")[1];
      console.log("part2Mod", part2Mod);
      if (part2Mod === undefined || part2Mod === "" || part2Mod === ")") {
        console.log("formula Invalid");
        invalidFormula = 1;
        return;
      }
      let mod = part2Mod.substring(0, part2Mod.length - 1);
      console.log("mod--", mod);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      // console.log('columnIndex--', columnIndex)
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
        return;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(arr[k][columnIndex] % parseInt(mod));
          }
          //  console.log('array', arr)

          console.log("columns check--", columns);
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);

          console.log("columns--", columns);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 4) {
      //AVG
      let extractColumn = formula.substring(4, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          column = columnIndex;
          let avg = findAvg();

          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(avg);
          }

          //console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 36) {
      //MAX
      let extractColumn = formula.substring(4, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          column = columnIndex;
          let avg = findMax();

          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(avg);
          }

          //console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 37) {
      //MIN
      let extractColumn = formula.substring(4, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          column = columnIndex;
          let avg = findMin();

          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(avg);
          }

          //console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 59) {
      //SUM
      let extractColumn = formula.substring(4, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          column = columnIndex;
          let avg = findSum();

          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(avg);
          }

          //console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }
    if (beastModeFunction == 60) {
      //SUM DISTINCT
      let extractColumn = formula.substring(13, formula.length - 1);
      if (extractColumn === "") invalidFormula = 1;
      console.log("columnname-", extractColumn);
      let columnIndex = columns.findIndex(
        (element) => element === extractColumn
      );
      console.log("columnIndex--", columnIndex);
      if (columnIndex == -1) {
        console.log("formula Invalid");
        invalidFormula = 1;
      } else {
        invalidFormula = 0;
        if (mode === 1) {
          column = columnIndex;
          let avg = findSum();

          for (let k = 0, j = 0; k < arr.length; k++) {
            arr[k].push(avg);
          }

          //console.log('done--', arr)
          // if (flag === 1 || props.existingWidget === false) columns.pop();

          columns.push(newFieldName);
          convert(arr, 3);
          setUpDataForGrid(arr);
        }
      }
    }

    setIsWidgetSaved(false);
    if (mode === 1) {
      opPerformed = 1;
      setOperationPerformed(true);
      (x = []),
        (y = []),
        (z = []),
        (c1 = []),
        (c2 = []),
        (c3 = []),
        (c4 = []),
        (c5 = []),
        (c6 = []),
        (c7 = []),
        (c8 = []),
        (c9 = []),
        (c10 = []),
        (c11 = []),
        (c12 = []),
        (c13 = []),
        (c14 = []),
        (c15 = []),
        (c16 = []),
        (c17 = []),
        (c18 = []),
        (c19 = []),
        (c20 = []);
      arr.map((e) => {
        if (e[0]) x.push(e[0]);
        if (e[1]) y.push(e[1]);
        if (e[2]) z.push(e[2]);

        if (e[0]) c1.push(e[0]);
        if (e[1]) c2.push(e[1]);
        if (e[2]) c3.push(e[2]);
        if (e[3]) c4.push(e[3]);
        if (e[4]) c5.push(e[4]);
        if (e[5]) c6.push(e[5]);
        if (e[6]) c7.push(e[6]);
        if (e[7]) c8.push(e[7]);
        if (e[8]) c9.push(e[8]);
        if (e[9]) c10.push(e[9]);
        if (e[10]) c11.push(e[10]);
        if (e[11]) c12.push(e[11]);
        if (e[12]) c13.push(e[12]);
        if (e[13]) c14.push(e[13]);
        if (e[14]) c15.push(e[14]);
        if (e[15]) c16.push(e[15]);
        if (e[16]) c17.push(e[16]);
        if (e[17]) c18.push(e[17]);
        if (e[18]) c19.push(e[18]);
        if (e[19]) c20.push(e[19]);
        return e;
      });
    }
  };

  const searchValue = () => {
    if (columnValues) {
      const found = columnValues.filter((element) =>
        element.toLowerCase().includes(searchInput.toLowerCase())
      );
      console.log("searched arr", found);

      setColumnValues(found);
    }
  };
  const selectAll = () => {
    setselectedAll(!selectedAll);
    setChecked(columnValues.map((e) => e));
    if (selectedAll) {
      setChecked([]);
    }
  };

  const selectNone = () => {
    setselectedAll(false);
    const updatedList = [];
    setChecked(updatedList);
  };

  const applySegment = (event) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    console.log("applied segment", event.target.value);
    // console.log(event.target.value)
    setSelectedSegment(event.target.value);
    let stemp = segments.filter((e) => {
      return e.id == event.target.value;
    });
    //   console.log('sss', JSON.parse(stemp[0].data))

    let result = JSON.parse(stemp[0].data);
    console.log("check segment data", result);
    //setUpdatedData(result)
    let columns3 = result[0];
    setColumns(columns3);
    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });
    xAxis = x;
    yAxis = y;
    zAxis = z;
    console.log("x data", x);
    setColumnX(0);
    setColumnY(1);
    setColumnZ(2);
    setColumnXData(x);
    setColumnYData(y);
    setColumnZData(z);
    saveWidgetOperation();
    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    convert(result, 2);
    setUpDataForGrid(result);
  };

  const saveWidgetOperation = (hex, val) => {
    let operation = {};
    console.log("before push or replace", operationsBox);
    console.log("check op index", operationIndex, operationIndex2);
    if (operationIndex == 0) {
      operation.type = "filter";
      operation.typeId = operationIndex;
      operation.filter = {
        filter_type: 0,
        filter_column: column,
        filter_columnvalue_selected: checked,
      };
      console.log("obj", operation);
    }
    if (operationIndex == 1) {
      operation.type = "merge";
      operation.typeId = operationIndex;
      operation.merge = {
        merge_column1: scolumn,
        merge_column2: scolumn2,
      };

      console.log("obj", operation);
    }
    if (operationIndex == 2) {
      operation.type = "group";
      operation.typeId = operationIndex;
      operation.group = {
        group_column: scolumn,
      };
      console.log(" obj", operation);
    }
    if (operationIndex == 3) {
      operation.type = "slice";
      operation.typeId = operationIndex;
      operation.slice = {
        start_index: startIndex,
        end_index: lastIndex,
      };
      console.log(" obj", operation);
    }
    if (operationIndex == 4) {
      operation.type = "sort";
      operation.typeId = operationIndex;
      operation.sort = {
        order_type: sortType,
        sort_column: scolumn,
      };
      console.log(" obj", operation);
    }
    if (operationIndex == 5) {
      operation.type = "rename";
      operation.typeId = operationIndex;
      operation.rename = {
        rename_column: selectedColumn,
        rename_input: newColumnName,
      };
    }
    if (operationIndex == 6) {
      operation.type = "duplicate";
      operation.typeId = operationIndex;
    }
    if (operationIndex == 7) {
      operation.type = "fuzzysearch";
      operation.typeId = operationIndex;
      operation.fuzzysearch = {
        search_input: fuzzyInput,
      };
    }
    if (operationIndex == 8) {
      operation.type = "standarization";
      operation.typeId = operationIndex;
      operation.standarization = {
        regex_input: "",
      };
    }
    if (operationIndex == 9) {
      operation.type = "replacenull";
      operation.typeId = operationIndex;
      operation.replacenull = {
        replaceInput: replaceInput,
      };
    }
    if (operationIndex == 10) {
      operation.type = "incomplete";
      operation.typeId = operationIndex;
      operation.incomplete = {
        incomplete_column: column,
      };
    }
    if (operationIndex == 11) {
      operation.type = "formatted";
      operation.typeId = operationIndex;
    }
    if (operationIndex == 12) {
      operation.type = "caseformat";
      operation.typeId = operationIndex;
      operation.caseformat = {
        case_type: caseType,
      };
    }
    if (operationIndex == 13) {
      operation.type = "discreterange";
      operation.typeId = operationIndex;
    }
    if (operationIndex2 == 15) {
      operation.type = "datatable";
      operation.typeId = operationIndex2;
    }
    if (operationIndex2 == 16) {
      operation.type = "change_dataset";
      operation.typeId = operationIndex2;
      operation.change_dataset = {
        dataset_input: "",
      };
    }
    if (operationIndex2 == 17) {
      operation.type = "change_color";
      operation.typeId = operationIndex2;
      operation.change_color = {
        color1: color1,
        color2: color2,
      };
    }
    if (operationIndex2 == 18) {
      operation.type = "annotation";
      operation.typeId = operationIndex2;
      operation.annotations = val;
      operation.chartType = chartSubType;
    }
    if (operationIndex2 == 19) {
      operation.type = "beast_mode";
      operation.typeId = operationIndex2;
      operation.beast_mode = {
        field_name: newFieldName,
        formula: formula,
        function_type: functionType,
        function_name: beastModeFunction,
      };
    }
    if (operationIndex2 == 20) {
      operation.type = "segments";
      operation.typeId = operationIndex2;
      operation.segment = {
        segment_type: selectedSegment,
      };
    }
    if (operationIndex2 == 21) {
      operation.type = "Yoy";
      operation.typeId = operationIndex2;
      operation.Yoy = {
        yoy_column: dateColumn,
        yoy_filter_option: hex,
        yoy_filter_input: dateInput1,
      };
    }
    if (operationIndex2 == 23) {
      operation.type = "create_segment";
      operation.typeId = operationIndex2;
      operation.create_segment = {
        segment_name: segmentName,
        segment_filter: 0,
        segment_column: scolumn,
        segment_selected: checked,
      };
    }
    if (hex == 24) {
      operation.type = "add_legend";
      operation.typeId = 24;
      operation.add_legend = val;
    }
    if (hex == 25) {
      operation.type = "chartTitle_Edit";
      operation.typeId = 25;
      operation.chartTitle_Edit = {
        chartTitle_Edit: chartTitle,
      };
    }

    //  console.log('obj length=', Object.keys(operation).length)
    if (operationsBox.length == 0 && Object.keys(operation).length > 0) {
      console.log("pushed new operaion!", operationsBox);
      operationsBox.push(operation);
    } else {
      for (var i = 0; i < operationsBox.length; i++) {
        // console.log(
        //   'OGVFOb',
        //   operationsBox[i].typeId == operation.typeId,
        //   'sss',
        //   operationsBox[i].typeId,
        //   'fdfg',
        //   operation.typeId
        // )
        if (operationsBox[i].typeId == operation.typeId) {
          operationsBox[i] = operation;
        }
        if (operation.typeId) {
          if (
            operationsBox[i].typeId &&
            operationsBox[i].typeId == operation.typeId
          ) {
            operationsBox[i] = operation;
          }
        }
      }
    }
    console.log("operationsBox?", operationsBox);
    operationsBox.map((item) => {
      if (item.typeId) performedBox.push(item.typeId);
    });

    if (operation.typeId != undefined) {
      console.log("pushed typeId op", operation.typeId, performedBox);
      if (performedBox && !performedBox.includes(operation.typeId)) {
        operationsBox.push(operation);
      }
    }

    console.log(
      "last operation performed",
      operation,
      "updated operationBox",
      operationsBox
    );
    console.log("op array->", operationsBox, operationsBox.length);
  };

  const widgetOperationsAdd = (mode) => {
    setTimeout(() => {
      console.log("Data save", mode, widgetId, operationsBox, props.drill);
      axios
        .post(
          mode == 1
            ? configData.API_URL +
                "personalAccount/database/widget_operations_save"
            : configData.API_URL +
                "personalAccount/database/widget_operations_edit",
          mode == 1
            ? {
                account_id: localStorage.getItem("account_id"),
                name: widgetTitle,
                description: widgetDesc,
                xColumn: columnX,
                yColumn: columnY,
                zColumn: columnZ,
                flow_graph: "",
                chart_category: category,
                chart_type: chartType,
                graphType: chartSubType,
                operation: operationsBox,
                type: 1,
              }
            : {
                widget_id: props.widgetId,
                xColumn: columnX,
                yColumn: columnY,
                zColumn: columnZ,
                flow_graph: "",
                chart_category: category,
                chart_type: chartType,
                graphType: chartSubType,
                operation: operationsBox,
                isDrill: props.drill,
                name: widgetTitle,
                discription: widgetDesc,
                account_id: localStorage.getItem("account_id").toString(),
                parent_id: props.widgetId,
              },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((response) => {
          if (mode == 1) {
            console.log(
              "widget operations save API response",
              response.data.data.insertId
            );

            widgetDataSave(response.data.data.insertId, 1);
          } else {
            if (props.drill == 1) {
              console.log(
                "edit widget operation API response",
                response.data.data.insertId
              );
              widgetDataSave2(response.data.data.insertId, 2);
            }
            console.log("edit widget API response", response.data.data);
          }
          return response;
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message);
          }
        });
    }, 1000);
  };
  const widgetDataSave = (Id, mode) => {
    //   console.log('LOO', operationIndex)

    if (operationIndex === 6 || operationIndex === 4) {
      if (props.existingWidget) updatedData.shift();
      console.log("updatedData", updatedData);
    }
    console.log("updatedData->", updatedData);
    let newWdata = props.data2;
    console.log("timesSave", timesSave);

    if (mode === 2 && timesSave === 1 && usedFormulas === 0) {
      newWdata.unshift(columns);
    }
    setTimeout(() => {
      // console.log('Data saving1', newWdata)
      // console.log('Data saving2', usedFormulas)

      axios
        .post(
          mode == 1
            ? configData.API_URL + "personalAccount/database/widget_data_save"
            : configData.API_URL + "personalAccount/database/widget_data_edit",
          {
            widget_id: mode == 1 ? Id : props.widgetId,
            x: columnXData,
            y: columnYData,
            z: columnZData,
            result: updatedData ? updatedData : newWdata,
            columns: columns,
            type: 1,
          },

          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((response) => {
          console.log("response", response.data);
          if (mode == 1) {
            setWidgetSaved(true);
            usedSaveAs = 0;
          }
          if (mode == 2) {
            setIsWidgetSaved(true);
            setWidgetUpdated(true);
          }
          setTimeout(
            () => (mode == 1 ? setWidgetSaved(false) : setWidgetUpdated(false)),
            2000
          );
          return response;
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message);
          }
        });
    }, 1000);
  };

  const widgetDataSave2 = (Id, mode) => {
    let newWdata = props.data2;
    if (timesDrill === 1) newWdata.unshift(columns);
    console.log("Data saving", newWdata[0], updatedData && updatedData[0]);
    setTimeout(() => {
      axios
        .post(
          mode == 1
            ? configData.API_URL + "personalAccount/database/widget_data_save"
            : configData.API_URL + "personalAccount/database/widget_data_edit",
          {
            widget_id: mode == 2 ? Id : props.widgetId,
            x: columnXData,
            y: columnYData,
            z: columnZData,
            result: mode == 2 ? updatedData : updatedData,
            columns: columns,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((response) => {
          console.log("response", props.data2, response.data);
          if (mode == 1) setWidgetSaved(true);
          if (mode == 2) setWidgetUpdated(true);
          setTimeout(
            () => (mode == 1 ? setWidgetSaved(false) : setWidgetUpdated(false)),
            2000
          );
          return response;
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message);
          }
        });
    }, 1000);
  };

  const addDays = (date, days) => {
    var result2 = new Date(date);
    result2.setDate(result2.getDate() + days);
    return result2;
  };

  const getSelectedColumnData = () => {
    let cValues =
      column == 0
        ? c1
        : column == 1
        ? c2
        : column == 2
        ? c3
        : column == 3
        ? c4
        : column == 4
        ? c5
        : column == 5
        ? c6
        : column == 6
        ? c7
        : column == 7
        ? c8
        : column == 8
        ? c9
        : column == 9
        ? c10
        : column == 10
        ? c11
        : column == 11
        ? c12
        : column == 12
        ? c13
        : column == 13
        ? c14
        : column == 14
        ? c15
        : column == 15
        ? c16
        : column == 16
        ? c17
        : column == 17
        ? c18
        : column == 18
        ? c19
        : column == 19
        ? c20
        : null;
    return cValues;
  };

  const findMin = () => {
    let cValues = getSelectedColumnData();

    // console.log('check cdata', cValues)

    min = cValues[1];

    for (let i = 2; i < cValues.length; i++) {
      if (cValues[i] < min) {
        min = cValues[i];
      }
    }
    setMin1(min);
    console.log("check min", min);
    getStats = 1;
    return min;
  };

  const findMax = () => {
    let cValues = getSelectedColumnData();

    max = cValues[1];

    for (let i = 2; i < cValues.length; i++) {
      if (cValues[i] > max) {
        max = cValues[i];
      }
    }
    setMax1(max);
    console.log("check max", max);
    getStats = 1;
    return max;
  };

  const findAvg = () => {
    let cValues = getSelectedColumnData();
    cValues.shift();
    console.log("check cdata", cValues);

    const average = cValues.reduce(
      (acc, v, i, a) => parseInt(acc) + parseInt(v) / a.length,
      0
    );

    setAvg1(parseFloat(average).toFixed(2));
    console.log("check avg", average);
    getStats = 1;
    return average;
  };

  const findSum = () => {
    let cValues = getSelectedColumnData();
    cValues.shift();
    console.log("check cdata", cValues);

    const sum = cValues.reduce((a, b) => parseInt(a) + parseInt(b));
    setSum1(sum);
    console.log("check sum", sum);
    getStats = 1;
    return sum;
  };

  const findCount = () => {
    let cValues = getSelectedColumnData();
    // if (getStats === 0)
    cValues.shift();
    //  console.log('check cdata', cValues)
    const count1 = cValues.filter((i) => i === input).length;

    setCount1(count1);
    getStats = 1;
    console.log("check count", count1 - 1);
    return count;
  };
  const checkData = () => {
    console.log("col???", column, c1);
    let cValues =
      column == 0
        ? c1
        : column == 1
        ? c2
        : column == 2
        ? c3
        : column == 3
        ? c4
        : column == 4
        ? c5
        : column == 5
        ? c6
        : column == 6
        ? c7
        : column == 7
        ? c8
        : column == 8
        ? c9
        : column == 9
        ? c10
        : column == 10
        ? c11
        : column == 11
        ? c12
        : column == 12
        ? c13
        : column == 13
        ? c14
        : column == 14
        ? c15
        : column == 15
        ? c16
        : column == 16
        ? c17
        : column == 17
        ? c18
        : column == 18
        ? c19
        : column == 19
        ? c20
        : null;

    var isNumeric;
    let columnVals = cValues;
    console.log("val??", columnVals);
    if (columnVals != undefined) columnVals.shift();
    console.log("valsss", columnVals);
    if (columnVals) {
      isNumeric = !columnVals.some(isNaN);
    }
    console.log("IsNumeric?", isNumeric);

    if (isNumeric && columnVals.length > 0) {
      findMin();
      findMax();
      findAvg();
      findSum();
    }
    setIsColumnNumeric(isNumeric);
  };

  useEffect(() => {
    console.log("subscription_id", subscription_id);
    if (subscription_id === "price_1LfOlnSBwqDDsny7nprdkWUQ")
      actions2Box = actions2.filter((item) => item.id !== 14 && item.id !== 16);
    else actions2Box = actions2;
  }, []);

  useEffect(() => {
    // console.log('GotHit')
    setFlag(0);
    opPerformed = 0;
    viewSegments();
    // const html = ReactDOMServer.renderToString(<Card1 />)
    // console.log('see1', html.toString())
  }, [location]);

  useEffect(() => {}, [isColumnNumeric]);

  useEffect(() => {}, [addAnnotation]);

  useEffect(() => {
    console.log("updatedData->", updatedData);
  }, [updatedData]);

  const handleColumnResize = (ci, width) => {
    setColumnData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const handleChanges = (changes) => {
    setrowsData((prevPeople) => applyChangesToPeople(changes, prevPeople));
  };
  const applyChangesToPeople = (changes, prevPeople) => {
    // console.log('nnned', changes)
    changes.forEach((change) => {
      const personIndex = change?.rowId;
      const fieldName = change?.columnId;
      // console.log('nnned2', personIndex, fieldName, prevPeople)
      // const data = [...prevPeople]
      const targetIndex = prevPeople.findIndex((f) =>
        f.cells.find((e) => e.id === change.rowId)
      );
      let targetColumn = prevPeople[0].cells.findIndex(
        (f) => f.text === fieldName
      );
      // console.log('target', targetColumn, targetIndex, prevPeople[targetIndex])
      prevPeople[targetIndex].cells[targetColumn] = change.newCell;
      // console.log('target data', data)
    });
    return [...prevPeople];
  };

  const simpleHandleContextMenu = (
    selectedRowIds,
    selectedColIds,
    selectionMode,
    menuOptions
  ) => {
    return menuOptions;
  };

  // useEffect(() => {}, [operationIndex])

  // useEffect(() => {}, [operationIndex2])

  useEffect(() => {
    changeColumnXData();
    changeColumnYData();
    changeColumnZData();
  }, [performed]);

  useEffect(() => {
    if (updatedData) result = updatedData;
    else result = props.data2;
    setdatabeforeupdate(result);
    if (props.existingWidget) {
      //  result.unshift(props.columns)
      //  console.log('IMPCHECK?', result)

      setdatabeforeupdate(result);
    }
    timesSave = 0;
    usedSaveAs = 0;
  }, []);

  useEffect(() => {
    console.log("Title changed", chartTitle);
    saveWidgetOperation(25);
  }, [chartTitle]);

  useEffect(() => {
    console.log("clicked data table");
    saveWidgetOperation();
  }, [dataTable == true]);

  useEffect(() => {
    console.log("color1 changeed =>");
    saveWidgetOperation();
  }, [color1 != "" && color1]);

  useEffect(() => {
    console.log("color2 changeed =>");
    saveWidgetOperation();
  }, [color2 != "" && color2]);

  useEffect(() => {
    console.log("color3 changeed =>");
    saveWidgetOperation();
  }, [color3 != "" && color3]);

  useEffect(() => {
    if (props.existingWidget == false) {
      // setUseLegend(false)
      operationsBox = [];
      performedBox = [];
      box6 = [];
    }
    if (props.existingWidget) {
      operationsBox = [];
      performedBox = [];
      box6 = [];
      //fetch operationsPerformed
      console.log("Wdata?", props.xColumn, props.yColumn, props.data2);

      // console.log(
      //   'DATA->',
      //   props.data2,
      //   'D',
      //   JSON.parse(props.widgetBox.data).result
      // )
      // console.log('Wdata',JSON.parse(props.widgetBox.operations).graphType)
      setColumns(props.columns);

      props.data2.map((e) => {
        if (e[0]) c1.push(e[0]);
        if (e[1]) c2.push(e[1]);
        if (e[2]) c3.push(e[2]);
        if (e[3]) c4.push(e[3]);
        if (e[4]) c5.push(e[4]);
        if (e[5]) c6.push(e[5]);
        if (e[6]) c7.push(e[6]);
        if (e[7]) c8.push(e[7]);
        if (e[8]) c9.push(e[8]);
        if (e[9]) c10.push(e[9]);
        if (e[10]) c11.push(e[10]);
        if (e[11]) c12.push(e[11]);
        if (e[12]) c13.push(e[12]);
        if (e[13]) c14.push(e[13]);
        if (e[14]) c15.push(e[14]);
        if (e[15]) c16.push(e[15]);
        if (e[16]) c17.push(e[16]);
        if (e[17]) c18.push(e[17]);
        if (e[18]) c19.push(e[18]);
        if (e[19]) c20.push(e[19]);
      });

      setCategory(JSON.parse(props.widgetBox.operations).chart_category);
      setchartType(JSON.parse(props.widgetBox.operations).chart_type);

      //  console.log('CHECKDATA?', props.data2[0])
      setUpDataForGrid(props.data2);
      setUpdatedData(props.data2);
      setColumnX(props.xColumn);
      setColumnY(props.yColumn);
      setColumnZ(props.zColumn);
      columnXIndex = JSON.parse(props.widgetBox.operations).xColumn;
      columnYIndex = JSON.parse(props.widgetBox.operations).yColumn;
      columnZIndex = JSON.parse(props.widgetBox.operations).zColumn;
      setXName(columns[columnXIndex]);
      setYName(columns[columnYIndex]);
      setZName(columns[columnZIndex]);
      setColumnXData(JSON.parse(props.widgetBox.data).x);
      setColumnYData(JSON.parse(props.widgetBox.data).y);
      setColumnZData(JSON.parse(props.widgetBox.data).z);
      setSelectAnyChart(true);
      setTimeout(
        () => setchartSubType(JSON.parse(props.widgetBox.operations).graphType),
        3000
      );
      setOperationIndex(JSON.parse(props.widgetBox.operations).typeId);
      if (
        Object.keys(JSON.parse(props.widgetBox.operations).operation).length ===
        0
      ) {
        console.log("obj empty");
      } else {
        console.log(
          "else existing widget",
          JSON.parse(props.widgetBox.operations)
        );
        columnXIndex = JSON.parse(props.widgetBox.operations).xColumn;
        columnYIndex = JSON.parse(props.widgetBox.operations).yColumn;
        columnZIndex = JSON.parse(props.widgetBox.operations).zColumn;
        setXName(columns[columnXIndex]);
        setYName(columns[columnYIndex]);
        setZName(columns[columnZIndex]);

        let box = JSON.parse(props.widgetBox.operations).operation;
        operationsBox = [];
        operationsBox = box;
        if (
          (operationsBox.length > 0 &&
            operationsBox[operationsBox.length - 1].typeId == 15) ||
          operationsBox[operationsBox.length - 1].typeId == 16 ||
          operationsBox[operationsBox.length - 1].typeId == 17 ||
          operationsBox[operationsBox.length - 1].typeId == 18 ||
          operationsBox[operationsBox.length - 1].typeId == 19 ||
          operationsBox[operationsBox.length - 1].typeId == 20 ||
          operationsBox[operationsBox.length - 1].typeId == 21 ||
          operationsBox[operationsBox.length - 1].typeId == 23
        )
          setOperationIndex2(operationsBox[operationsBox.length - 1].typeId);
        else if (
          (operationsBox.length > 0 &&
            operationsBox[operationsBox.length - 1].typeId == 0) ||
          operationsBox[operationsBox.length - 1].typeId == 1 ||
          operationsBox[operationsBox.length - 1].typeId == 2 ||
          operationsBox[operationsBox.length - 1].typeId == 3 ||
          operationsBox[operationsBox.length - 1].typeId == 4 ||
          operationsBox[operationsBox.length - 1].typeId == 5 ||
          operationsBox[operationsBox.length - 1].typeId == 6 ||
          operationsBox[operationsBox.length - 1].typeId == 7 ||
          operationsBox[operationsBox.length - 1].typeId == 8 ||
          operationsBox[operationsBox.length - 1].typeId == 9 ||
          operationsBox[operationsBox.length - 1].typeId == 10 ||
          operationsBox[operationsBox.length - 1].typeId == 11 ||
          operationsBox[operationsBox.length - 1].typeId == 12 ||
          operationsBox[operationsBox.length - 1].typeId == 13
        )
          setOperationIndex(operationsBox[operationsBox.length - 1].typeId);

        box.map((item) => {
          // console.log('Item', item, item.typeId)

          if (item.typeId === 0) {
            setScolumn(parseInt(item.filter.filter_column));
            setChecked(item.filter.filter_columnvalue_selected);
          } else if (item.typeId === 1) {
            setScolumn(item.merge.merge_column1);
            setScolumn2(item.merge.merge_column2);
          } else if (item.typeId === 2) {
            setScolumn(item.group.group_column);
          } else if (item.typeId === 3) {
            setStartIndex(item.slice.start_index);
            setLastIndex(item.slice.end_index);
          } else if (item.typeId === 4) {
            setSortType1(item.sort.order_type);
            setScolumn(item.sort.sort_column);
          } else if (item.typeId === 5) {
            setSelectedColumn(item.rename.rename_column);
            setnewColumnName(item.rename.rename_input);
          } else if (item.typeId === 6) {
          } else if (item.typeId === 7) {
            if (item.fuzzysearch.search_input)
              setfuzzyInput(item.fuzzysearch.search_input);
          } else if (item.typeId === 8) {
            if (item.standarization.regex_input)
              setRegexValue(item.standarization.regex_input);
          } else if (item.typeId === 9) {
            if (item.replacenull.replaceInput)
              setReplaceInput(item.replacenull.replaceInput);
          } else if (item.typeId === 10) {
            if (item.incomplete.incomplete_column)
              setScolumn(item.incomplete.incomplete_column);
          } else if (item.typeId === 11) {
          } else if (item.typeId === 12) {
            caseType = item.caseformat.case_type;
          } else if (item.typeId === 13) {
          } else if (item.typeId === 15) {
            setDataTable(!dataTable);
          } else if (item.typeId === 16) {
            item.change_dataset.dataset_input;
          } else if (item.typeId === 17) {
            console.log("color change", item.change_color.color1);
            if (item.change_color.color1) setColor1(item.change_color.color1);
            if (item.change_color.color2) setColor2(item.change_color.color2);
          } else if (item.typeId === 18) {
            setAddedAnnotations(item.annotations);
            setAnnotedChartType(item.chartType);
          } else if (item.typeId === 19) {
            if (item.beast_mode.field_name)
              setNewFieldName(item.beast_mode.field_name);
            if (item.beast_mode.formula) setFormula(item.beast_mode.formula);

            if (item.beast_mode.function_type)
              setFunctionType(item.beast_mode.function_type);

            if (item.beast_mode.function_name)
              setBeastModeFunction(item.beast_mode.function_name);
          } else if (item.typeId === 20) {
            if (item.segment.segment_type)
              setSelectedSegment(item.segment.segment_type);
          } else if (item.typeId === 21) {
            if (item.Yoy.yoy_column) setDateColumn(item.Yoy.yoy_column);
            if (item.Yoy.yoy_filter_option)
              setYoyOption(item.Yoy.yoy_filter_option);
            if (item.Yoy.yoy_filter_input)
              setDateInput1(item.Yoy.yoy_filter_input);
          } else if (item.typeId === 23) {
            // box6.push(item.typeId)
            // setOperationIndex2(item.typeId)
            // if(item.create_segment.create_segment.segment_name)
            // if(item.create_segment.create_segment.segment_filter)
            // if(item.create_segment.create_segment.segment_column)
            // if(item.create_segment.create_segment.segment_selected)
          } else if (item.typeId === 24) {
            console.log("In add_Legend", item.add_legend);
            //  showL = item.add_legend
            setTimeout(() => {
              console.log("HITLEG");
              if (item.add_legend === true) setUseLegend(true);
            }, 6000);
            // if (item.add_legend) setUseLegend(item.add_legend)
          } else if (item.typeId === 25) {
            console.log("In Edited title", item.chartTitle_Edit);
            setChartTitle(item.chartTitle_Edit.chartTitle_Edit);
            setNewChartTitle(item.chartTitle_Edit.chartTitle_Edit);
          }
        });

        box.map((item) => box6.push(item.typeId));
        console.log("box->", box6);
        if (props.existingWidget) setOperationIndexForDisplay(box6);
        // setSelectAnyChart(true)
        // setchartSubType(JSON.parse(props.widgetBox.operations).graphType)
        setOpenModal(true);
        setOpenModal2(true);
      }
    }
  }, []);

  useEffect(() => {
    // console.log('function type', functionType, functionType === 0)
    functionType == 0
      ? setBeastFunctions(beastModeFunctions)
      : functionType == 1
      ? setBeastFunctions(commonFunctions)
      : functionType == 2
      ? setBeastFunctions(aggregateFunctions)
      : functionType == 3
      ? setBeastFunctions(mathematicalFunctions)
      : functionType == 4
      ? setBeastFunctions(logicalFunctions)
      : functionType == 5
      ? setBeastFunctions(stringFunctions)
      : functionType == 6
      ? setBeastFunctions(dateAndTimeFunctions)
      : [];
  }, [functionType]);

  useEffect(() => {
    console.log("searchF-", searchedFunction);
    let found;

    if (functionType == 0) {
      found = beastModeFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    if (functionType == 1) {
      found = commonFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    if (functionType == 2) {
      found = aggregateFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    if (functionType == 3) {
      found = mathematicalFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    if (functionType == 4) {
      found = logicalFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    if (functionType == 5) {
      found = stringFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    if (functionType == 6) {
      found = dateAndTimeFunctions.filter((element) =>
        element.name.toLowerCase().includes(searchedFunction.toLowerCase())
      );
    }
    setBeastFunctions(found);
    //console.log('searchFound', found)
  }, [searchedFunction]);

  useEffect(() => {
    // console.log('searchF-', searchedFunction)
    let found;

    found = columns.filter((element) =>
      element.toLowerCase().includes(searchedColumn.toLowerCase())
    );
    setColumns(found);
  }, [searchedColumn]);

  useEffect(() => {
    setSearch2(false);
  }, [searchedFunction == ""]);

  useEffect(() => {
    columnsbeforeupdate = columns;
  }, []);

  useEffect(() => {
    setColumns(columnsbeforeupdate);
  }, [searchedColumn == ""]);

  useEffect(() => {
    functionType == 0
      ? setBeastFunctions(beastModeFunctions)
      : functionType == 1
      ? setBeastFunctions(commonFunctions)
      : functionType == 2
      ? setBeastFunctions(aggregateFunctions)
      : functionType == 3
      ? setBeastFunctions(mathematicalFunctions)
      : functionType == 4
      ? setBeastFunctions(logicalFunctions)
      : functionType == 5
      ? setBeastFunctions(stringFunctions)
      : functionType == 6
      ? setBeastFunctions(dateAndTimeFunctions)
      : [];
  }, [search2 == false]);

  // useEffect(() => {
  //   // console.log('column changed')
  //   setChecked([])
  // }, [column])

  useEffect(() => {
    console.log("checked changed", checked);
  }, [checked]);

  useEffect(() => {
    // console.log('opIndex', operationIndex)
    viewAllDatasource();
  }, [operationIndex]);

  useEffect(() => {
    searchValue();
  }, [searchInput]);

  useEffect(() => {
    setSearch(false);
    let cValues = getSelectedColumnData();
    let uniqueItems = [...new Set(cValues)];
    // console.log('xyz', uniqueItems)
    setColumnValues(uniqueItems);
  }, [searchInput === ""]);

  useEffect(() => {
    setSearch(false);
    let cValues = getSelectedColumnData();
    let uniqueItems = [...new Set(cValues)];
    // console.log('xyz', uniqueItems)
    setColumnValues(uniqueItems);
  }, [column]);

  const viewAllDatasource = () => {
    axios
      .post(
        configData.API_URL +
          "personalAccount/database/viewall_datasource_names",
        {
          id: localStorage.getItem("ConnectionId"),
          account_id: localStorage.getItem("account_id").toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        //   console.log('all datasources api', response.data.data)

        databases = response.data.data;
        console.log(
          "user account Id and data sets",
          localStorage.getItem("account_id").toString(),
          databases
        );
        // storeDatasourceResult('e', 2)
        return response;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 401) {
            if (window.confirm("Session expired. Please log in again.")) {
              // Optional: handle the OK button click, e.g., redirect to login page
              window.location.href = "/login";
            }
          }
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };

  const storeDatasourceResult = (event, mode) => {
    axios
      .post(
        configData.API_URL +
          "personalAccount/database/viewall_datasource_result",
        mode === 2
          ? {
              datasource_id: databases[0].id.toString(),
            }
          : {
              datasource_id: event.target.value.toString(),
            },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        const tempArray2 = JSON.parse(response.data.data[0].result);
        columns3 = tempArray2;
        setColumns(Object.keys(columns3[0]));
        columns4 = Object.keys(columns3[0]);
        const array6 = Object.keys(tempArray2);
        const array7 = tempArray2.map((obj) => Object.values(obj));
        //console.log('what data', array6, array7)

        console.log("DATA..", array7);
        array7.shift();
        result = array7;
        result.map((e) => {
          if (e[0]) x.push(e[0]);
          if (e[1]) y.push(e[1]);
          if (e[2]) z.push(e[2]);

          if (e[0]) c1.push(e[0]);
          if (e[1]) c2.push(e[1]);
          if (e[2]) c3.push(e[2]);
          if (e[3]) c4.push(e[3]);
          if (e[4]) c5.push(e[4]);
          if (e[5]) c6.push(e[5]);
          if (e[6]) c7.push(e[6]);
          if (e[7]) c8.push(e[7]);
          if (e[8]) c9.push(e[8]);
          if (e[9]) c10.push(e[9]);
          if (e[10]) c11.push(e[10]);
          if (e[11]) c12.push(e[11]);
          if (e[12]) c13.push(e[12]);
          if (e[13]) c14.push(e[13]);
          if (e[14]) c15.push(e[14]);
          if (e[15]) c16.push(e[15]);
          if (e[16]) c17.push(e[16]);
          if (e[17]) c18.push(e[17]);
          if (e[18]) c19.push(e[18]);
          if (e[19]) c20.push(e[19]);

          return e;
        });

        xAxis = x;
        yAxis = y;
        zAxis = z;
        setColumnXData(x);
        setColumnYData(y);
        setColumnZData(z);

        setOperationPerformed(true);
        setIsWidgetSaved(false);

        setOperationPerformedIndex(hoverIndex);
        convert(result, 1);
        setUpDataForGrid(result);
        setUpdatedData(result);
        saveWidgetOperation();
        console.log("updated data-", result);
        return response;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 401) {
            if (window.confirm("Session expired. Please log in again.")) {
              // Optional: handle the OK button click, e.g., redirect to login page
              window.location.href = "/login";
            }
          }
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };

  const convert = (data, mode) => {
    let tmp = columns;
    var boxForTable = data;
    console.log("FLAG-", flag, data);
    // console.log('data-', data)
    if (mode != 4) setUpdatedData(data);

    if (flag === 0 && mode === 1) data.unshift(columns4);
    else if (flag === 0 && mode === 2) {
      boxForTable.unshift(tmp);
    } else if (flag === 1 && mode == 3) {
      data.shift();
      data.unshift(tmp);
    } else if (flag === 0 && mode == 3) {
      data.shift();
      data.unshift(tmp);
    } else if (flag === 0 && mode == 5) {
      data.unshift(tmp);
    } else if (flag === 1 && mode == 5) {
      data.unshift(tmp);
    }

    if (mode == 5 || mode == 2) setUpdatedData(data);

    // console.log('data--', data)
    setFlag(1);
    let def = [],
      ghm = [];

    boxForTable.map((e) => {
      def = [];
      e.map((f) => {
        if (f != null && typeof f != "object") def.push({ value: f });
        else if (f != null && typeof f == "object")
          def.push({ value: Object.values(f) });
      });
      ghm.push(def);
    });
    // console.log('ghm', ghm)
    setFinalData(ghm);
  };

  const caseFormat = (event) => {
    var databox2 = [];
    var databox;
    if (updatedData) databox = updatedData;
    else databox = props.data2;

    var done = false;
    var Regex = /^[a-zA-Z ]+$/;
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    // console.log('beforeCase', databox)
    while (!done) {
      let t = undefined;
      done = true;
      for (let i = 0; i < databox.length; i += 1) {
        var tempBox = [];
        // console.log('C---', databox[i])
        databox[i].forEach((e) => {
          if (Regex.test(e)) {
            if (e != null) {
              console.log("caseType??", caseType, e);
              if (caseType == 0) t = e.toLowerCase();
              else t = e.toUpperCase();
              tempBox.push(t);
            }
          } else {
            tempBox.push(e);
          }
        });
        databox2.push(tempBox);
      }
    }

    // console.log('Data0-', databox2)
    result = databox2;

    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });
    // xAxis = x
    // yAxis = y
    // zAxis = z
    // setColumnX(0)
    // setColumnY(1)
    // setColumnZ(2)
    // setColumnX(columns[columnXIndex])
    // setColumnY(columns[columnYIndex])
    // setColumnZ(columns[columnZIndex])
    // setColumnXData(x)
    // setColumnYData(y)
    // setColumnZData(z)
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    convert(result, 3);
    setUpDataForGrid(result);
    setPerformed(!performed);
  };
  const handleSortType = (event) => {
    sortType = event.target.value;
    setSortType1(event.target.value);
    console.log("sortt-", sortType);
  };

  const handleCaseType = (event) => {
    caseType = event.target.value;
    setCase(event.target.value);
    console.log("sortt-", caseType);
  };
  const handleSortAsc = (event) => {
    //  result = props.data2
    flag2 = 1;
    var tmp, databox, tmpBox;

    //sort
    console.log(event.target.value);
    var sorted;
    var done = false;
    var Regex = /^[a-zA-Z ]+$/;
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);

    var j = event.target.value;
    //sort
    if (updatedData) databox = updatedData;
    else databox = props.data2;
    console.log("Databox", databox);
    tmpBox = databox;
    tmpBox.shift();

    sorted = tmpBox.sort((a, b) => {
      if (Regex.test(a[j]) && Regex.test(b[j])) {
        if (a[j] && b[j]) return a[j].localeCompare(b[j]);
      } else {
        return parseInt(a[j]) - parseInt(b[j]);
      }
    });

    // console.log('sortedAsc-', sorted)

    sorted.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    saveWidgetOperation();
    convert(sorted, 5);
    setUpDataForGrid(sorted);
    setPerformed(!performed);
  };

  const handleSortDesc = (event) => {
    flag3 = 1;
    var tmp, databox, tmpBox;
    var sorted;
    var Regex = /^[a-zA-Z ]+$/;
    var done = false;
    var j = event.target.value;
    //  console.log('what', sortType, j)
    //sort
    if (updatedData) databox = updatedData;
    else databox = props.data2;
    // console.log('beforeSort', databox)
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);

    tmpBox = databox;
    tmpBox.shift();

    sorted = tmpBox.sort((a, b) => {
      if (Regex.test(b[j]) && Regex.test(a[j])) {
        if (a[j] && b[j]) return b[j].localeCompare(a[j]);
      } else {
        return parseInt(b[j]) - parseInt(a[j]);
      }
    });
    console.log("sortedDesc-", sorted);

    sorted.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    setOperationPerformed(true);
    setIsWidgetSaved(false);
    setOperationPerformedIndex(hoverIndex);
    saveWidgetOperation();
    // console.log('check sorted', sorted)
    convert(sorted, 5);
    setUpDataForGrid(sorted);
    setPerformed(!performed);
  };

  const handleFilterCondition = (event) => {
    condition = event.target.value;
  };

  const handleFilter = (event) => {
    column = event.target.value;
    console.log("selectedColumn-", column);
    setScolumn(column);
    checkData();
    filter2();
  };
  const handleFilter2 = (event) => {
    column = event.target.value;
    setScolumn(column);
    checkData();

    console.log("selectedColumn-", column);
  };
  const handleValue = (event) => {
    //  value = event.target.value
    console.log("selectedValue-", event.target);
  };

  var checkedItems =
    checked.length > 0
      ? checked.reduce((total, item) => {
          return total + ", " + item;
        })
      : "";

  const resetData = () => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    console.log("reset data", databeforeupdate);
    setUpdatedData(databeforeupdate);

    databeforeupdate.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);
    setPerformed(!performed);
    convert(databeforeupdate);
    setUpDataForGrid(databeforeupdate);
  };

  const applyFilter = (mode) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;
    // setdatabeforeupdate(result)
    var dataContainer = result;
    //    console.log('seee', dataContainer)
    var result4 = [];

    let result4_new = dataContainer.filter((item, index) =>
      checked.includes(dataContainer[index][column])
    );

    //  result = result4_new
    console.log("seee filtered", result4_new);
    //  setUpdatedData(result4_new)

    if (mode == 1) {
      result4_new.map((e) => {
        if (e[0]) x.push(e[0]);
        if (e[1]) y.push(e[1]);
        if (e[2]) z.push(e[2]);

        if (e[0]) c1.push(e[0]);
        if (e[1]) c2.push(e[1]);
        if (e[2]) c3.push(e[2]);
        if (e[3]) c4.push(e[3]);
        if (e[4]) c5.push(e[4]);
        if (e[5]) c6.push(e[5]);
        if (e[6]) c7.push(e[6]);
        if (e[7]) c8.push(e[7]);
        if (e[8]) c9.push(e[8]);
        if (e[9]) c10.push(e[9]);
        if (e[10]) c11.push(e[10]);
        if (e[11]) c12.push(e[11]);
        if (e[12]) c13.push(e[12]);
        if (e[13]) c14.push(e[13]);
        if (e[14]) c15.push(e[14]);
        if (e[15]) c16.push(e[15]);
        if (e[16]) c17.push(e[16]);
        if (e[17]) c18.push(e[17]);
        if (e[18]) c19.push(e[18]);
        if (e[19]) c20.push(e[19]);
        return e;
      });

      saveWidgetOperation();
      opPerformed = 1;
      setOperationPerformed(true);
      setIsWidgetSaved(false);

      setOperationPerformedIndex(hoverIndex);
      result = result4_new;
      convert(result4_new, 3);
      setUpDataForGrid(result4_new);
      setPerformed(!performed);
      //    console.log('filtered-3', result4_new)
    }
  };

  const filter = (mode) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;
    var dataContainer = result;
    var done = false;
    console.log("seee", dataContainer);
    var result4 = [];

    //console.log('seee2', result4)
    while (!done) {
      done = true;
      for (let i = 0; i < dataContainer.length; i += 1) {
        if (condition == 0) {
          if (dataContainer[i][column] === input) {
            result4.push(result[i]);
          }
        }
        if (condition == 1) {
          if (dataContainer[i][column] !== input) {
            result4.push(dataContainer[i]);
          }
        }
        if (condition == 2) {
          if (parseInt(dataContainer[i][column]) === parseInt(input)) {
            result4.push(dataContainer[i]);
          }
        }
        if (condition == 3) {
          if (parseInt(dataContainer[i][column]) > parseInt(input)) {
            result4.push(dataContainer[i]);
          }
        }
        if (condition == 4) {
          if (parseInt(dataContainer[i][column]) >= parseInt(input)) {
            result4.push(dataContainer[i]);
          }
        }
        if (condition == 5) {
          if (parseInt(dataContainer[i][column]) < parseInt(input)) {
            result4.push(dataContainer[i]);
          }
        }
        if (condition == 6) {
          if (parseInt(dataContainer[i][column]) <= parseInt(input)) {
            result4.push(dataContainer[i]);
          }
        }
      }
    }

    console.log("filtered-3", result4);
    result = result4;
    // c1.length = 0
    if (mode == 1) {
      result.map((e) => {
        if (e[0]) x.push(e[0]);
        if (e[1]) y.push(e[1]);
        if (e[2]) z.push(e[2]);

        if (e[0]) c1.push(e[0]);
        if (e[1]) c2.push(e[1]);
        if (e[2]) c3.push(e[2]);
        if (e[3]) c4.push(e[3]);
        if (e[4]) c5.push(e[4]);
        if (e[5]) c6.push(e[5]);
        if (e[6]) c7.push(e[6]);
        if (e[7]) c8.push(e[7]);
        if (e[8]) c9.push(e[8]);
        if (e[9]) c10.push(e[9]);
        if (e[10]) c11.push(e[10]);
        if (e[11]) c12.push(e[11]);
        if (e[12]) c13.push(e[12]);
        if (e[13]) c14.push(e[13]);
        if (e[14]) c15.push(e[14]);
        if (e[15]) c16.push(e[15]);
        if (e[16]) c17.push(e[16]);
        if (e[17]) c18.push(e[17]);
        if (e[18]) c19.push(e[18]);
        if (e[19]) c20.push(e[19]);
        return e;
      });

      setOperationPerformed(true);
      setIsWidgetSaved(false);
      setOperationPerformedIndex(hoverIndex);
      convert(result, 3);
      setUpDataForGrid(result);
      setPerformed(!performed);
      setUpdatedData(result);
    }
  };

  const filter2 = () => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;
    var dataContainer = result;

    for (let k = 0, j = 0; j <= dataContainer[0].length; k++) {
      if (k == dataContainer.length - 1) {
        j++, (k = 0);
      }
      if (j == 0) c1.push(dataContainer[k][j]);
      else if (j == 1) c2.push(dataContainer[k][j]);
      else if (j == 2) c3.push(dataContainer[k][j]);
      else if (j == 3) c4.push(dataContainer[k][j]);
      else if (j == 4) c5.push(dataContainer[k][j]);
      else if (j == 5) c6.push(dataContainer[k][j]);
      else if (j == 6) c7.push(dataContainer[k][j]);
      else if (j == 7) c8.push(dataContainer[k][j]);
      else if (j == 8) c9.push(dataContainer[k][j]);
      else if (j == 9) c10.push(dataContainer[k][j]);
      else if (j == 10) c11.push(dataContainer[k][j]);
      else if (j == 11) c12.push(dataContainer[k][j]);
      else if (j == 12) c13.push(dataContainer[k][j]);
      else if (j == 13) c14.push(dataContainer[k][j]);
      else if (j == 14) c15.push(dataContainer[k][j]);
      else if (j == 15) c16.push(dataContainer[k][j]);
      else if (j == 16) c17.push(dataContainer[k][j]);
      else if (j == 17) c18.push(dataContainer[k][j]);
      else if (j == 18) c19.push(dataContainer[k][j]);
      else if (j == 19) c20.push(dataContainer[k][j]);
    }
    // console.log('values-', c1)
    let cValues = getSelectedColumnData();

    let uniqueItems = [...new Set(cValues)];
    setColumnValues(uniqueItems);
  };

  const handleCheck = (event, position) => {
    var updatedList = [...checked];

    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const MyComponent = (dt) => {
    let { data } = dt;
    return (
      <div
        className="screen1"
        style={{
          marginTop: alignment === "data" ? 15 : 0,
          width: window.innerWidth - 150,
          overflow: "scroll",
          height: "73vh",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          // alignSelf: "center",
          // margin: "auto",
          alignItems: "center",
          // paddingTop: "20px",
          // backgroundColor: "cyan",
        }}
      >
        {/* <Spreadsheet data={data} /> */}
        {data && (
          <ReactGrid
            rows={rowsData}
            columns={columnData}
            enableRangeSelection={fillHandle ? true : false}
            enableFillHandle={fillHandle ? true : false}
            enableFullWidthHeader
            enableRowSelection={rowSelection ? true : false}
            enableColumnSelection={columnSelection ? true : false}
            stickyTopRows={stickyHeader ? 1 : 0}
            onColumnResized={columnResize ? handleColumnResize : null}
            onCellsChanged={editMode ? handleChanges : null}
            onContextMenu={simpleHandleContextMenu}
            onFocusLocationChanged={handleFocus}
            highlights={highlightOn ? highlight : []}
          />
        )}
      </div>
    );
  };
  const handleFuzzySearch = (mode) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;
    // console.log('I am in Fuzzy Search 177283892918180')
    console.log(fuzzyInput);
    const searcher = new FuzzySearch(result, [], {
      caseSensitive: false,
    });
    const res = searcher.search(mode === 1 ? regexValue : fuzzyInput);
    setFuzzyCount(res.length);
    result = res;
    convert(result, 3);
    setUpDataForGrid(result);

    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    saveWidgetOperation();
    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);
    setPerformed(!performed);
    setOperationPerformedIndex(hoverIndex);
  };

  const handleCheckIncomplete = (mode) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;

    result.map((e) => {
      console.log("eee?", e[scolumn] === "", e[scolumn]);
      if (
        e[scolumn] === "" ||
        e[scolumn] === null ||
        e[scolumn] === " " ||
        e[scolumn] === undefined
      )
        setInComplete(true);
      // else setInComplete(false)
    });

    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });
    xAxis = x;
    yAxis = y;
    zAxis = z;

    opPerformed = 1;

    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    setPerformed(!performed);
  };

  const handleRenameColumn = (event) => {
    setSelectedColumn(event.target.value);
  };

  const rename = () => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;

    result.shift();
    result.unshift(columns);

    result[(0, 0)][selectedColumn] = newColumnName;
    const databox = result;
    let array5 = databox.map((obj) => Object.values(obj));
    result = array5;

    console.log("renamed", result);
    setUpdatedData(result);
    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    saveWidgetOperation();
    let def = [],
      ghm = [];
    result.map((e) => {
      def = [];
      e.map((f) => {
        if (f != null && typeof f != "object") def.push({ value: f });
        else if (f != null && typeof f == "object")
          def.push({ value: Object.values(f) });
      });
      ghm.push(def);
    });
    // console.log('ghm>', ghm)
    setPerformed(!performed);

    setUpDataForGrid(result);
    setFinalData(ghm);
    // setUpdatedData(result)
  };

  const checkInComplete = () => {
    handleCheckIncomplete();
    setCheckedColumn(true);
    // setInComplete(false)
    saveWidgetOperation();
    opPerformed = 1;
    setFlag(1);
  };
  const handleSlice = (event) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;
    result = result.slice(s, l);
    //console.log('Sliced', result.slice(s, l))

    convert(result, 3);
    setUpDataForGrid(result);
    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    saveWidgetOperation();
    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    setPerformed(!performed);
  };

  const handleDuplicate = (event) => {
    if (updatedData) result = updatedData;
    else result = props.data2;

    var duplicateIndex = _.findIndex(
      result,
      function (value, index, collection) {
        var equal = _.isEqual.bind(undefined, value);
        return _.findIndex(collection.slice(0, index), equal) !== -1;
      }
    );
    console.log("dupIndex", duplicateIndex);
    if (duplicateIndex != -1) result.splice(duplicateIndex, 1);

    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    convert(result, 2);
    setUpDataForGrid(result);
    saveWidgetOperation();
    setPerformed(!performed);
    setDupCheck(duplicateIndex);
  };

  const handleReplaceNull = (event) => {
    (x = []),
      (y = []),
      (z = []),
      (c1 = []),
      (c2 = []),
      (c3 = []),
      (c4 = []),
      (c5 = []),
      (c6 = []),
      (c7 = []),
      (c8 = []),
      (c9 = []),
      (c10 = []),
      (c11 = []),
      (c12 = []),
      (c13 = []),
      (c14 = []),
      (c15 = []),
      (c16 = []),
      (c17 = []),
      (c18 = []),
      (c19 = []),
      (c20 = []);
    if (updatedData) result = updatedData;
    else result = props.data2;
    console.log(result);
    var p = result;
    var t2 = [];
    p.map((e) => {
      var t3 = [];
      e.map((e2) => {
        if (
          e2 === "NULL" ||
          e2 === "" ||
          e2 === null ||
          e2 === "Null" ||
          e2 === "null" ||
          e2 === undefined
        ) {
          t3.push(replaceInput);
        } else {
          t3.push(e2);
        }
        return e2;
      });
      t2.push(t3);
      return e;
    });
    console.log(t2);
    console.log("....................");
    // setDt(t2)
    result = t2;
    // setUpdatedData(result)
    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
      return e;
    });

    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);
    setOperationPerformedIndex(hoverIndex);
    convert(result, 3);
    setUpDataForGrid(result);
    saveWidgetOperation();
    setPerformed(!performed);
    // setUpdatedData(result)
  };

  const handleGroup = () => {
    if (updatedData) result = updatedData;
    else result = props.data2;

    var newArr = result.reduce((acc, cur) => {
      const idx = acc.findIndex((arr) => arr[0] === cur[0]);
      if (idx != -1) acc[idx][1] += cur[column1];
      else acc.push([cur[0], cur[column1]]);

      return acc;
    }, []);

    let t9 = newArr[0];
    // console.log('t9', t9)
    setColumns(t9);

    newArr.unshift(t9);
    newArr = newArr.slice(1);
    //console.log('merged???', newArr)

    result = newArr;
    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);

      return e;
    });

    setColumnX(0);
    setColumnY(column1);
    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    convert(result);
    setUpDataForGrid(result);
    setUpdatedData(result);
    saveWidgetOperation();
    setPerformed(!performed);
    console.log("updated data after grouo-", result);
  };

  const handleMerge = () => {
    // var d1 = [
    //   ["id", "name"],
    //   ["1", "abc"],
    // ];

    // var d2 = [
    //   ["id", "name"],
    //   ["2", "pqr"],
    // ];

    // const mergedData = [...dataset1, ...dataset2];
    // var tdataset2 = dataset2;

    // for (var p = 0; p < dataset1.length; p++) {
    //   dataset1[p].map((e) => {
    //     tdataset2[p].push(e);
    //   });
    // }

    // Extract unique column names from merged data
    const columnNames = Array.from(
      new Set([...dataset1, ...dataset2].flatMap((item) => Object.keys(item)))
    ).map(String);

    // Create mergedArray with the first inner array containing column names
    const mergedData = [
      columnNames,
      ...[...dataset1, ...dataset2].map((item) =>
        columnNames.map((column) =>
          item[column] !== undefined ? String(item[column]) : "null"
        )
      ),
    ];

    // result = mergedData;
    // console.log("check>>>>", mergedData);
    const dataWithoutColumnNames = mergedData.slice(1);

    // Remove the last inner array
    const lastInnerArray = dataWithoutColumnNames.pop();

    // Move the last inner array to the first position
    dataWithoutColumnNames.unshift(lastInnerArray);

    console.log("check>>>>", dataWithoutColumnNames);
    let columns3 = dataWithoutColumnNames[0];
    setColumns(columns3);

    result = dataWithoutColumnNames;
    result.map((e) => {
      if (e[0]) x.push(e[0]);
      if (e[1]) y.push(e[1]);
      if (e[2]) z.push(e[2]);

      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);

      return e;
    });

    opPerformed = 1;
    setOperationPerformed(true);
    setIsWidgetSaved(false);

    setOperationPerformedIndex(hoverIndex);
    convert(result, 3);
    setUpDataForGrid(result);
    setUpdatedData(result);

    saveWidgetOperation();
    setPerformed(!performed);
    setColumnX(0);
    setColumnY(1);
    setColumnZ(2);
    setXName(columns3[0]);
    setYName(columns3[1]);
    setZName(columns3[2]);

    console.log("updated data after merge-", result);
  };

  const handleDataset1 = (event, mode) => {
    axios
      .post(
        configData.API_URL +
          "personalAccount/database/viewall_datasource_result",
        mode === 2
          ? {
              datasource_id: databases[0].id.toString(),
            }
          : {
              datasource_id: event.target.value.toString(),
            },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        const tempArray2 = JSON.parse(response.data.data[0].result);
        columns3 = tempArray2;
        setColumns(Object.keys(columns3[0]));
        columns4 = Object.keys(columns3[0]);
        const array6 = Object.keys(tempArray2);
        const array7 = tempArray2.map((obj) => Object.values(obj));
        //console.log('what data', array6, array7)
        //  console.log('DATA1...$',columns4, array7)
        array7.push(columns4);
        console.log("DSET1", array7);
        setDataset1(array7);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 401) {
            if (window.confirm("Session expired. Please log in again.")) {
              // Optional: handle the OK button click, e.g., redirect to login page
              window.location.href = "/login";
            }
          }
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };

  const handleDataset2 = (event, mode) => {
    axios
      .post(
        configData.API_URL +
          "personalAccount/database/viewall_datasource_result",
        mode === 2
          ? {
              datasource_id: databases[0].id.toString(),
            }
          : {
              datasource_id: event.target.value.toString(),
            },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        const tempArray2 = JSON.parse(response.data.data[0].result);
        columns3 = tempArray2;
        setColumns(Object.keys(columns3[0]));
        columns4 = Object.keys(columns3[0]);
        const array6 = Object.keys(tempArray2);
        const array7 = tempArray2.map((obj) => Object.values(obj));
        //console.log('what data', array6, array7)
        array7.push(columns4);
        //  console.log('DATA2...', array7)
        setDataset2(array7);
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if (error.response.status === 401) {
            if (window.confirm("Session expired. Please log in again.")) {
              // Optional: handle the OK button click, e.g., redirect to login page
              window.location.href = "/login";
            }
          }
          console.log(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error.message);
        }
      });
  };
  useEffect(() => {
    //  console.log('columnx>', c1)
    console.log("ZP1-");
    if (columnX == 0) setColumnXData(c1);
    if (columnX == 1) setColumnXData(c2);
    if (columnX == 2) setColumnXData(c3);
    if (columnX == 3) setColumnXData(c4);
    if (columnX == 4) setColumnXData(c5);
    if (columnX == 5) setColumnXData(c6);
    if (columnX == 6) setColumnXData(c7);
    if (columnX == 7) setColumnXData(c8);
    if (columnX == 8) setColumnXData(c9);
    if (columnX == 9) setColumnXData(c10);
    if (columnX == 10) setColumnXData(c11);
    if (columnX == 11) setColumnXData(c12);
    if (columnX == 12) setColumnXData(c13);
    if (columnX == 13) setColumnXData(c14);
    if (columnX == 14) setColumnXData(c15);
    if (columnX == 15) setColumnXData(c16);
    if (columnX == 16) setColumnXData(c17);
    if (columnX == 17) setColumnXData(c18);
    if (columnX == 18) setColumnXData(c19);
    if (columnX == 19) setColumnXData(c20);
  }, [opPerformed == 1 && operationPerformed == true && columnX]);

  useEffect(() => {
    console.log("OP1-");
    if (columnX == 0) setColumnXData(props.c1);
    if (columnX == 1) setColumnXData(props.c2);
    if (columnX == 2) setColumnXData(props.c3);
    if (columnX == 3) setColumnXData(props.c4);
    if (columnX == 4) setColumnXData(props.c5);
    if (columnX == 5) setColumnXData(props.c6);
    if (columnX == 6) setColumnXData(props.c7);
    if (columnX == 7) setColumnXData(props.c8);
    if (columnX == 8) setColumnXData(props.c9);
    if (columnX == 9) setColumnXData(props.c10);
    if (columnX == 10) setColumnXData(props.c11);
    if (columnX == 11) setColumnXData(props.c12);
    if (columnX == 12) setColumnXData(props.c13);
    if (columnX == 13) setColumnXData(props.c14);
    if (columnX == 14) setColumnXData(props.c15);
    if (columnX == 15) setColumnXData(props.c16);
    if (columnX == 16) setColumnXData(props.c17);
    if (columnX == 17) setColumnXData(props.c18);
    if (columnX == 18) setColumnXData(props.c19);
    if (columnX == 19) setColumnXData(props.c20);
  }, [opPerformed == 0 && operationPerformed == false && columnX]);

  useEffect(() => {
    console.log("ZP2-");
    if (columnY == 0) setColumnYData(c1);
    if (columnY == 1) setColumnYData(c2);
    if (columnY == 2) setColumnYData(c3);
    if (columnY == 3) setColumnYData(c4);
    if (columnY == 4) setColumnYData(c5);
    if (columnY == 5) setColumnYData(c6);
    if (columnY == 6) setColumnYData(c7);
    if (columnY == 7) setColumnYData(c8);
    if (columnY == 8) setColumnYData(c9);
    if (columnY == 9) setColumnYData(c10);
    if (columnY == 10) setColumnYData(c11);
    if (columnY == 11) setColumnYData(c12);
    if (columnY == 12) setColumnYData(c13);
    if (columnY == 13) setColumnYData(c14);
    if (columnY == 14) setColumnYData(c15);
    if (columnY == 15) setColumnYData(c16);
    if (columnY == 16) setColumnYData(c17);
    if (columnY == 17) setColumnYData(c18);
    if (columnY == 18) setColumnYData(c19);
    if (columnY == 19) setColumnYData(c20);
  }, [opPerformed == 1 && operationPerformed == true && columnY]);

  useEffect(() => {
    // console.log('OP2-', props.c1)
    if (columnY == 0) setColumnYData(props.c1);
    if (columnY == 1) setColumnYData(props.c2);
    if (columnY == 2) setColumnYData(props.c3);
    if (columnY == 3) setColumnYData(props.c4);
    if (columnY == 4) setColumnYData(props.c5);
    if (columnY == 5) setColumnYData(props.c6);
    if (columnY == 6) setColumnYData(props.c7);
    if (columnY == 7) setColumnYData(props.c8);
    if (columnY == 8) setColumnYData(props.c9);
    if (columnY == 9) setColumnYData(props.c10);
    if (columnY == 10) setColumnYData(props.c11);
    if (columnY == 11) setColumnYData(props.c12);
    if (columnY == 12) setColumnYData(props.c13);
    if (columnY == 13) setColumnYData(props.c14);
    if (columnY == 14) setColumnYData(props.c15);
    if (columnY == 15) setColumnYData(props.c16);
    if (columnY == 16) setColumnYData(props.c17);
    if (columnY == 17) setColumnYData(props.c18);
    if (columnY == 18) setColumnYData(props.c19);
    if (columnY == 19) setColumnYData(props.c20);
  }, [opPerformed == 0 && operationPerformed == false && columnY]);

  useEffect(() => {
    console.log("ZP3-");
    if (columnZ == 0) setColumnZData(c1);
    if (columnZ == 1) setColumnZData(c2);
    if (columnZ == 2) setColumnZData(c3);
    if (columnZ == 3) setColumnZData(c4);
    if (columnZ == 4) setColumnZData(c5);
    if (columnZ == 5) setColumnZData(c6);
    if (columnZ == 6) setColumnZData(c7);
    if (columnZ == 7) setColumnZData(c8);
    if (columnZ == 8) setColumnZData(c9);
    if (columnZ == 9) setColumnZData(c10);
    if (columnZ == 10) setColumnZData(c11);
    if (columnZ == 11) setColumnZData(c12);
    if (columnZ == 12) setColumnZData(c13);
    if (columnZ == 13) setColumnZData(c14);
    if (columnZ == 14) setColumnZData(c15);
    if (columnZ == 15) setColumnZData(c16);
    if (columnZ == 16) setColumnZData(c17);
    if (columnZ == 17) setColumnZData(c18);
    if (columnZ == 18) setColumnZData(c19);
    if (columnZ == 19) setColumnZData(c20);
  }, [opPerformed == 1 && operationPerformed == true && columnZ]);

  useEffect(() => {
    // console.log('OP3-', props.c1)
    if (columnZ == 0) setColumnZData(props.c1);
    if (columnZ == 1) setColumnZData(props.c2);
    if (columnZ == 2) setColumnZData(props.c3);
    if (columnZ == 3) setColumnZData(props.c4);
    if (columnZ == 4) setColumnZData(props.c5);
    if (columnZ == 5) setColumnZData(props.c6);
    if (columnZ == 6) setColumnZData(props.c7);
    if (columnZ == 7) setColumnZData(props.c8);
    if (columnZ == 8) setColumnZData(props.c9);
    if (columnZ == 9) setColumnZData(props.c10);
    if (columnZ == 10) setColumnZData(props.c11);
    if (columnZ == 11) setColumnZData(props.c12);
    if (columnZ == 12) setColumnZData(props.c13);
    if (columnZ == 13) setColumnZData(props.c14);
    if (columnZ == 14) setColumnZData(props.c15);
    if (columnZ == 15) setColumnZData(props.c16);
    if (columnZ == 16) setColumnZData(props.c17);
    if (columnZ == 17) setColumnZData(props.c18);
    if (columnZ == 18) setColumnZData(props.c19);
    if (columnZ == 19) setColumnZData(props.c20);
  }, [opPerformed == 0 && operationPerformed == false && columnZ]);

  const changeColumnXData = () => {
    if (columnX == 0) setColumnXData(c1);
    if (columnX == 1) setColumnXData(c2);
    if (columnX == 2) setColumnXData(c3);
    if (columnX == 3) setColumnXData(c4);
    if (columnX == 4) setColumnXData(c5);
    if (columnX == 5) setColumnXData(c6);
    if (columnX == 6) setColumnXData(c7);
    if (columnX == 7) setColumnXData(c8);
    if (columnX == 8) setColumnXData(c9);
    if (columnX == 9) setColumnXData(c10);
    if (columnX == 10) setColumnXData(c11);
    if (columnX == 11) setColumnXData(c12);
    if (columnX == 12) setColumnXData(c13);
    if (columnX == 13) setColumnXData(c14);
    if (columnX == 14) setColumnXData(c15);
    if (columnX == 15) setColumnXData(c16);
    if (columnX == 16) setColumnXData(c17);
    if (columnX == 17) setColumnXData(c18);
    if (columnX == 18) setColumnXData(c19);
    if (columnX == 19) setColumnXData(c20);
  };

  const changeColumnYData = () => {
    if (columnY == 0) setColumnYData(c1);
    if (columnY == 1) setColumnYData(c2);
    if (columnY == 2) setColumnYData(c3);
    if (columnY == 3) setColumnYData(c4);
    if (columnY == 4) setColumnYData(c5);
    if (columnY == 5) setColumnYData(c6);
    if (columnY == 6) setColumnYData(c7);
    if (columnY == 7) setColumnYData(c8);
    if (columnY == 8) setColumnYData(c9);
    if (columnY == 9) setColumnYData(c10);
    if (columnY == 10) setColumnYData(c11);
    if (columnY == 11) setColumnYData(c12);
    if (columnY == 12) setColumnYData(c13);
    if (columnY == 13) setColumnYData(c14);
    if (columnY == 14) setColumnYData(c15);
    if (columnY == 15) setColumnYData(c16);
    if (columnY == 16) setColumnYData(c17);
    if (columnY == 17) setColumnYData(c18);
    if (columnY == 18) setColumnYData(c19);
    if (columnY == 19) setColumnYData(c20);
  };

  const changeColumnZData = () => {
    if (columnZ == 0) setColumnZData(c1);
    if (columnZ == 1) setColumnZData(c2);
    if (columnZ == 2) setColumnZData(c3);
    if (columnZ == 3) setColumnZData(c4);
    if (columnZ == 4) setColumnZData(c5);
    if (columnZ == 5) setColumnZData(c6);
    if (columnZ == 6) setColumnZData(c7);
    if (columnZ == 7) setColumnZData(c8);
    if (columnZ == 8) setColumnZData(c9);
    if (columnZ == 9) setColumnZData(c10);
    if (columnZ == 10) setColumnZData(c11);
    if (columnZ == 11) setColumnZData(c12);
    if (columnZ == 12) setColumnZData(c13);
    if (columnZ == 13) setColumnZData(c14);
    if (columnZ == 14) setColumnZData(c15);
    if (columnZ == 15) setColumnZData(c16);
    if (columnZ == 16) setColumnZData(c17);
    if (columnZ == 17) setColumnZData(c18);
    if (columnZ == 18) setColumnZData(c19);
    if (columnZ == 19) setColumnZData(c20);
  };
  useEffect(() => {}, [graph]);

  useEffect(() => {}, [chartSubType]);

  useEffect(() => {
    setXNumeric(!columnXData.some(isNaN));
  }, [columnXData]);
  useEffect(() => {
    setYNumeric(!columnYData.some(isNaN));
  }, [columnYData]);
  useEffect(() => {
    setZNumeric(!columnZData.some(isNaN));
  }, [columnZData]);

  useEffect(() => {
    if (updatedData) result = updatedData;
    else result = props.data2;
    result.shift();
    result.map((e) => {
      if (e[0]) c1.push(e[0]);
      if (e[1]) c2.push(e[1]);
      if (e[2]) c3.push(e[2]);
      if (e[3]) c4.push(e[3]);
      if (e[4]) c5.push(e[4]);
      if (e[5]) c6.push(e[5]);
      if (e[6]) c7.push(e[6]);
      if (e[7]) c8.push(e[7]);
      if (e[8]) c9.push(e[8]);
      if (e[9]) c10.push(e[9]);
      if (e[10]) c11.push(e[10]);
      if (e[11]) c12.push(e[11]);
      if (e[12]) c13.push(e[12]);
      if (e[13]) c14.push(e[13]);
      if (e[14]) c15.push(e[14]);
      if (e[15]) c16.push(e[15]);
      if (e[16]) c17.push(e[16]);
      if (e[17]) c18.push(e[17]);
      if (e[18]) c19.push(e[18]);
      if (e[19]) c20.push(e[19]);
    });
  }, []);

  useEffect(() => {
    if (props.existingWidget === false) {
      console.log("In New Widget?", props.data2, props.data);
      setdatabeforeupdate(props.data2);
      if (props.data2?.length > 0) setUpDataForGrid(props.data2);
      if (props.fromImport) handleDuplicate();
    }
  }, [props.data2]);
  return (
    <>
      <div style={{ position: "absolute", top: -35, left: -90, right: 0 }}>
        <ToggleButtonGroup
          // color='primary'
          size="small"
          value={alignment}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="data">DATA</ToggleButton>
          <ToggleButton value="visualization">ANALYTICS</ToggleButton>
          {/* {global.subscription_id == 'price_1LfOpESBwqDDsny7sB1s8fra' && (
            <ToggleButton value='auto-ML'>AUTO-ML ENGINE</ToggleButton>
          )}

          {global.subscription_id === 'price_1LfOrRSBwqDDsny7TiYnfuXA' && (
            <ToggleButton value='auto-ML'>AUTO-ML ENGINE</ToggleButton>
          )} */}
        </ToggleButtonGroup>
      </div>
      {alignment === "data" && props.existingWidget && (
        <div style={{ position: "absolute", top: -1, left: 0, right: 20 }}>
          <HtmlTooltip title={props.description} arrow>
            <IconButton
              color="primary"
              aria-label="info"
              // onMouseEnter={() => {
              //   setShowDescription(true)
              // }}
              // onMouseLeave={() => {
              //   setShowDescription(false)
              // }}
              // onClick={() => {
              //   setisExport(true)
              //   setisExportHTML(true)
              // }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <InfoIcon />
                <label style={{ fontSize: 12, fontFamily: "Trebuchet MS" }}>
                  INFO
                </label>
              </div>
            </IconButton>
          </HtmlTooltip>
        </div>
      )}
      {alignment === "data" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center items horizontally
            marginTop: -40,
            // width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "350px",
              marginTop: "30px",
              marginRight: "20px",
              marginBottom: "25px",
              alignSelf: "flex-end",
            }}
          >
            <Tooltip title="Edit Mode">
              <div
                style={{
                  backgroundColor: editMode ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setEditMode(!editMode)}
              >
                <IconButton>
                  <EditIcon sx={{ color: editMode && "white" }} />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Column Resizing">
              <div
                style={{
                  backgroundColor: columnResize ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setColumnResize(!columnResize)}
              >
                <IconButton>
                  <SwapHorizIcon sx={{ color: columnResize && "white" }} />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Sticky Header">
              <div
                style={{
                  backgroundColor: stickyHeader ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setStickyHeader(!stickyHeader)}
              >
                <IconButton>
                  <DragHandleIcon sx={{ color: stickyHeader && "white" }} />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Column Selection">
              <div
                style={{
                  backgroundColor: columnSelection ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setColumnSelection(!columnSelection)}
              >
                <IconButton>
                  <PhotoSizeSelectSmallIcon
                    sx={{ color: columnSelection && "white" }}
                  />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Row Selection">
              <div
                style={{
                  backgroundColor: rowSelection ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setRowSelection(!rowSelection)}
              >
                <IconButton>
                  <HighlightAltIcon sx={{ color: rowSelection && "white" }} />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Fill Handle">
              <div
                style={{
                  backgroundColor: fillHandle ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setFillHandle(!fillHandle)}
              >
                <IconButton>
                  <GradingIcon sx={{ color: fillHandle && "white" }} />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Context Menu">
              <div
                style={{
                  backgroundColor: contextMenu ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setContextMenu(!contextMenu)}
              >
                <IconButton>
                  <ContentCopyIcon sx={{ color: contextMenu && "white" }} />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="Highlight">
              <div
                style={{
                  backgroundColor: highlightOn ? "#067AB4" : "#C1D9EC",
                  borderRadius: "8px",
                }}
                onClick={() => setHighlightOn(!highlightOn)}
              >
                <IconButton>
                  <HighlightIcon sx={{ color: highlightOn && "white" }} />
                </IconButton>
              </div>
            </Tooltip>
          </div>

          <MyComponent data={rowsData} />
        </div>
      ) : alignment === "visualization" ? (
        <div>
          <div style={{ marginBottom: 40 }}>
            <div style={{ position: "absolute", top: -35 }}>
              <Button
                sx={{
                  bgcolor: "#0aafff",
                  color: "white",
                  "&:hover, &:focus": {
                    bgcolor: "#067ab4",
                    color: "white",
                  },
                }}
                onClick={() => {
                  setIsSaveWidget(true);
                }}
                variant="outlined"
              >
                Save as a Widget
              </Button>
            </div>
            {props.existingWidget && (
              <div style={{ position: "absolute", top: -35, left: 220 }}>
                <Button
                  sx={{
                    bgcolor: "#0aafff",
                    color: "white",
                    "&:hover, &:focus": {
                      bgcolor: "#067ab4",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    timesSave++;
                    //saveWidgetOperation(2)
                    console.log("props.drill", props.drill);
                    if (props.drill == 1) {
                      timesDrill++;
                      setIsSaveWidget(true);
                    } else {
                      widgetOperationsAdd(2);
                      widgetDataSave(0, 2);
                    }

                    // console.log(
                    //   'Chtml',
                    //   ReactDOMServer.renderToStaticMarkup(render())
                    // )
                  }}
                  variant="outlined"
                >
                  Save Widget
                </Button>
              </div>
            )}
            {widgetSaved === false && usedSaveAs === 1 && (
              <div style={{ position: "absolute", top: -48, left: 400 }}>
                <Box
                  sx={{
                    marginTop: "15%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#0BAFFF" }} />
                </Box>
              </div>
            )}
            <div style={{ position: "absolute", top: 5, left: 15 }}>
              {widgetSaved ? (
                <Alert
                  style={{ height: 32, alignItems: "center" }}
                  severity={"success"}
                >
                  New widget saved successfully!
                </Alert>
              ) : widgetUpdated ? (
                <Alert
                  style={{ height: 32, alignItems: "center" }}
                  severity={"success"}
                >
                  Widget updated successfully!
                </Alert>
              ) : null}
            </div>

            <div style={{ position: "absolute", top: -40, left: "75%" }}>
              <IconButton
                aria-label="add an alarm"
                onClick={() => {
                  hideEditchart = 1;
                  setisExport(true);
                  setIsPrint(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PrintIcon sx={{ color: "#0D4669" }} />
                  <label
                    style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}
                  >
                    Print
                  </label>
                </div>
              </IconButton>
            </div>

            <div style={{ position: "absolute", top: -40, left: "78%" }}>
              <IconButton
                aria-label="add an alarm"
                onClick={() => {
                  setisExport(true);
                  setisExportPDF(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PictureAsPdf sx={{ color: "#0D4669" }} />
                  <label
                    style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}
                  >
                    Export to PDF
                  </label>
                </div>
              </IconButton>
            </div>
            <div style={{ position: "absolute", top: -40, left: "84%" }}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  hideEditchart = 1;
                  setisExport(true);
                  setisExportCSV(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TableRows sx={{ color: "#0D4669" }} />
                  <label
                    style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}
                  >
                    Export to CSV
                  </label>
                </div>
              </IconButton>
            </div>
            <div>
              <div style={{ position: "absolute", top: -40, left: "90%" }}>
                <IconButton
                  aria-label="add to shopping cart"
                  onClick={() => {
                    setisExport(true);
                    setisExportHTML(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <IntegrationInstructions sx={{ color: "#0D4669" }} />
                    <label
                      style={{ fontSize: 10, color: "#0D4669", marginTop: 2 }}
                    >
                      Export to HTML
                    </label>
                  </div>
                </IconButton>
              </div>

              <CSVLink
                data={updatedData ? updatedData : props.data2}
                filename={fileName}
                ref={csvLink}
                target="_blank"
              ></CSVLink>
              <Modal
                open={isSaveWidget}
                onClose={() => {
                  setIsSaveWidget(false);
                  setNoWidgetTitle(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={widgetmodalstyle}>
                  <>
                    <CloseIcon
                      onClick={() => {
                        setIsSaveWidget(false);
                        setNoWidgetTitle(false);
                      }}
                      style={{
                        position: "absolute",
                        left: "95%",
                        top: "1%",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 20,
                          marginTop: 5,
                          fontSize: 16,
                          fontFamily: "Trebuchet MS",
                        }}
                      >
                        Widget Title
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <input
                            style={{
                              marginLeft: 20,
                              alignSelf: "center",
                              width: 200,
                              height: 40,
                              border: "1px solid #CCC",
                            }}
                            type={"text"}
                            placeholder={"Add Widget Title.."}
                            value={widgetTitle}
                            onChange={(e) => {
                              setWidgetTitle(e.target.value);
                              setNoWidgetTitle(false);
                            }}
                          />
                        </div>

                        {noWidgetTitle && (
                          <Alert
                            style={{
                              height: 40,
                              marginLeft: 16,
                              alignItems: "center",
                            }}
                            severity={"error"}
                          >
                            Please enter widget title
                          </Alert>
                        )}
                      </div>
                      <div
                        style={{
                          marginLeft: 20,
                          marginTop: 5,
                          fontSize: 16,
                          fontFamily: "Trebuchet MS",
                        }}
                      >
                        Widget Description
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <textarea
                          style={{
                            marginLeft: 20,
                            width: "90%",
                            height: 120,
                            border: "1px solid #CCC",
                            overflow: "auto",
                          }}
                          placeholder={"Add Widget Description.."}
                          value={widgetDesc}
                          onChange={(e) => setWidgetDesc(e.target.value)}
                          cols={40}
                          rows={10}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        alignSelf: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ marginRight: 10, marginBottom: 20 }}>
                        <Button
                          sx={{
                            bgcolor: "#067AB4",
                            color: "white",
                            "&:hover, &:focus": {
                              bgcolor: "#0BAFFF",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            setIsSaveWidget(false);
                            setNoWidgetTitle(false);
                          }}
                          variant="outlined"
                        >
                          CANCEL
                        </Button>
                      </div>
                      <div style={{ marginRight: 20, marginBottom: 20 }}>
                        <Button
                          sx={{
                            bgcolor: "#067AB4",
                            color: "white",
                            "&:hover, &:focus": {
                              bgcolor: "#0BAFFF",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            //saveWidgetOperation(1)
                            if (widgetTitle === "") {
                              setNoWidgetTitle(true);
                              return;
                            } else {
                              if (props.drill == 1) {
                                widgetOperationsAdd(2);
                              } else {
                                usedSaveAs = 1;
                                widgetOperationsAdd(1);
                              }
                              setIsWidgetSaved(true);
                              setIsSaveWidget(false);
                              setTimeout(() => {
                                setWidgetSaved(true);
                              }, 500);
                              setTimeout(() => {
                                if (props.existingWidget === false)
                                  history.push("/Widgets");
                              }, 2500);
                            }
                          }}
                          variant="outlined"
                        >
                          SAVE {"&"} CLOSE
                        </Button>
                      </div>
                    </div>
                  </>
                </Box>
              </Modal>

              {isExport && (
                <Modal
                  open={isExport}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={isPrint ? exportModalstyle2 : exportModalstyle}>
                    <>
                      <CloseIcon
                        onClick={() => {
                          if (isExportCSV) {
                            setisExportCSV(false);
                          }
                          if (isExportPDF) {
                            setisExportPDF(false);
                          }
                          if (isExportHTML) {
                            setisExportHTML(false);
                          }
                          if (isPrint) {
                            setIsPrint(false);
                          }
                          setisExport(false);
                        }}
                        style={{
                          position: "absolute",
                          left: "95%",
                          top: "1%",
                          cursor: "pointer",
                        }}
                      />
                      {isPrint === false && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 20,
                              marginTop: 5,
                              fontSize: 16,
                              fontFamily: "Trebuchet MS",
                            }}
                          >
                            File Name
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <input
                              style={{
                                marginLeft: 20,
                                alignSelf: "center",
                                width: 350,
                                height: 40,
                                border: "1px solid #CCC",
                              }}
                              type={"text"}
                              placeholder={"Add File Name.."}
                              value={fileName}
                              onChange={(e) => setFileName(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      {isPrint === false && isExportCSV === false && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 20,
                              marginTop: 5,
                              fontSize: 16,
                              fontFamily: "Trebuchet MS",
                            }}
                          >
                            Edit Chart Title
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <input
                              style={{
                                marginLeft: 20,
                                alignSelf: "center",
                                width: 350,
                                height: 40,
                                border: "1px solid #CCC",
                              }}
                              type={"text"}
                              placeholder={"Edit Chart Title.."}
                              value={newChartTitle}
                              onChange={(e) => setNewChartTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          marginTop: 25,
                          display: "flex",
                          alignSelf: isPrint ? "center" : "flex-end",
                          justifyContent: isPrint ? "center" : "flex-end",
                        }}
                      >
                        <div style={{ marginRight: 10, marginBottom: 20 }}>
                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "white",
                              },
                            }}
                            onClick={() => {
                              if (isExportCSV) {
                                setisExportCSV(false);
                              }
                              if (isExportPDF) {
                                setisExportPDF(false);
                              }
                              if (isExportHTML) {
                                setisExportHTML(false);
                              }
                              if (isPrint) setIsPrint(false);

                              setisExport(false);
                            }}
                            variant="outlined"
                          >
                            CANCEL
                          </Button>
                        </div>
                        <div style={{ marginRight: 20, marginBottom: 20 }}>
                          <Pdf targetRef={ref2} filename={fileName}>
                            {({ toPdf }) => (
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  if (isExportCSV) {
                                    setisExportCSV(false);
                                    exportCSV();
                                  }
                                  if (isExportPDF) {
                                    toPdf();
                                    setisExportPDF(false);
                                  }
                                  if (isExportHTML) {
                                    setisExportHTML(false);
                                    exportToHtml();
                                    setisExportPDF(false);
                                  }
                                  if (isPrint) {
                                    printDiv();
                                    setIsPrint(false);
                                  }

                                  setisExport(false);
                                }}
                                variant="outlined"
                              >
                                {isExportCSV
                                  ? "Export To CSV"
                                  : isExportHTML
                                  ? "Export To HTML"
                                  : isExportPDF
                                  ? "Export To PDF"
                                  : isPrint
                                  ? "Print"
                                  : ""}
                              </Button>
                            )}
                          </Pdf>
                        </div>
                      </div>
                    </>
                  </Box>
                </Modal>
              )}

              <Modal
                open={editChartTitleModal}
                onClose={() => {
                  // if (chartTitle === '') {
                  //   setNoChartTitle(true)
                  //   setTimeout( setNoChartTitle(false), 3000)
                  //   return
                  // }
                  setEditChartTitleModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={editChartTitleModalStyle}>
                  <>
                    <CloseIcon
                      onClick={() => {
                        // if (chartTitle === '') {
                        //   setNoChartTitle(true)
                        //   setTimeout( setNoChartTitle(false), 3000)
                        //   return
                        // }
                        setEditChartTitleModal(false);
                      }}
                      style={{
                        position: "absolute",
                        left: "95%",
                        top: "1%",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 20,
                          marginTop: 5,
                          fontSize: 16,
                          fontFamily: "Trebuchet MS",
                        }}
                      >
                        Edit Chart Title
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            marginLeft: 20,
                            alignSelf: "flex-start",
                            width: 250,
                            height: 40,
                            border: "1px solid #CCC",
                          }}
                          type={"text"}
                          //placeholder={'Edit Chart Title..'}
                          value={newChartTitle}
                          onChange={(e) => setNewChartTitle(e.target.value)}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        alignSelf: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* <div style={{ marginRight: 10, marginBottom: 20 }}>
                    <Button
                      sx={{
                        bgcolor: '#067AB4',
                        color: 'white',
                        fontFamily: 'Trebuchet MS'
                      }}
                      onClick={() => {
                        setEditChartTitleModal(false)
                      }}
                      variant='outlined'
                    >
                      CANCEL
                    </Button>
                  </div> */}
                      {noChartTitle && (
                        <Stack sx={{ width: "90%", marginRight: 18 }}>
                          <Alert
                            style={{ fontFamily: "Trebuchet MS" }}
                            severity={"error"}
                          >
                            Please enter chart title
                          </Alert>
                        </Stack>
                      )}
                      <div style={{ marginRight: 20, marginBottom: 20 }}>
                        <Button
                          sx={{
                            bgcolor: "#067AB4",
                            color: "white",
                            "&:hover, &:focus": {
                              bgcolor: "#0BAFFF",
                              color: "white",
                            },
                          }}
                          onClick={(e) => {
                            if (newChartTitle === "") {
                              setNoChartTitle(true);
                              return;
                            }
                            setTimeout(setNoChartTitle(false), 3000);

                            setChartTitle(newChartTitle);

                            setEditChartTitleModal(false);
                            clickedEdit = 1;
                            setTimeout(saveWidgetOperation(25), 500);
                          }}
                          variant="outlined"
                        >
                          Edit Title
                        </Button>
                      </div>
                    </div>
                  </>
                </Box>
              </Modal>

              {/* beast mode*/}
              <Modal
                open={isBeastMode}
                onClose={() => setIsBeastMode(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalstyle2}>
                  <CloseIcon
                    onClick={() => setIsBeastMode(false)}
                    style={{
                      position: "absolute",
                      left: "96%",
                      top: "2%",
                      cursor: "pointer",
                    }}
                  />
                  <div
                    style={{
                      marginLeft: 25,
                      marginTop: 40,
                      fontSize: 16,
                    }}
                  >
                    DETAILS
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{
                        marginTop: 10,
                        width: "95%",
                        height: 40,
                        border: "1px solid #CCC",
                      }}
                      type={"text"}
                      placeholder={"Calculated Field Name"}
                      value={newFieldName}
                      onChange={(e) => setNewFieldName(e.target.value)}
                    />

                    <div
                      style={{
                        marginLeft: 15,
                        display: "flex",
                        marginTop: 30,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 10,
                          marginLeft: -520,
                          fontSize: 16,
                        }}
                      >
                        FORMULA
                      </div>
                      <div>
                        {invalidFormula2 == 1 ? (
                          <Stack sx={{ width: "100%", ml: 2 }}>
                            <Alert severity={"error"}>
                              Invalid Formula: A column in this calculation did
                              not exist.
                            </Alert>
                          </Stack>
                        ) : invalidFormula2 == -1 ? (
                          <Stack sx={{ width: "100%", ml: 2 }}>
                            <Alert severity={"error"}>
                              Please enter new column name
                            </Alert>
                          </Stack>
                        ) : invalidFormula2 == -2 ? (
                          <Stack sx={{ width: "100%", ml: 2 }}>
                            <Alert severity={"error"}>
                              Please enter formula
                            </Alert>
                          </Stack>
                        ) : invalidFormula == 0 &&
                          operationPerformed &&
                          usedFormulas ? (
                          <Stack sx={{ width: "100%", ml: 2 }}>
                            <Alert severity={"success"}>
                              Valid Formula: New column created successfully.
                            </Alert>
                          </Stack>
                        ) : null}
                      </div>
                    </div>
                    <input
                      style={{
                        marginLeft: 5,
                        marginTop: 10,
                        width: "96%",
                        height: 40,
                        border: "1px solid #CCC",
                      }}
                      type={"text"}
                      placeholder={"Enter Formula.."}
                      value={formula}
                      onChange={(e) => setFormula(e.target.value)}
                    />

                    <div
                      style={{
                        width: 1040,
                        overflow: "auto",
                        marginTop: 10,
                        display: "flex",
                      }}
                    >
                      <div style={{ margin: 10 }}>
                        <div style={{ fontSize: 16 }}>DATASET FIELDS</div>
                        <div
                          style={{
                            marginTop: 5,
                            height: 300,
                            width: 360,
                            border: "1px solid #CCC",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <input
                            style={{
                              marginTop: 10,
                              width: "96%",
                              height: 30,
                              border: "1px solid #067AB4",
                              borderRadius: "5px",
                            }}
                            type={"text"}
                            placeholder="Search"
                            value={searchedColumn}
                            onChange={(e) => setSearchedColumn(e.target.value)}
                          />
                          <div
                            style={{
                              height: 250,
                              width: 360,
                              flexWrap: "wrap",
                              overflow: "auto",
                              marginTop: 12,
                              fontSize: 14,
                            }}
                          >
                            {columns &&
                              columns.map((value, index) => {
                                return (
                                  <div
                                    style={{
                                      padding: 5,
                                      border: "0.3px solid #CCC",
                                      borderRadius: 5,
                                      margin: 5,
                                      backgroundColor:
                                        columnHover &&
                                        columnHoverIndex === index
                                          ? "#c4c4c4"
                                          : "white",
                                    }}
                                    onMouseEnter={() => {
                                      setColumnHover(true);
                                      setColumnHoverIndex(index);
                                    }}
                                    onMouseLeave={() => {
                                      setColumnHover(false);
                                    }}
                                    onClick={(e) => {
                                      console.log("clicked", index);
                                      if (e.detail === 2)
                                        setFormula(
                                          formula != ""
                                            ? formula.split(")")[0] +
                                                value +
                                                ")"
                                            : value
                                        );
                                      setField(index);
                                      column = index;
                                    }}
                                  >
                                    {value}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div style={{ margin: 10 }}>
                        <div style={{ fontSize: 16 }}>FUNCTIONS</div>
                        <div
                          style={{
                            marginTop: 5,
                            height: 300,
                            width: 360,
                            border: "1px solid #CCC",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <select
                            onChange={(e) => {
                              setFunctionType(e.target.value);
                            }}
                            style={{
                              marginTop: 5,
                              marginLeft: 2,
                              border: "1px solid #CCC",
                              width: 350,
                              height: 28,
                              backgroundColor: "white",
                              border: "0.5px solid",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            {BeastModeFunctionTypes.map((ele, i) => (
                              <option
                                style={{ fontSize: 14, height: 25 }}
                                value={i}
                                key={ele.name}
                              >
                                {ele.name}
                              </option>
                            ))}
                          </select>
                          <input
                            style={{
                              marginTop: 10,
                              width: "96%",
                              height: 32,
                              border: "1px solid #067AB4",
                              borderRadius: "5px",
                            }}
                            type={"text"}
                            placeholder="Search"
                            value={searchedFunction}
                            onChange={(e) => {
                              setSearchedFunction(e.target.value);
                              setSearch2(true);
                            }}
                          />

                          <div
                            style={{
                              height: 250,
                              width: 360,
                              flexWrap: "wrap",
                              overflow: "auto",
                              marginTop: 12,
                            }}
                          >
                            {beastFunctions.map((value, index) => {
                              return (
                                <div
                                  onMouseEnter={() => {
                                    setFunctionHover(true);
                                    setFunctionHoverIndex(index);
                                  }}
                                  onMouseLeave={() => {
                                    setFunctionHover(false);
                                  }}
                                  style={{
                                    padding: 5,
                                    borderRadius: 5,
                                    margin: 5,
                                    fontSize: 14,
                                    backgroundColor:
                                      findex == index ? "#c4c4c4" : "#E9E9E9",

                                    border: functionHover
                                      ? functionHoverIndex == index
                                        ? "0.1px solid gray"
                                        : "0px"
                                      : findex == index
                                      ? "0.1px solid gray"
                                      : "0px",
                                    cursor: functionHover && "pointer",
                                  }}
                                  onClick={(e) => {
                                    console.log("clicked functionId", value.id);
                                    console.log("check dbl", e.detail === 2);
                                    if (e.detail === 2)
                                      setFormula(value.name + "(" + ")");
                                    setBeastModeFunction(value.id);
                                    setFIndex(index);
                                  }}
                                >
                                  {value.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div style={{ margin: 10 }}>
                        <div style={{ fontSize: 16 }}>FUNCTION DESCRIPTION</div>

                        <div
                          style={{
                            marginTop: 5,
                            height: 300,
                            width: 380,
                            border: "1px solid #CCC",
                            flexWrap: "wrap",
                            padding: 4,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              //  fontFamily: 'Trebuchet MS'
                            }}
                          >
                            {beastModeFunctions.map((item) =>
                              item.id == beastModeFunction ? item.name : ""
                            )}
                          </div>

                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "500",
                              //  fontFamily: 'Trebuchet MS'
                            }}
                          >
                            {beastModeFunctions.map((item) =>
                              item.id == beastModeFunction
                                ? item.description
                                : ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignSelf: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ marginRight: 10, marginBottom: 20 }}>
                        <Button
                          sx={{
                            bgcolor: "#067AB4",
                            color: "white",
                            "&:hover, &:focus": {
                              bgcolor: "#0BAFFF",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            setIsBeastMode(false);
                          }}
                          variant="outlined"
                        >
                          CANCEL
                        </Button>
                      </div>
                      <div style={{ marginRight: 20, marginBottom: 20 }}>
                        <Button
                          sx={{
                            bgcolor: "#067AB4",
                            color: "white",
                            "&:hover, &:focus": {
                              bgcolor: "#0BAFFF",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            if (newFieldName === "") {
                              // invalidFormula = -1
                              setInvalidFormula2(-1);
                              setIsBeastMode(true);
                              setTimeout(() => {
                                setInvalidFormula2(null);
                              }, 2000);
                              return;
                            }

                            if (formula === "") {
                              setInvalidFormula2(-2);
                              setIsBeastMode(true);
                              setTimeout(() => {
                                setInvalidFormula2(null);
                              }, 2000);
                              return;
                            }
                            invalidFormula = 0;
                            handleBeastMode(0);
                            if (invalidFormula == 1) {
                              setInvalidFormula2(1);
                              setIsBeastMode(true);
                              setTimeout(() => {
                                setInvalidFormula2(null);
                              }, 2000);
                              return;
                            } else if (invalidFormula == 0) {
                              setTimeout(() => {
                                setIsBeastMode(false);
                              }, 2000);
                              invalidFormula = 0;
                              handleBeastMode(1);
                              setTimeout(() => (invalidFormula = 1), 2000);
                              saveWidgetOperation();
                            }
                          }}
                          variant="outlined"
                        >
                          SAVE {"&"} CLOSE
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* Create Segment Modal*/}
              <Modal
                open={isCreateSegment}
                onClose={() => setIsCreateSegment(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalstyle3}>
                  <CloseIcon
                    onClick={() => setIsCreateSegment(false)}
                    style={{
                      position: "absolute",
                      left: "94%",
                      top: "2%",
                      cursor: "pointer",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: -1050,
                        marginTop: 20,
                        fontSize: 16,
                      }}
                    >
                      Graph Filter
                    </div>
                    <input
                      style={{
                        marginTop: 10,
                        width: "95%",
                        height: 30,
                        border: "1px solid #CCC",
                      }}
                      type={"text"}
                      placeholder={"Filter Name"}
                      value={segmentName}
                      onChange={(e) => setSegmentName(e.target.value)}
                    />

                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-around",
                          padding: 5,
                          marginTop: 10,
                        }}
                      >
                        <select
                          onChange={(e) =>
                            e.target.value == 0
                              ? setIsColumnNumeric(false)
                              : setIsColumnNumeric(true)
                          }
                          value={isColumnNumeric ? 1 : 0}
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            marginTop: 20,
                            marginBottom: 12,
                            width: "90%",
                            height: 25,
                            backgroundColor: "white",
                            border: "0.5px solid",
                            borderRadius: 10,
                            fontSize: 12,
                            color: "#067AB4",
                          }}
                        >
                          <option style={{ fontSize: 14 }} value={0}>
                            {"Selection"}
                          </option>
                          <option style={{ fontSize: 14 }} value={1}>
                            {"Range"}
                          </option>
                        </select>
                        <div
                          style={{
                            fontSize: 14,
                            color: "#067AB4",
                            fontFamily: "Trebuchet MS",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                        >
                          Filter columns to create a custom group of rows of
                          your dataset
                        </div>

                        <p style={{ fontSize: 14, marginBottom: 6 }}>Column</p>
                        <select
                          onChange={
                            isColumnNumeric ? handleFilter2 : handleFilter
                          }
                          style={{
                            marginBottom: 12,
                            width: "90%",
                            height: 25,
                            backgroundColor: "white",
                            border: "0.5px solid",
                            borderRadius: 10,
                            fontSize: 12,
                            color: "#067AB4",
                          }}
                        >
                          <option
                            style={{
                              fontSize: 14,
                              height: 25,
                              marginBottom: 20,
                            }}
                            //value={0}
                          >
                            {"select Column"}
                          </option>
                          {columns.map((value, index) => {
                            return (
                              <option
                                style={{ fontSize: 14 }}
                                key={index}
                                value={index}
                              >
                                {value}
                              </option>
                            );
                          })}
                        </select>
                        {isColumnNumeric === false ? (
                          <div
                            style={{
                              marginTop: -10,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                marginBottom: 4,
                              }}
                            >
                              Column Values
                            </p>

                            <input
                              style={{
                                marginBottom: 10,
                                background: "#F0F0F0",
                                border: "1px solid gray",
                                width: "90%",
                                height: 25,
                              }}
                              placeholder={"Filter by..."}
                              type={"text"}
                              value={searchInput}
                              onChange={(e) => {
                                setSearch(true);
                                setsearchInput(e.target.value);
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                height: 185,
                                width: 170,
                                //   overflow: 'auto',
                                marginTop: -5,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div
                                  style={{
                                    color: "blue",
                                    fontSize: 12,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => selectAll()}
                                >
                                  Select All
                                </div>
                                <div
                                  style={{
                                    color: "blue",
                                    fontSize: 12,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => selectNone()}
                                >
                                  Select None
                                </div>
                              </div>
                              <div style={{ height: 500, overflowY: "auto" }}>
                                {columnValues != undefined &&
                                  columnValues.map((value, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        <input
                                          style={{ marginRight: 10 }}
                                          value={value}
                                          type="checkbox"
                                          checked={checked.includes(value)}
                                          onChange={(e) =>
                                            handleCheck(e, index)
                                          }
                                        />
                                        <span>{value}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>

                            <div
                              style={{
                                height: 70,
                                width: 200,
                                display: "flex",
                                alignSelf: "center",
                                flexWrap: "wrap",
                                overflow: "auto",
                                marginTop: 12,
                                fontSize: 14,
                              }}
                            >{`Selected: ${checkedItems}`}</div>

                            {notEnteredFilterName && (
                              <div style={{ fontSize: 14 }}>
                                Please enter filter name to be saved!
                              </div>
                            )}

                            <div
                              style={{
                                marginTop: 8,
                                marginBottom: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  height: 25,
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  applyFilter(1);
                                  saveWidgetOperation();
                                }}
                                variant="outlined"
                              >
                                Apply
                              </Button>
                            </div>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                height: 25,
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                resetData();
                              }}
                              variant="outlined"
                            >
                              Reset Filter
                            </Button>
                            <div
                              style={{
                                marginTop: 8,
                                marginBottom: 20,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  height: 25,
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  if (segmentName === "") {
                                    setNotEnteredFilterName(true);
                                    return;
                                  }

                                  saveSegment();
                                  viewSegments();
                                  setIsCreateSegment(false);
                                }}
                                variant="outlined"
                              >
                                Save Filter
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 14,
                                marginBottom: 6,
                              }}
                            >
                              Aggregation
                            </div>
                            <select
                              onChange={(e) =>
                                e.target.value == 0
                                  ? null
                                  : e.target.value == 1
                                  ? findSum()
                                  : e.target.value == 2
                                  ? findMin()
                                  : e.target.value == 3
                                  ? findMax()
                                  : e.target.value == 4
                                  ? findAvg()
                                  : e.target.value == 5
                                  ? findCount()
                                  : null
                              }
                              style={{
                                marginBottom: 10,
                                width: "90%",
                                height: 25,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              <option style={{ fontSize: 14 }} value={0}>
                                {"No aggregation"}
                              </option>
                              <option style={{ fontSize: 14 }} value={1}>
                                {"Sum"}
                              </option>
                              <option style={{ fontSize: 14 }} value={2}>
                                {"Minimum"}
                              </option>
                              <option style={{ fontSize: 14 }} value={3}>
                                {"Maximum"}
                              </option>
                              <option style={{ fontSize: 14 }} value={4}>
                                {"Average"}
                              </option>
                              <option style={{ fontSize: 14 }} value={5}>
                                {"Count"}
                              </option>
                            </select>
                            <div style={{ marginBottom: 10, fontSize: 14 }}>
                              Condition
                            </div>
                            <select
                              onChange={handleFilterCondition}
                              style={{
                                width: "90%",
                                height: 25,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              <option style={{ fontSize: 14 }} value={2}>
                                {"number equals"}
                              </option>
                              <option style={{ fontSize: 14 }} value={3}>
                                {"number is greater than"}
                              </option>
                              <option style={{ fontSize: 14 }} value={4}>
                                {"number is greater than or equals"}
                              </option>
                              <option style={{ fontSize: 14 }} value={5}>
                                {"number is lesser than"}
                              </option>
                              <option style={{ fontSize: 14 }} value={6}>
                                {"number is lesser than or equals"}
                              </option>
                            </select>

                            <input
                              style={{
                                marginBottom: 5,
                                marginTop: 15,
                                background: "#F0F0F0",
                                border: "1px solid gray",
                                width: "90%",
                                height: 25,
                              }}
                              type={"text"}
                              value={input1}
                              onChange={(e) => {
                                input = e.target.value;
                                setInput1(e.target.value);
                              }}
                            />
                            <div style={{ marginLeft: 85, marginBottom: 10 }}>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  height: 20,
                                  width: 40,
                                  fontSize: 10,
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "#067AB4",
                                  },
                                }}
                                onClick={() => {
                                  setInput1("");
                                  input = null;
                                }}
                                variant="outlined"
                              >
                                Clear
                              </Button>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    borderRadius: 6,
                                    padding: 4,
                                    marginRight: 2,
                                  }}
                                >
                                  Sum:
                                </label>
                                <label style={{ fontSize: 12, padding: 4 }}>
                                  {sum1}
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    borderRadius: 6,
                                    padding: 4,
                                    marginRight: 2,
                                  }}
                                >
                                  Count:
                                </label>
                                <label style={{ fontSize: 12, padding: 4 }}>
                                  {count1}
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    borderRadius: 6,
                                    padding: 4,
                                    marginRight: 2,
                                  }}
                                >
                                  Min:
                                </label>
                                <label style={{ fontSize: 12, padding: 4 }}>
                                  {min1}
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    borderRadius: 6,
                                    padding: 4,
                                    marginRight: 2,
                                  }}
                                >
                                  Avg:
                                </label>
                                <label style={{ fontSize: 12, padding: 4 }}>
                                  {avg1}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    borderRadius: 6,
                                    padding: 4,
                                    marginRight: 2,
                                  }}
                                >
                                  Max:
                                </label>
                                <label style={{ fontSize: 12, padding: 4 }}>
                                  {max1}
                                </label>
                              </div>
                            </div>

                            <div style={{ marginTop: 6, marginBottom: 20 }}>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  height: 25,
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "#067AB4",
                                  },
                                }}
                                onClick={() => filter(1)}
                                variant="outlined"
                              >
                                Apply
                              </Button>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  height: 25,
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  saveSegment();
                                  viewSegments();
                                  setIsCreateSegment(false);
                                }}
                                variant="outlined"
                              >
                                Save Segment
                              </Button>
                            </div>
                            <div style={{ marginTop: 8 }}>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  height: 25,
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  resetData();
                                }}
                                variant="outlined"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              <div
                style={{
                  marginTop: -15,
                  height: 85,
                  width: "100%",
                  backgroundColor: "#F8F8F8",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                {actions.map((e, i) => (
                  <>
                    <div
                      onMouseEnter={() => {
                        setHover(true);
                        setHoverIndex(i);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}
                      //  onMouseOver={() => console.log('Hover op')}
                      onClick={() => {
                        console.log(
                          "Clicked op-",
                          e.name,
                          operationIndex,
                          e.id
                        );

                        if (operationIndex != e.id) {
                          setOperationIndex2(undefined);
                          setOperationIndex(undefined);
                          setOpenModal(false);
                          setTimeout(() => setOperationIndex(e.id), 500);
                          setOpenModal(true);
                        } else {
                          setOperationIndex(e.id);
                          setOpenModal2(false);
                          setOpenModal(!openModal);
                        }
                      }}
                      style={{
                        margin: 5,
                        display: "inline-block",
                        height: 75,
                        width: 92,
                        backgroundColor: hover
                          ? hoverIndex == i
                            ? "white"
                            : "#C1D9EC"
                          : (openModal && operationIndex == e.id) ||
                            (operationIndexForDisplay &&
                              operationIndexForDisplay.includes(e.id))
                          ? "white"
                          : "#C1D9EC",
                        border: hover
                          ? hoverIndex == i
                            ? "0.1px solid gray"
                            : "0px"
                          : (openModal && operationIndex == e.id) ||
                            (operationIndexForDisplay &&
                              operationIndexForDisplay.includes(e.id))
                          ? "0.1px solid gray"
                          : "0px",
                        borderRadius: 8,
                        boxShadow: 2,
                        flexWrap: "wrap",
                        fontSize: "11px",
                        fontWeight: "500",
                        color: "#067AB4",
                        padding: 4,
                        cursor: hover && "pointer",
                      }}
                    >
                      <div style={{ marginTop: 5, marginBottom: 2 }}>
                        {e.icon}
                      </div>

                      {e.name}
                    </div>
                  </>
                ))}
              </div>

              <div
                style={{
                  marginTop: 10,
                  height: 85,
                  width: "100%",
                  backgroundColor: "#F8F8F8",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                {actions2Box &&
                  actions2Box.map((e, i) => (
                    <>
                      <div
                        onMouseEnter={() => {
                          setHover2(true);
                          setHoverIndex2(e.id);
                        }}
                        onMouseLeave={() => {
                          setHover2(false);
                        }}
                        //  onMouseOver={() => console.log('Hover op')}
                        onClick={() => {
                          console.log("Clicked op2-", e.name, e.id);
                          if (operationIndex2 != e.id) {
                            setOperationIndex(undefined);
                            setOperationIndex2(undefined);
                            setOpenModal(false);
                            setOpenModal2(false);
                            setTimeout(() => setOperationIndex2(e.id), 500);
                            setOpenModal2(true);
                            if (e.id === 15) {
                              setOperationIndex2(e.id);
                              setOpenModal2(false);
                              setDataTable(!dataTable);
                            } else if (e.id === 14) {
                              localStorage.setItem("FromWidget", 1);
                              history.push(
                                "/Import Dataset/" + global.subscription_id
                              );
                            } else if (e.id === 19) setIsBeastMode(true);
                            else if (e.id === 23) setIsCreateSegment(true);
                            else if (e.id === 24) {
                              setEditChartTitleModal(true);
                              // saveWidgetOperation()
                            } else if (e.id === 22) history.push("/Widgets");
                          } else {
                            if (e.id === 15) {
                              setOpenModal2(false);
                              setDataTable(!dataTable);
                            } else if (e.id === 14) {
                              localStorage.setItem("FromWidget", 1);
                              history.push(
                                "/Import Dataset/" + global.subscription_id
                              );
                            } else if (e.id === 19) setIsBeastMode(true);
                            else if (e.id === 23) setIsCreateSegment(true);
                            else if (e.id === 24) {
                              setEditChartTitleModal(true);
                              // saveWidgetOperation()
                            } else if (e.id === 22) history.push("/Widgets");
                            else {
                              setOperationIndex2(e.id);
                              setOpenModal(false);
                              setOpenModal2(!openModal2);
                            }
                          }
                        }}
                        style={{
                          margin: 5,
                          display: "inline-block",
                          height: 75,
                          width: 92,
                          backgroundColor: hover2
                            ? hoverIndex2 == e.id
                              ? "white"
                              : "#C1D9EC"
                            : (openModal2 && operationIndex2 == e.id) ||
                              (dataTable && e.id == 15) ||
                              (operationIndexForDisplay &&
                                operationIndexForDisplay.includes(e.id))
                            ? "white"
                            : "#C1D9EC",
                          border: hover2
                            ? hoverIndex2 == e.id
                              ? "0.1px solid gray"
                              : "0px"
                            : (openModal2 && operationIndex2 == e.id) ||
                              (dataTable && e.id == 15) ||
                              (operationIndexForDisplay &&
                                operationIndexForDisplay.includes(e.id))
                            ? "0.1px solid gray"
                            : "0px",
                          borderRadius: 8,
                          boxShadow: 2,
                          flexWrap: "wrap",
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#067AB4",
                          padding: 4,
                          cursor: hover2 && "pointer",
                        }}
                      >
                        <div style={{ marginTop: 5, marginBottom: 2 }}>
                          {e.icon}
                        </div>

                        {e.name}
                      </div>
                    </>
                  ))}
              </div>
              <div ref={ref}>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {isPrint === false && (
                    <Card
                      sx={{ boxShadow: 3, border: "1px solid #d8d8d8" }}
                      style={{
                        height: 500,
                        width: 330,
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 8,
                        overflowY: "auto",
                      }}
                    >
                      {openModal === false && openModal2 === false ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <div style={{ marginTop: -20 }}>
                            <div style={{ marginTop: 0 }}>
                              <select
                                onChange={e => {
                                  // console.log('graph-', e.target.value)
                                  setGraph(e.target.value)
                                  setSelectAnyChart(false)
                                }}
                                style={{
                                  border: '1px solid #FFF',
                                  width: 150,
                                  height: 25,
                                  marginTop: -10,

                                  backgroundColor: 'white',
                                  border: '0.5px solid',
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: '#067AB4'
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20
                                  }}
                                  value={-1}
                                >
                                  {'Flow Graphs'}
                                </option>
                                {props.selectedCharts &&
                                  props.selectedCharts.map((ele, i) => (
                                    <option
                                      style={{ fontSize: 14, height: 25 }}
                                      value={i}
                                    >
                                      {ele.title}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div> */}
                          <div
                            style={{
                              fontSize: "16px",
                              marginTop: -35,
                              marginBottom: 50,
                              fontFamily: "Trebuchet MS",
                            }}
                          >
                            Select X,Y and Z axis Columns
                          </div>

                          <div style={{ marginBottom: 20 }}>
                            <div>X Axis</div>

                            <div style={{ marginTop: 5 }}>
                              <select
                                onChange={(e) => {
                                  // console.log(
                                  //   'selected column-',
                                  //   e.target.innerHTML
                                  // )
                                  setColumnX(e.target.value);
                                  setXName(columns[e.target.value]);
                                }}
                                value={columnX}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,
                                  marginTop: -40,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  //value={0}
                                >
                                  {"select Column"}
                                </option>
                                {columns &&
                                  columns.map((ele, i) => (
                                    <option
                                      style={{ fontSize: 14, height: 25 }}
                                      value={i}
                                    >
                                      {ele}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div style={{ marginBottom: 20 }}>
                            <div>Y Axis</div>

                            <div style={{ marginTop: 5 }}>
                              <select
                                onChange={(e) => {
                                  //  console.log('selected-', e.target.value)
                                  setColumnY(e.target.value);
                                  setYName(columns[e.target.value]);
                                }}
                                value={columnY}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,
                                  marginTop: -40,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  // value={0}
                                >
                                  {"select Column"}
                                </option>
                                {columns &&
                                  columns.map((ele, i) => (
                                    <option
                                      style={{ fontSize: 14, height: 25 }}
                                      value={i}
                                    >
                                      {ele}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div>
                            {isLegend ? <div>Legend</div> : <div>Z Axis</div>}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: 5,
                              }}
                            >
                              <select
                                onChange={(e) => {
                                  //  console.log('selected-', e.target.value)
                                  setColumnZ(e.target.value);
                                  setZName(columns[e.target.value]);
                                }}
                                value={columnZ}
                                style={{
                                  marginLeft: 20,
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,
                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  //  value={0}
                                >
                                  {"select Column"}
                                </option>
                                {columns &&
                                  columns.map((ele, i) => (
                                    <option
                                      style={{ fontSize: 14, height: 25 }}
                                      value={i}
                                    >
                                      {ele}
                                    </option>
                                  ))}
                              </select>
                              <div style={{ marginLeft: -4 }}>
                                <CloseIcon
                                  onClick={() => {
                                    setUseLegend(false);
                                    saveWidgetOperation(24, false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: 40,
                            }}
                          >
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                setUseLegend(true);
                                setLegendAdded(true);
                                setTimeout(() => setLegendAdded(false), 2000);
                                // setOperationIndex2(24)
                                saveWidgetOperation(24, true);
                              }}
                              variant="outlined"
                            >
                              Add Legend
                            </Button>
                            {legendAdded && (
                              <Alert
                                severity={"success"}
                                style={{
                                  marginTop: 45,
                                  position: "absolute",
                                  fontSize: 12,
                                  height: 40,
                                  alignItems: "center",
                                }}
                              >
                                Legend Added Successfully
                              </Alert>
                            )}
                          </div>
                        </div>
                      ) : operationIndex === 3 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            padding: 5,
                          }}
                        >
                          <div>
                            <Chip
                              sx={{
                                fontSize: 16,
                                color: "#067AB4",
                                fontFamily: "Trebuchet MS",
                                fontWeight: "bold",
                                backgroundColor: "#C1D9EC",
                              }}
                              label="Slice"
                            />
                            <div
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              Slices a data set based on a given indices
                            </div>
                            <div style={{ marginTop: 30, fontSize: 14 }}>
                              Enter Start Index
                            </div>
                            <input
                              style={{
                                marginTop: 20,
                                background: "#F0F0F0",
                                border: "1px solid gray",
                                width: 150,
                                height: 20,
                              }}
                              type={"text"}
                              value={startIndex}
                              onChange={(e) => {
                                s = e.target.value;
                                setStartIndex(e.target.value);
                              }}
                            />
                            <div style={{ marginTop: 30, fontSize: 14 }}>
                              Enter End Index
                            </div>
                            <input
                              style={{
                                marginTop: 20,
                                background: "#F0F0F0",
                                border: "1px solid gray",
                                width: 150,
                                height: 20,
                              }}
                              type={"text"}
                              value={lastIndex}
                              onChange={(e) => {
                                l = e.target.value;
                                setLastIndex(e.target.value);
                              }}
                            />
                            <div style={{ alignSelf: "center", marginTop: 20 }}>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  handleSlice();
                                }}
                                variant="outlined"
                              >
                                Slice
                              </Button>
                            </div>

                            <div style={{ alignSelf: "center", marginTop: 12 }}>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  resetData();
                                }}
                                variant="outlined"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : operationIndex === 6 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                            padding: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              sx={{
                                fontSize: 16,
                                color: "#067AB4",
                                fontFamily: "Trebuchet MS",
                                fontWeight: "bold",
                                backgroundColor: "#C1D9EC",
                              }}
                              label="Duplicate Check"
                            />
                            <div
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              Find and removes duplicate rows
                            </div>
                            <div>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  handleDuplicate();
                                }}
                                variant="outlined"
                              >
                                Duplicate Check
                              </Button>
                            </div>

                            {dupCheck !== 0 && dupCheck !== -1 ? (
                              <p style={{ marginLeft: 10, marginBottom: 0 }}>
                                {"Duplicate found at row number - " +
                                  (dupCheck + 1) +
                                  "\n and removed"}
                              </p>
                            ) : (
                              dupCheck === -1 && (
                                <p style={{ marginLeft: 10, marginBottom: 0 }}>
                                  {"No Duplicates found"}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      ) : operationIndex === 9 ? (
                        <div style={{ padding: 5 }}>
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Replace Null"
                          />
                          <div
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Finds and replaces null values with the given value
                          </div>
                          <div style={{ fontSize: 14, marginBottom: 10 }}>
                            What do you want to replace with null values found ?
                          </div>
                          <input
                            style={{
                              marginLeft: 10,
                              marginBottom: 15,
                              background: "#F0F0F0",
                              border: "1px solid gray",
                              width: 150,
                              height: 20,
                            }}
                            onChange={(e) => setReplaceInput(e.target.value)}
                          ></input>
                          <div>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                fontSize: 10,
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                handleReplaceNull();
                              }}
                              variant="outlined"
                            >
                              Replace Null Values
                            </Button>
                          </div>
                          <div style={{ marginTop: 12 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                resetData();
                              }}
                              variant="outlined"
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      ) : operationIndex === 5 ? (
                        <div style={{ padding: 5 }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <Chip
                              sx={{
                                fontSize: 16,
                                color: "#067AB4",
                                fontFamily: "Trebuchet MS",
                                fontWeight: "bold",
                                backgroundColor: "#C1D9EC",
                              }}
                              label="Rename"
                            />

                            <div
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              Renames a given column name from the data set
                            </div>

                            <div style={{ fontSize: 14, marginBottom: 8 }}>
                              Column
                            </div>
                            <select
                              onChange={handleRenameColumn}
                              value={selectedColumn}
                              style={{
                                marginBottom: 10,

                                width: 150,
                                height: 25,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              {columns.map((value, index) => {
                                return (
                                  <option
                                    style={{ fontSize: 14 }}
                                    key={index}
                                    value={index}
                                  >
                                    {value}
                                  </option>
                                );
                              })}
                            </select>

                            <div style={{ fontSize: 14, marginBottom: 8 }}>
                              New Name
                            </div>
                            <input
                              style={{
                                marginTop: -4,
                                background: "#F0F0F0",
                                border: "1px solid gray",
                                width: 150,
                                height: 20,
                                marginBottom: 15,
                              }}
                              type={"text"}
                              value={newColumnName}
                              onChange={(e) => setnewColumnName(e.target.value)}
                            />

                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                width: "90%",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => rename()}
                              variant="outlined"
                            >
                              Rename
                            </Button>
                          </div>
                        </div>
                      ) : operationIndex === 7 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Fuzzy Search"
                          />
                          <div
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Filters data set based on a given string that
                            matches in a column
                          </div>

                          <input
                            style={{
                              marginBottom: 15,
                              background: "#F0F0F0",
                              border: "1px solid gray",
                              width: 150,
                              height: 20,
                              background: "#F0F0F0",
                              // border: '1px solid gray',
                              width: 150,
                              height: 20,
                            }}
                            value={fuzzyInput}
                            onChange={(e) => {
                              //console.log(e.target.value)
                              setfuzzyInput(e.target.value);
                            }}
                          ></input>
                          <div>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={handleFuzzySearch}
                              variant="outlined"
                            >
                              Fuzzy Search
                            </Button>
                          </div>
                          <div style={{ marginTop: 12 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                resetData();
                              }}
                              variant="outlined"
                            >
                              Reset
                            </Button>
                          </div>
                          {fuzzyCount === 0 ? null : (
                            <p>{fuzzyCount + " Rows found"}</p>
                          )}
                        </div>
                      ) : operationIndex === 0 ? (
                        <div style={{ overflowY: "auto", marginTop: 10 }}>
                          <div
                            style={{
                              marginTop: 10,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                              padding: 5,
                            }}
                          >
                            <select
                              onChange={(e) =>
                                e.target.value == 0
                                  ? setIsColumnNumeric(false)
                                  : setIsColumnNumeric(true)
                              }
                              value={isColumnNumeric ? 1 : 0}
                              style={{
                                marginTop: 15,
                                display: "flex",
                                alignSelf: "flex-end",
                                marginBottom: 12,
                                width: 74,
                                height: 22,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              <option style={{ fontSize: 14 }} value={0}>
                                {"Selection"}
                              </option>
                              <option style={{ fontSize: 14 }} value={1}>
                                {"Range"}
                              </option>
                            </select>

                            <Chip
                              sx={{
                                fontSize: 16,
                                color: "#067AB4",
                                fontFamily: "Trebuchet MS",
                                fontWeight: "bold",
                                backgroundColor: "#C1D9EC",
                              }}
                              label="Filter"
                            />

                            <p style={{ fontSize: 14, marginBottom: 6 }}>
                              Column
                            </p>
                            <select
                              onChange={
                                isColumnNumeric ? handleFilter2 : handleFilter
                              }
                              value={scolumn}
                              style={{
                                marginBottom: 12,
                                width: 150,
                                height: 25,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              <option
                                style={{
                                  fontSize: 14,
                                  height: 25,
                                  marginBottom: 20,
                                }}
                                //value={0}
                              >
                                {"select Column"}
                              </option>
                              {columns.map((value, index) => {
                                return (
                                  <option
                                    style={{ fontSize: 14 }}
                                    key={index}
                                    value={index}
                                  >
                                    {value}
                                  </option>
                                );
                              })}
                            </select>
                            {isColumnNumeric === false ? (
                              <div style={{ marginTop: -10 }}>
                                <p
                                  style={{
                                    fontSize: 14,
                                    marginBottom: 4,
                                  }}
                                >
                                  Column Values
                                </p>

                                <input
                                  style={{
                                    marginBottom: 10,
                                    background: "#F0F0F0",
                                    border: "1px solid gray",
                                    width: 150,
                                    height: 20,
                                  }}
                                  placeholder={"Filter by..."}
                                  type={"text"}
                                  value={searchInput}
                                  onChange={(e) => {
                                    setSearch(true);
                                    setsearchInput(e.target.value);
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px",
                                    height: 185,
                                    width: 170,
                                    overflow: "auto",
                                    marginTop: -5,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "blue",
                                        fontSize: 12,
                                        marginTop: 5,
                                        marginBottom: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => selectAll()}
                                    >
                                      Select All
                                    </div>
                                    <div
                                      style={{
                                        color: "blue",
                                        fontSize: 12,
                                        marginTop: 5,
                                        marginBottom: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => selectNone()}
                                    >
                                      Select None
                                    </div>
                                  </div>
                                  {columnValues != undefined &&
                                    columnValues.map((value, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <input
                                            style={{ marginRight: 10 }}
                                            value={value}
                                            type="checkbox"
                                            checked={checked.includes(value)}
                                            onChange={(e) =>
                                              handleCheck(e, index)
                                            }
                                          />
                                          <span>{value}</span>
                                        </div>
                                      );
                                    })}
                                </div>

                                <div
                                  style={{
                                    height: 70,
                                    width: 150,
                                    flexWrap: "wrap",
                                    overflow: "auto",
                                    marginTop: 12,
                                    fontSize: 14,
                                  }}
                                >{`Selected: ${checkedItems}`}</div>
                                <div style={{ marginTop: 8 }}>
                                  <Button
                                    sx={{
                                      bgcolor: "#067AB4",
                                      color: "white",
                                      height: 25,
                                      "&:hover, &:focus": {
                                        bgcolor: "#0BAFFF",
                                        color: "white",
                                      },
                                    }}
                                    onClick={() => applyFilter(1)}
                                    variant="outlined"
                                  >
                                    Apply
                                  </Button>
                                </div>
                                <div style={{ marginTop: 8 }}>
                                  <Button
                                    sx={{
                                      bgcolor: "#067AB4",
                                      color: "white",
                                      height: 25,
                                      "&:hover, &:focus": {
                                        bgcolor: "#0BAFFF",
                                        color: "#067AB4",
                                      },
                                    }}
                                    onClick={() => {
                                      resetData();
                                    }}
                                    variant="outlined"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div style={{ marginTop: -6 }}>
                                <div
                                  style={{
                                    fontSize: 14,
                                    marginBottom: 6,
                                  }}
                                >
                                  Aggregation
                                </div>
                                <select
                                  onChange={(e) =>
                                    e.target.value == 0
                                      ? null
                                      : e.target.value == 1
                                      ? findSum()
                                      : e.target.value == 2
                                      ? findMin()
                                      : e.target.value == 3
                                      ? findMax()
                                      : e.target.value == 4
                                      ? findAvg()
                                      : e.target.value == 5
                                      ? findCount()
                                      : null
                                  }
                                  style={{
                                    marginBottom: 10,
                                    width: 150,
                                    height: 25,
                                    backgroundColor: "white",
                                    border: "0.5px solid",
                                    borderRadius: 10,
                                    fontSize: 12,
                                    color: "#067AB4",
                                  }}
                                >
                                  <option style={{ fontSize: 14 }} value={0}>
                                    {"No aggregation"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={1}>
                                    {"Sum"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={2}>
                                    {"Minimum"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={3}>
                                    {"Maximum"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={4}>
                                    {"Average"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={5}>
                                    {"Count"}
                                  </option>
                                </select>
                                <div style={{ marginBottom: 6, fontSize: 14 }}>
                                  Condition
                                </div>
                                <select
                                  onChange={handleFilterCondition}
                                  style={{
                                    width: 150,
                                    height: 25,
                                    backgroundColor: "white",
                                    border: "0.5px solid",
                                    borderRadius: 10,
                                    fontSize: 12,
                                    color: "#067AB4",
                                  }}
                                >
                                  <option style={{ fontSize: 14 }} value={2}>
                                    {"number equals"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={3}>
                                    {"number is greater than"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={4}>
                                    {"number is greater than or equals"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={5}>
                                    {"number is lesser than"}
                                  </option>
                                  <option style={{ fontSize: 14 }} value={6}>
                                    {"number is lesser than or equals"}
                                  </option>
                                </select>

                                <input
                                  style={{
                                    marginBottom: 5,
                                    marginTop: 8,
                                    background: "#F0F0F0",
                                    border: "1px solid gray",
                                    width: 150,
                                    height: 20,
                                  }}
                                  type={"text"}
                                  value={input1}
                                  onChange={(e) => {
                                    input = e.target.value;
                                    setInput1(e.target.value);
                                  }}
                                />
                                <div
                                  style={{ marginLeft: 85, marginBottom: 10 }}
                                >
                                  <Button
                                    sx={{
                                      bgcolor: "#067AB4",
                                      color: "white",
                                      height: 20,
                                      width: 40,
                                      fontSize: 10,
                                      "&:hover, &:focus": {
                                        bgcolor: "#0BAFFF",
                                        color: "#067AB4",
                                      },
                                    }}
                                    onClick={() => {
                                      setInput1("");
                                      input = null;
                                    }}
                                    variant="outlined"
                                  >
                                    Clear
                                  </Button>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",

                                        fontSize: 12,
                                        borderRadius: 6,
                                        padding: 4,
                                        marginRight: 2,
                                      }}
                                    >
                                      Sum:
                                    </label>
                                    <label style={{ fontSize: 12, padding: 4 }}>
                                      {sum1}
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",

                                        fontSize: 12,
                                        borderRadius: 6,
                                        padding: 4,
                                        marginRight: 2,
                                      }}
                                    >
                                      Count:
                                    </label>
                                    <label style={{ fontSize: 12, padding: 4 }}>
                                      {count1}
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        borderRadius: 6,
                                        padding: 4,
                                        marginRight: 2,
                                      }}
                                    >
                                      Min:
                                    </label>
                                    <label style={{ fontSize: 12, padding: 4 }}>
                                      {min1}
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        borderRadius: 6,
                                        padding: 4,
                                        marginRight: 2,
                                      }}
                                    >
                                      Avg:
                                    </label>
                                    <label style={{ fontSize: 12, padding: 4 }}>
                                      {avg1}
                                    </label>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        borderRadius: 6,
                                        padding: 4,
                                        marginRight: 2,
                                      }}
                                    >
                                      Max:
                                    </label>
                                    <label style={{ fontSize: 12, padding: 4 }}>
                                      {max1}
                                    </label>
                                  </div>
                                </div>

                                <div style={{ marginTop: 8 }}>
                                  <Button
                                    sx={{
                                      bgcolor: "#067AB4",
                                      color: "white",
                                      height: 25,
                                      "&:hover, &:focus": {
                                        bgcolor: "#0BAFFF",
                                        color: "white",
                                      },
                                    }}
                                    onClick={() => filter(1)}
                                    variant="outlined"
                                  >
                                    Apply
                                  </Button>
                                </div>
                                <div style={{ marginTop: 8 }}>
                                  <Button
                                    sx={{
                                      bgcolor: "#067AB4",
                                      color: "white",
                                      height: 25,
                                      "&:hover, &:focus": {
                                        bgcolor: "#0BAFFF",
                                        color: "#067AB4",
                                      },
                                    }}
                                    onClick={() => {
                                      resetData();
                                    }}
                                    variant="outlined"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : operationIndex === 4 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Sort"
                          />
                          <div
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 25,
                            }}
                          >
                            Sorts a data set based on a given column name and
                            order
                          </div>

                          <div
                            style={{
                              fontSize: 14,
                            }}
                          >
                            Order
                          </div>
                          <select
                            onChange={handleSortType}
                            value={sortType1}
                            style={{
                              marginTop: 10,
                              //  border: '1px solid #FFF',
                              border: "0.5px solid",
                              width: 150,
                              height: 25,
                              backgroundColor: "white",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            <option
                              style={{
                                fontSize: 14,
                                height: 25,
                                marginBottom: 10,
                              }}
                              value={-1}
                            >
                              {"select sort type"}
                            </option>
                            <option style={{ fontSize: 12 }} value={0}>
                              {"Ascending"}
                            </option>
                            <option style={{ fontSize: 12 }} value={1}>
                              {"Descending"}
                            </option>
                          </select>

                          <div style={{ fontSize: 14, marginTop: 10 }}>
                            Column
                          </div>
                          <select
                            value={scolumn}
                            onChange={(event) => {
                              setContainNull(0);
                              if (sortType == 0) handleSortAsc(event);
                              else handleSortDesc(event);
                            }}
                            style={{
                              marginTop: 10,

                              //  border: '1px solid #FFF',
                              width: 150,
                              height: 25,
                              border: "0.5px solid",
                              backgroundColor: "white",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            {columns.map((value, index) => {
                              return (
                                <option
                                  style={{ fontSize: 14 }}
                                  key={index}
                                  value={index}
                                >
                                  {value}
                                </option>
                              );
                            })}
                          </select>

                          {containNull === 1 && (
                            <div
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 25,
                              }}
                            >
                              Column contains null values
                            </div>
                          )}
                        </div>
                      ) : operationIndex === 8 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Standardization"
                          />

                          <div>
                            <p
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              Create a Regex Below to search in a data set
                            </p>
                            <input
                              value={regexValue}
                              onChange={(e) => setRegexValue(e.target.value)}
                              style={{
                                marginBottom: 10,
                                background: "#F0F0F0",
                                border: "1px solid gray",
                                marginTop: 10,
                                height: 20,
                                width: 150,
                                marginBottom: 25,
                              }}
                            ></input>

                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                handleFuzzySearch(1);
                                //  saveWidgetOperation()
                              }}
                              variant="outlined"
                            >
                              Standardization
                            </Button>
                            <div style={{ marginTop: 10 }}>
                              <Button
                                sx={{
                                  bgcolor: "#067AB4",
                                  color: "white",
                                  "&:hover, &:focus": {
                                    bgcolor: "#0BAFFF",
                                    color: "white",
                                  },
                                }}
                                onClick={() => {
                                  resetData();
                                }}
                                variant="outlined"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : operationIndex === 10 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Check Incomplete"
                          />
                          <p
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Check which Columns are incomplete
                          </p>
                          <div style={{ marginBottom: 10 }}>Column</div>
                          <select
                            onChange={(e) => {
                              setInComplete(false);
                              setScolumn(e.target.value);
                            }}
                            value={scolumn}
                            style={{
                              marginBottom: 15,
                              // border: '1px solid #FFF',
                              width: 150,
                              height: 25,
                              backgroundColor: "white",
                              border: "0.5px solid",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            <option
                              style={{
                                fontSize: 14,
                                height: 25,
                                marginBottom: 20,
                              }}
                              //value={0}
                            >
                              {"select Column"}
                            </option>
                            {columns.map((value, index) => {
                              return (
                                <option
                                  style={{ fontSize: 14 }}
                                  key={index}
                                  value={index}
                                >
                                  {value}
                                </option>
                              );
                            })}
                          </select>

                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "white",
                              },
                            }}
                            onClick={checkInComplete}
                            variant="outlined"
                          >
                            Check InComplete
                          </Button>
                          {inComplete === false && checkedColumn ? (
                            <p
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              Column is Complete
                            </p>
                          ) : inComplete === true && checkedColumn ? (
                            <p
                              style={{
                                fontSize: 14,
                                color: "#969595",
                                marginTop: 10,
                                marginBottom: 15,
                              }}
                            >
                              Column is InComplete
                            </p>
                          ) : null}
                        </div>
                      ) : operationIndex === 12 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Case Format"
                          />

                          <p
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Changes Case format of data set
                          </p>

                          <select
                            onChange={handleCaseType}
                            value={case1}
                            style={{
                              marginTop: 10,
                              marginBottom: 25,
                              //  border: '1px solid #FFF',
                              border: "0.5px solid",
                              width: 150,
                              height: 25,
                              backgroundColor: "white",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            <option
                              style={{
                                fontSize: 14,
                                height: 25,
                                marginBottom: 20,
                              }}
                              value={-1}
                            >
                              {"select case type"}
                            </option>
                            <option style={{ fontSize: 12 }} value={0}>
                              {"To lower case"}
                            </option>
                            <option style={{ fontSize: 12 }} value={1}>
                              {"To upper case"}
                            </option>
                          </select>

                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "white",
                              },
                            }}
                            onClick={() => {
                              caseFormat();
                              saveWidgetOperation();
                            }}
                            variant="outlined"
                          >
                            Get Case Formatted
                          </Button>
                        </div>
                      ) : operationIndex === 11 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Formatted"
                          />
                          <p
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Get Formatted
                          </p>

                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "white",
                              },
                            }}
                            onClick={() => {
                              handleSlice();
                              //  saveWidgetOpration()
                            }}
                            variant="outlined"
                          >
                            Get Formatted
                          </Button>
                        </div>
                      ) : operationIndex === 13 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Discrete Range"
                          />

                          <div style={{ marginTop: 30, fontSize: 14 }}>
                            Enter Start Index
                          </div>
                          <input
                            style={{
                              marginTop: 20,
                              background: "#F0F0F0",
                              border: "1px solid gray",
                              width: 150,
                              height: 20,
                            }}
                            type={"text"}
                            value={startIndex}
                            onChange={(e) => {
                              s = e.target.value;
                              setStartIndex(e.target.value);
                            }}
                          />
                          <div style={{ marginTop: 30, fontSize: 14 }}>
                            Enter End Index
                          </div>
                          <input
                            style={{
                              marginTop: 20,
                              background: "#F0F0F0",
                              border: "1px solid gray",
                              width: 150,
                              height: 20,
                            }}
                            type={"text"}
                            value={lastIndex}
                            onChange={(e) => {
                              l = e.target.value;
                              setLastIndex(e.target.value);
                            }}
                          />
                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              marginTop: 6,
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "#067AB4",
                              },
                            }}
                            onClick={() => {
                              handleSlice();
                            }}
                            variant="outlined"
                          >
                            Discrete Range
                          </Button>

                          <div style={{ marginTop: 10 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                resetData();
                              }}
                              variant="outlined"
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      ) : operationIndex === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Merge"
                          />
                          <p
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Merges two data sets
                          </p>
                          <div style={{ marginBottom: 8 }}>
                            Choose Dataset 1
                          </div>
                          <div>
                            <select
                              onChange={(e) => handleDataset1(e, 1)}
                              // value={}
                              style={{
                                width: 150,
                                height: 25,
                                marginTop: 5,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              {databases &&
                                databases.map((ele) => (
                                  <option
                                    style={{ fontSize: 14 }}
                                    key={ele.id}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div style={{ marginBottom: 8, marginTop: 10 }}>
                            Choose Dataset 2
                          </div>

                          <div>
                            <select
                              onChange={(e) => handleDataset2(e, 1)}
                              // value={}
                              style={{
                                width: 150,
                                height: 25,
                                marginTop: 5,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              {databases &&
                                databases.map((ele) => (
                                  <option
                                    style={{ fontSize: 14 }}
                                    key={ele.id}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {performed && (
                            <p style={{ marginTop: 10, marginBottom: 15 }}>
                              Merged Successfully
                            </p>
                          )}
                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "white",
                              },
                            }}
                            onClick={handleMerge}
                            variant="outlined"
                          >
                            Merge
                          </Button>
                        </div>
                      ) : operationIndex === 2 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 5,
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Group"
                          />

                          <p
                            style={{
                              fontSize: 14,
                              color: "#969595",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            Groups a data set based on a given column
                          </p>

                          <select
                            onChange={(event) => {
                              column1 = event.target.value;
                              // console.log('selectedColumn-', column)
                              // setScolumn(column)
                            }}
                            style={{
                              marginBottom: 15,
                              border: "0.5px solid",
                              width: 150,
                              height: 25,
                              backgroundColor: "white",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            <option
                              style={{
                                fontSize: 14,
                                height: 25,
                                marginBottom: 10,
                              }}
                              //value={0}
                            >
                              {"select Column"}
                            </option>
                            {columns.map((value, index) => {
                              return (
                                <option
                                  style={{ fontSize: 14 }}
                                  key={index}
                                  value={index}
                                >
                                  {value}
                                </option>
                              );
                            })}
                          </select>

                          <Button
                            sx={{
                              bgcolor: "#067AB4",
                              color: "white",
                              "&:hover, &:focus": {
                                bgcolor: "#0BAFFF",
                                color: "white",
                              },
                            }}
                            onClick={() => handleGroup()}
                            variant="outlined"
                          >
                            Group
                          </Button>

                          <div style={{ marginTop: 16 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                height: 35,
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                resetData();
                                setColumns(props.columns);
                              }}
                              variant="outlined"
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      ) : operationIndex2 === 16 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Change Dataset"
                          />
                          <div>
                            <select
                              onChange={(e) => storeDatasourceResult(e, 1)}
                              // value={}
                              style={{
                                width: 150,
                                height: 25,
                                marginTop: 10,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              {databases &&
                                databases.map((ele) => (
                                  <option
                                    style={{ fontSize: 14 }}
                                    key={ele.id}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {databases && databases.length === 0 && (
                            <div style={{ fontSize: 14, marginTop: 8 }}>
                              Please Select Data source
                            </div>
                          )}
                        </div>
                      ) : operationIndex2 === 17 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ marginBottom: 20, marginTop: -230 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => setPicker1Visible(!picker1Visible)}
                              variant="outlined"
                            >
                              Change Color1
                            </Button>
                          </div>
                          <div style={{ marginBottom: 10 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => setPicker2Visible(!picker2Visible)}
                              variant="outlined"
                            >
                              Change Color2
                            </Button>
                          </div>
                          <div style={{ marginBottom: 10, marginTop: 8 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => setPicker3Visible(!picker3Visible)}
                              variant="outlined"
                            >
                              Change Color3
                            </Button>
                          </div>

                          {picker1Visible ? (
                            <div
                              style={{
                                marginTop: -135,
                                display: "flex",
                                alignSelf: "center",
                                justifyContent: "center",
                                position: "absolute",
                              }}
                            >
                              <SketchPicker
                                color={color1}
                                onChangeComplete={handleChange1}
                              />
                            </div>
                          ) : picker2Visible ? (
                            <div
                              style={{
                                display: "flex",
                                alignSelf: "center",
                                justifyContent: "center",
                                marginTop: -135,
                                position: "absolute",
                              }}
                            >
                              <SketchPicker
                                color={color2}
                                onChangeComplete={handleChange2}
                              />
                            </div>
                          ) : picker3Visible ? (
                            <div
                              style={{
                                display: "flex",
                                alignSelf: "center",
                                justifyContent: "center",
                                marginTop: -135,
                                position: "absolute",
                              }}
                            >
                              <SketchPicker
                                color={color3}
                                onChangeComplete={handleChange3}
                              />
                            </div>
                          ) : null}
                          <div
                            style={{
                              marginTop: 185,
                              position: "absolute",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                fontSize: 14,
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                picker1Visible
                                  ? setPicker1Visible(false)
                                  : picker2Visible
                                  ? setPicker2Visible(false)
                                  : picker3Visible
                                  ? setPicker3Visible(false)
                                  : null;
                              }}
                              variant="outlined"
                            >
                              Confirm Color
                            </Button>
                          </div>
                        </div>
                      ) : operationIndex2 === 18 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Add Annotation"
                          />
                          <p
                            style={{
                              margin: 5,
                              fontSize: 14,
                              fontFamily: "Trebuchet MS",
                            }}
                          >
                            Enter an annotation and click on the point from the
                            chart where you want to add an annotation
                          </p>
                          <input
                            style={{
                              alignSelf: "center",
                              width: 160,
                              height: 40,
                              border: "1px solid #CCC",
                              marginTop: 8,
                              marginBottom: 20,
                              borderRadius: "4px",
                            }}
                            type={"text"}
                            placeholder={"Enter an Annotation.."}
                            value={annotation}
                            onChange={(e) => {
                              setAnnotation(e.target.value);
                            }}
                          />
                        </div>
                      ) : operationIndex2 === 20 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Select Filter"
                          />
                          <div>
                            <select
                              onChange={(e) => applySegment(e)}
                              value={selectedSegment}
                              style={{
                                width: 150,
                                height: 25,
                                marginTop: 10,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              <option value="" disabled>
                                Select Filter
                              </option>
                              {segments &&
                                segments.map((ele) => (
                                  <option
                                    style={{ fontSize: 14 }}
                                    key={ele.id}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      ) : operationIndex2 === 21 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                          }}
                        >
                          <Chip
                            sx={{
                              fontSize: 16,
                              color: "#067AB4",
                              fontFamily: "Trebuchet MS",
                              fontWeight: "bold",
                              backgroundColor: "#C1D9EC",
                            }}
                            label="Date Filter"
                          />
                          <p style={{ fontSize: 14, marginBottom: 6 }}>
                            Select Date Column
                          </p>

                          <select
                            onChange={(event) => {
                              column = event.target.value;
                              setDateColumn(event.target.value);
                            }}
                            value={dateColumn}
                            style={{
                              marginBottom: 12,
                              width: "90%",
                              height: 25,
                              backgroundColor: "white",
                              border: "0.5px solid",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            {columns.map((value, index) => {
                              return (
                                <option
                                  style={{ fontSize: 14 }}
                                  key={index}
                                  value={index}
                                >
                                  {value}
                                </option>
                              );
                            })}
                          </select>
                          <div>
                            <select
                              onChange={handleDateFilter}
                              value={yoyOption}
                              style={{
                                width: 150,
                                height: 25,
                                marginTop: 10,
                                backgroundColor: "white",
                                border: "0.5px solid",
                                borderRadius: 10,
                                fontSize: 12,
                                color: "#067AB4",
                              }}
                            >
                              {dateFilterOptions.map((ele) => (
                                <option
                                  style={{ fontSize: 14 }}
                                  key={ele.id}
                                  value={ele.id}
                                >
                                  {ele.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <input
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              background: "#F0F0F0",
                              border: "1px solid gray",
                              width: "90%",
                              height: 25,
                            }}
                            placeholder={"(YYYY-MM-DD)"}
                            type={"text"}
                            value={dateInput1}
                            onChange={(e) => {
                              setDateInput1(e.target.value);
                            }}
                          />

                          <div style={{ marginTop: 8 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                height: 25,
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => handleDateFilter2()}
                              variant="outlined"
                            >
                              Apply
                            </Button>
                          </div>
                          <div style={{ marginTop: 8 }}>
                            <Button
                              sx={{
                                bgcolor: "#067AB4",
                                color: "white",
                                height: 25,
                                "&:hover, &:focus": {
                                  bgcolor: "#0BAFFF",
                                  color: "white",
                                },
                              }}
                              onClick={() => {
                                resetData();
                              }}
                              variant="outlined"
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </Card>
                  )}

                  {/* rrr */}
                  <Card
                    id="capture"
                    ref={ref2}
                    sx={{ boxShadow: 3, border: "1px solid #d8d8d8" }}
                    style={{
                      height: 500,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 8,
                      overflow: "auto",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          zIndex: 2,
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          marginTop: 175,
                          height: 10,
                          marginBottom: 4,
                          marginLeft: "auto",
                          marginRight: "auto",
                          left: 0,
                          right: 0,
                        }}
                      >
                        {isExport === false && (
                          <div
                            style={{
                              marginTop: -6,
                            }}
                          >
                            <IconButton
                              color="primary"
                              aria-label="add an alarm"
                              onClick={() => {
                                setEditChartTitleModal(true);
                              }}
                            >
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </div>
                        )}
                        <div style={{ fontFamily: "Trebuchet MS" }}>
                          {chartTitle}
                        </div>
                      </div>
                      {/* <div style={{ height: 8 }}> </div> */}
                      {selectAnyChart == false &&
                      graph == 0 &&
                      props.selectedCharts &&
                      props.selectedCharts[0].type == "special20" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special21" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special22" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special23" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special24" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special25" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special26" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special27" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special28" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special29" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special30" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special31" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special32" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special33" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special34" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special35" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special36" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special37" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special38" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special39" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special40" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special41" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special42" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special43" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special44" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special45" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special46" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special47" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special48" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special49" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special50" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special51" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special52" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special53" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special54" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special55" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special56" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special57" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <CustomNodeCategoricalDotPlot
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special58" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special59" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special60" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special61" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special62" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special63" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special64" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special65" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special66" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special67" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special68" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special69" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special70" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special71" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special72" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special73" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special74" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special75" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special76" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special77" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special78" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special81" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special82" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special83" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special84" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special85" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special86" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special87" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special88" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special89" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special90" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special91" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special92" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special93" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special94" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special95" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special96" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special97" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special98" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special99" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special100" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special101" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special102" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special103" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special104" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special105" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special106" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special107" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special108" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special109" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special110" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special111" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special112" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special113" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special114" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special115" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special116" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special120" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special121" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special122" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special123" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special124" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special125" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special126" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special127" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special128" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special129" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special130" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special131" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special132" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special133" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special134" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special135" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special136" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special137" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special138" ? (
                        <LogarithmicAxesBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={props.x}
                          y={props.y}
                          z={props.z}
                          IsDataNumeric={
                            xNumeric === true && yNumeric === true
                              ? true
                              : false
                          }
                        />
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special139" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special140" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special141" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special142" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special147" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special148" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special149" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special150" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special151" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special152" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special153" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special154" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special155" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special156" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special157" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special158" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special159" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special160" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special161" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special162" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special163" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special164" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special165" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special166" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special167" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special168" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special169" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special170" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special171" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special172" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special173" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special174" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special175" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special176" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special177" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special178" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special179" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special180" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special181" ? (
                        <SimpleInsetGraphBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={columnXData}
                          y={columnYData}
                          z={columnZData}
                          useLegend={useLegend}
                          columnX={columnX}
                          columnY={columnY}
                          columnZ={columnZ}
                          xName={xName}
                          yName={yName}
                          zName={zName}
                          parentCallback={handleCallback}
                          addAnnotation={addAnnotation}
                          annotation={annotation}
                          chartSubType={chartSubType}
                          getAnnotations={fetchAnnotations}
                          addedAnnotations={addedannotations}
                          existingWidget={props.existingWidget}
                          color1={color1}
                          color2={color2}
                        />
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special182" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <Multiple3DSubplotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true && zNumeric
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special183" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MixedSubPlotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special184" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <TableandChartSubplotBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special185" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special186" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special187" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special188" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special1" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special189" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special190" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special191" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special192" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special193" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special194" ? (
                        <div style={{ marginTop: -10 }}>
                          <div>
                            <div>
                              <BindingtoZoomEventsBlock
                                block={false}
                                main={true}
                                data={props.data2}
                                x={props.x}
                                y={props.y}
                                z={props.z}
                              />
                            </div>
                          </div>
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special195" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special196" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special197" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <FilterBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special198" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <GroupByBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special199" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special200" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special201" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special202" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special203" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special204" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special205" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special208" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special209" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special210" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special211" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special212" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special213" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special214" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special215" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special216" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special219" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special220" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special221" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <LassoSelectionBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special222" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <BasicRangeSlideronTimeSeriesBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special223" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special224" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special225" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special226" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special227" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special228" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special229" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special230" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special231" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special232" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special233" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MapAnimationsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special234" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special235" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special236" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special237" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special238" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special239" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special240" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special241" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special242" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special243" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          (
                          <AnimatingwithaSliderBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                          )
                        </div>
                      ) : null}
                      {/*graph=0*/}
                      {selectAnyChart == false &&
                      graph == 1 &&
                      props.selectedCharts &&
                      props.selectedCharts[1].type == "special20" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special21" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special22" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special23" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special24" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special25" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special26" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special27" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special28" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special29" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special30" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special31" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special32" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special33" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special34" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special35" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special36" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special37" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special38" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special39" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special40" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special41" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special42" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special43" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special44" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special45" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special46" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special47" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special48" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special49" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special50" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special51" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special52" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special53" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special54" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special55" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special56" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special57" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <CustomNodeCategoricalDotPlot
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special58" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special59" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special60" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special61" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special62" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special63" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special64" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special65" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special66" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special67" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special68" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special69" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special70" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special71" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special72" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special73" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special74" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special75" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special76" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special77" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special78" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special81" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special82" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special83" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special84" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special85" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special86" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special87" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special88" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special89" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special90" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special91" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special92" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special93" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special94" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special95" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special96" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special97" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special98" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special99" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special100" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special101" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special102" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special103" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special104" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special105" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special106" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special107" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special108" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special109" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special110" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special111" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special112" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special113" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special114" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special115" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special116" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special120" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special121" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special122" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special123" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special124" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special125" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special126" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special127" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special128" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special129" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special130" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special131" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special132" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special133" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special134" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special135" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special136" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special137" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special138" ? (
                        <LogarithmicAxesBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={props.x}
                          y={props.y}
                          z={props.z}
                          IsDataNumeric={
                            xNumeric === true && yNumeric === true
                              ? true
                              : false
                          }
                        />
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special139" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special140" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special141" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special142" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special147" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special148" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special149" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special150" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special151" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special152" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special153" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special154" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special155" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special156" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special157" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special158" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special159" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special160" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special161" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special162" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special163" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special164" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special165" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special166" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special167" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special168" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special169" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special170" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special171" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special172" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special173" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special174" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special175" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special176" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special177" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special178" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special179" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special180" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special181" ? (
                        <SimpleInsetGraphBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={columnXData}
                          y={columnYData}
                          z={columnZData}
                          useLegend={useLegend}
                          columnX={columnX}
                          columnY={columnY}
                          columnZ={columnZ}
                          xName={xName}
                          yName={yName}
                          zName={zName}
                          parentCallback={handleCallback}
                          addAnnotation={addAnnotation}
                          annotation={annotation}
                          chartSubType={chartSubType}
                          getAnnotations={fetchAnnotations}
                          addedAnnotations={addedannotations}
                          existingWidget={props.existingWidget}
                          color1={color1}
                          color2={color2}
                        />
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special182" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <Multiple3DSubplotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true && zNumeric
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special183" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MixedSubPlotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special184" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <TableandChartSubplotBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special185" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special186" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special187" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special188" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special1" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special189" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special190" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special191" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special192" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special193" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special194" ? (
                        <div style={{ marginTop: -10 }}>
                          <div>
                            <div>
                              <BindingtoZoomEventsBlock
                                block={false}
                                main={true}
                                data={props.data2}
                                x={props.x}
                                y={props.y}
                                z={props.z}
                              />
                            </div>
                          </div>
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special195" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special196" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special197" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <FilterBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special198" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <GroupByBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special199" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special200" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special201" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special202" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special203" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special204" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special205" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special208" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special209" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special210" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special211" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special212" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special213" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special214" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special215" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special216" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special219" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special220" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special221" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <LassoSelectionBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special222" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <BasicRangeSlideronTimeSeriesBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special223" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special224" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special225" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special226" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special227" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special228" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special229" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special230" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special231" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special232" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special233" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MapAnimationsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special234" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special235" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special236" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special237" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special238" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special239" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special240" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special241" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 1 &&
                        props.selectedCharts &&
                        props.selectedCharts[1].type == "special242" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special243" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          (
                          <AnimatingwithaSliderBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                          )
                        </div>
                      ) : null}
                      {/* graph=1 end */}
                      {selectAnyChart == false &&
                      graph == 2 &&
                      props.selectedCharts &&
                      props.selectedCharts[2].type == "special20" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special21" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special22" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special23" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special24" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special25" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special26" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special27" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special28" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special29" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special30" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special31" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special32" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special33" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special34" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special35" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special36" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special37" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special38" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special39" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special40" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special41" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special42" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special43" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special44" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special45" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special46" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special47" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special48" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special49" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special50" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special51" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special52" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special53" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special54" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special55" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special56" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special57" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <CustomNodeCategoricalDotPlot
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special58" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special59" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special60" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special61" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special62" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special63" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special64" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special65" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special66" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special67" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special68" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special69" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special70" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special71" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special72" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special73" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special74" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special75" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special76" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special77" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special78" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special81" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special82" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special83" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special84" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special85" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special86" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special87" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special88" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special89" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special90" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special91" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special92" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special93" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special94" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special95" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special96" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special97" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special98" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special99" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special100" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special101" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special102" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special103" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special104" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special105" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special106" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special107" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special108" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special109" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special110" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special111" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special112" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special113" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special114" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special115" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special116" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special120" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special121" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special122" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special123" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special124" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special125" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special126" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special127" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special128" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special129" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special130" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special131" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special132" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special133" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special134" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special135" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special136" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special137" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special138" ? (
                        <LogarithmicAxesBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={props.x}
                          y={props.y}
                          z={props.z}
                          IsDataNumeric={
                            xNumeric === true && yNumeric === true
                              ? true
                              : false
                          }
                        />
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special139" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special140" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special141" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special142" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special147" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special148" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special149" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special150" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special151" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special152" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special153" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special154" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special155" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special156" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special157" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special158" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special159" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special160" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special161" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special162" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special163" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special164" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special165" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special166" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special167" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special168" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special169" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special170" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special171" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special172" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special173" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special174" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special175" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special176" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special177" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special178" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special179" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special180" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special181" ? (
                        <SimpleInsetGraphBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={columnXData}
                          y={columnYData}
                          z={columnZData}
                          useLegend={useLegend}
                          columnX={columnX}
                          columnY={columnY}
                          columnZ={columnZ}
                          xName={xName}
                          yName={yName}
                          zName={zName}
                          parentCallback={handleCallback}
                          addAnnotation={addAnnotation}
                          annotation={annotation}
                          chartSubType={chartSubType}
                          getAnnotations={fetchAnnotations}
                          addedAnnotations={addedannotations}
                          existingWidget={props.existingWidget}
                          color1={color1}
                          color2={color2}
                        />
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special182" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <Multiple3DSubplotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true && zNumeric
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special183" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MixedSubPlotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special184" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <TableandChartSubplotBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special185" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special186" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special187" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special188" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special1" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special189" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special190" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special191" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special192" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special193" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special194" ? (
                        <div style={{ marginTop: -10 }}>
                          <div>
                            <div>
                              <BindingtoZoomEventsBlock
                                block={false}
                                main={true}
                                data={props.data2}
                                x={props.x}
                                y={props.y}
                                z={props.z}
                              />
                            </div>
                          </div>
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special195" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special196" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special197" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <FilterBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special198" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <GroupByBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special199" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special200" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special201" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special202" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special203" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special204" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special205" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special208" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special209" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special210" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special211" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special212" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special213" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special214" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special215" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special216" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special219" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special220" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special221" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <LassoSelectionBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special222" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <BasicRangeSlideronTimeSeriesBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special223" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special224" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special225" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special226" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special227" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special228" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special229" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special230" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special231" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special232" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special233" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MapAnimationsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special234" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special235" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special236" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special237" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special238" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special239" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special240" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special241" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 2 &&
                        props.selectedCharts &&
                        props.selectedCharts[2].type == "special242" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special243" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          (
                          <AnimatingwithaSliderBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                          )
                        </div>
                      ) : null}
                      {/*graph=2 end*/}
                      {selectAnyChart == false &&
                      graph == 3 &&
                      props.selectedCharts &&
                      props.selectedCharts[3].type == "special20" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special21" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special22" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special23" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special24" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special25" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special26" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special27" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special28" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special29" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special30" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special31" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special32" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special33" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special34" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special35" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special36" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special37" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special38" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special39" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special40" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special41" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special42" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special43" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special44" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special45" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special46" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special47" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special48" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special49" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special50" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special51" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special52" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special53" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special54" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special55" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special56" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special57" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <CustomNodeCategoricalDotPlot
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special58" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special59" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special60" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special61" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special62" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special63" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special64" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special65" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special66" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special67" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special68" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special69" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special70" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special71" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special72" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special73" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special74" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special75" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special76" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special77" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special78" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special81" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special82" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special83" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special84" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special85" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special86" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special87" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special88" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special89" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special90" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special91" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special92" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special93" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special94" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special95" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special96" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special97" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special98" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special99" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special100" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special101" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special102" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special103" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special104" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special105" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special106" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special107" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special108" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special109" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special110" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special111" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special112" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special113" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special114" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special115" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special116" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special120" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special121" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special122" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special123" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special124" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special125" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special126" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special127" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special128" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special129" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special130" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special131" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special132" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special133" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special134" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special135" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special136" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special137" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special138" ? (
                        <LogarithmicAxesBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={props.x}
                          y={props.y}
                          z={props.z}
                          IsDataNumeric={
                            xNumeric === true && yNumeric === true
                              ? true
                              : false
                          }
                        />
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special139" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special140" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special141" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special142" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special147" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special148" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special149" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special150" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special151" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special152" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special153" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special154" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special155" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special156" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special157" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special158" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special159" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special160" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special161" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special162" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special163" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special164" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special165" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special166" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special167" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special168" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special169" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special170" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special171" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special172" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special173" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special174" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special175" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special176" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special177" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special178" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special179" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special180" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special181" ? (
                        <SimpleInsetGraphBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={columnXData}
                          y={columnYData}
                          z={columnZData}
                          useLegend={useLegend}
                          columnX={columnX}
                          columnY={columnY}
                          columnZ={columnZ}
                          xName={xName}
                          yName={yName}
                          zName={zName}
                          parentCallback={handleCallback}
                          addAnnotation={addAnnotation}
                          annotation={annotation}
                          chartSubType={chartSubType}
                          getAnnotations={fetchAnnotations}
                          addedAnnotations={addedannotations}
                          existingWidget={props.existingWidget}
                          color1={color1}
                          color2={color2}
                        />
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special182" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <Multiple3DSubplotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true && zNumeric
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special183" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MixedSubPlotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special184" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <TableandChartSubplotBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special185" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special186" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special187" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special188" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special1" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special189" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special190" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special191" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special192" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special193" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special194" ? (
                        <div style={{ marginTop: -10 }}>
                          <div>
                            <div>
                              <BindingtoZoomEventsBlock
                                block={false}
                                main={true}
                                data={props.data2}
                                x={props.x}
                                y={props.y}
                                z={props.z}
                              />
                            </div>
                          </div>
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special195" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special196" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special197" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <FilterBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special198" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <GroupByBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special199" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special200" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special201" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special202" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special203" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special204" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special205" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special208" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special209" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special210" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special211" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special212" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special213" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special214" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special215" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special216" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special219" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special220" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special221" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <LassoSelectionBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special222" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <BasicRangeSlideronTimeSeriesBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special223" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special224" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special225" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special226" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special227" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special228" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special229" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special230" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special231" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special232" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special233" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MapAnimationsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special234" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special235" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special236" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special237" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special238" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special239" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special240" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special241" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 3 &&
                        props.selectedCharts &&
                        props.selectedCharts[3].type == "special242" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special243" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          (
                          <AnimatingwithaSliderBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                          )
                        </div>
                      ) : null}
                      {/*graph=3 end*/}
                      {selectAnyChart == false &&
                      graph == 4 &&
                      props.selectedCharts &&
                      props.selectedCharts[4].type == "special20" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special21" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special22" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special23" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special24" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special25" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special26" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special27" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special28" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special29" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special30" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special31" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special32" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special33" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special34" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special35" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special36" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special37" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special38" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special39" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special40" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special41" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special42" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special43" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special44" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special45" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special46" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special47" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special48" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special49" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special50" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special51" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special52" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special53" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special54" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special55" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special56" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special57" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <CustomNodeCategoricalDotPlot
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special58" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special59" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special60" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special61" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special62" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special63" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special64" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special65" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special66" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special67" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special68" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special69" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special70" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special71" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special72" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special73" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special74" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special75" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special76" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special77" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special78" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special81" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special82" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special83" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special84" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special85" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special86" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special87" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special88" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special89" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special90" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special91" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special92" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special93" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special94" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special95" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special96" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special97" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special98" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special99" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special100" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special101" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special102" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special103" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special104" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special105" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special106" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special107" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special108" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special109" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special110" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special111" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special112" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special113" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special114" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special115" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special116" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special120" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special121" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special122" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special123" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special124" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special125" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special126" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special127" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special128" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special129" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special130" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special131" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special132" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special133" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special134" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special135" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special136" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special137" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special138" ? (
                        <LogarithmicAxesBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={props.x}
                          y={props.y}
                          z={props.z}
                          IsDataNumeric={
                            xNumeric === true && yNumeric === true
                              ? true
                              : false
                          }
                        />
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special139" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special140" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special141" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special142" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special147" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special148" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special149" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special150" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special151" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special152" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special153" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special154" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special155" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special156" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special157" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special158" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special159" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special160" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special161" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special162" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special163" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special164" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special165" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special166" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special167" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special168" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special169" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special170" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special171" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special172" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special173" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special174" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special175" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special176" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special177" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special178" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special179" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special180" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special181" ? (
                        <SimpleInsetGraphBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={columnXData}
                          y={columnYData}
                          z={columnZData}
                          useLegend={useLegend}
                          columnX={columnX}
                          columnY={columnY}
                          columnZ={columnZ}
                          xName={xName}
                          yName={yName}
                          zName={zName}
                          parentCallback={handleCallback}
                          addAnnotation={addAnnotation}
                          annotation={annotation}
                          chartSubType={chartSubType}
                          getAnnotations={fetchAnnotations}
                          addedAnnotations={addedannotations}
                          existingWidget={props.existingWidget}
                          color1={color1}
                          color2={color2}
                        />
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special182" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <Multiple3DSubplotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true && zNumeric
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special183" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MixedSubPlotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special184" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <TableandChartSubplotBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special185" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special186" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special187" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special188" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special1" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special189" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special190" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special191" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special192" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special193" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special194" ? (
                        <div style={{ marginTop: -10 }}>
                          <div>
                            <div>
                              <BindingtoZoomEventsBlock
                                block={false}
                                main={true}
                                data={props.data2}
                                x={props.x}
                                y={props.y}
                                z={props.z}
                              />
                            </div>
                          </div>
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special195" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special196" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special197" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <FilterBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special198" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <GroupByBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special199" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special200" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special201" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special202" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special203" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special204" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special205" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special208" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special209" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special210" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special211" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special212" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special213" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special214" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special215" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special216" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special219" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special220" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special221" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <LassoSelectionBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special222" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <BasicRangeSlideronTimeSeriesBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special223" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special224" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special225" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special226" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special227" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special228" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special229" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special230" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special231" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special232" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special233" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MapAnimationsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special234" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special235" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special236" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special237" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special238" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special239" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special240" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special241" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 4 &&
                        props.selectedCharts &&
                        props.selectedCharts[4].type == "special242" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special243" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          (
                          <AnimatingwithaSliderBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                          )
                        </div>
                      ) : null}
                      {/*graph=4 end*/}
                      {selectAnyChart == false &&
                      graph == 5 &&
                      props.selectedCharts &&
                      props.selectedCharts[5].type == "special20" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special21" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special22" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special23" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special24" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special25" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special26" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special27" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special28" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special29" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special30" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special31" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special32" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special33" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special34" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special35" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special36" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special37" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special38" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special39" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special40" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special41" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special42" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special43" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special44" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special45" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special46" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special47" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special48" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special49" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special50" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special51" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special52" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special53" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special54" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special55" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special56" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special57" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <CustomNodeCategoricalDotPlot
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special58" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special59" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special60" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special61" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special62" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special63" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special64" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special65" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special66" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special67" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special68" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special69" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special70" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special71" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special72" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : subCategory == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special73" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special74" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special75" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special76" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special77" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special78" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special81" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special82" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special83" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special84" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special85" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special86" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special87" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special88" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special89" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special90" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special91" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special92" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special93" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special94" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special95" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special96" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special97" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special98" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special99" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special100" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special101" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special102" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special103" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special104" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special105" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special106" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special107" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special108" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special109" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special110" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special111" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special112" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special113" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special114" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special115" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special116" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special120" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special121" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special122" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special123" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special124" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special125" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special126" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special127" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : subCategory == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : subCategory == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          ) : (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special128" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special129" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special130" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special131" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                            />
                          ) : subCategory == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                            />
                          ) : subCategory == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          ) : (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special132" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special133" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special134" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special135" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special136" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special137" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special138" ? (
                        <LogarithmicAxesBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={props.x}
                          y={props.y}
                          z={props.z}
                          IsDataNumeric={
                            xNumeric === true && yNumeric === true
                              ? true
                              : false
                          }
                        />
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special139" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special140" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special141" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special142" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special147" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special148" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special149" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special150" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special151" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special152" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special153" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special154" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special155" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special156" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : subCategory == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special157" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special158" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special159" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special160" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special161" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special162" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special163" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special164" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special165" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special166" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special167" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special168" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special169" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special170" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special171" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special172" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special173" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special70" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                            />
                          ) : subCategory == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special174" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special175" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special176" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special177" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special178" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special179" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special180" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special181" ? (
                        <SimpleInsetGraphBlock
                          block={false}
                          main={true}
                          data={props.data2}
                          x={columnXData}
                          y={columnYData}
                          z={columnZData}
                          useLegend={useLegend}
                          columnX={columnX}
                          columnY={columnY}
                          columnZ={columnZ}
                          xName={xName}
                          yName={yName}
                          zName={zName}
                          parentCallback={handleCallback}
                          addAnnotation={addAnnotation}
                          annotation={annotation}
                          chartSubType={chartSubType}
                          getAnnotations={fetchAnnotations}
                          addedAnnotations={addedannotations}
                          existingWidget={props.existingWidget}
                          color1={color1}
                          color2={color2}
                        />
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special182" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <Multiple3DSubplotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true && zNumeric
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special183" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MixedSubPlotsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special184" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <TableandChartSubplotBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special185" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special186" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special187" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special188" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special1" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special189" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special190" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special191" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special192" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special193" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special194" ? (
                        <div style={{ marginTop: -10 }}>
                          <div>
                            <div>
                              <BindingtoZoomEventsBlock
                                block={false}
                                main={true}
                                data={props.data2}
                                x={props.x}
                                y={props.y}
                                z={props.z}
                              />
                            </div>
                          </div>
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special195" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special196" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special197" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <FilterBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                            IsDataNumeric={
                              xNumeric === true && yNumeric === true
                                ? true
                                : false
                            }
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special198" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <GroupByBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special199" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special200" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special201" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special202" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special203" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special204" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special205" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special208" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special209" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special210" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special211" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special212" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special213" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special214" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special215" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special216" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special219" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special220" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special221" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <LassoSelectionBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special222" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <BasicRangeSlideronTimeSeriesBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special223" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special224" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special225" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special226" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special227" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special228" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special229" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special230" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special231" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special232" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special233" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          <MapAnimationsBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special234" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special235" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special236" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special237" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                            />
                          ) : subCategory == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special238" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special239" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                            />
                          ) : subCategory == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special240" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special241" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 5 &&
                        props.selectedCharts &&
                        props.selectedCharts[5].type == "special242" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {subCategory == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : subCategory == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          ) : (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                            />
                          )}
                        </div>
                      ) : selectAnyChart == false &&
                        graph == 0 &&
                        props.selectedCharts &&
                        props.selectedCharts[0].type == "special243" ? (
                        <div
                          style={{
                            marginTop: 40,
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          (
                          <AnimatingwithaSliderBlock
                            block={false}
                            main={true}
                            data={props.data2}
                            x={columnXData}
                            y={columnYData}
                            z={columnZData}
                          />
                          )
                        </div>
                      ) : null}
                      :{/* graph=5 end */}
                      {selectAnyChart == true && (
                        <div
                          style={{
                            marginLeft: -20,
                            overflow: "hidden",
                            height: 800,
                            width: 900,
                          }}
                        >
                          {chartSubType == "special20" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special21" ? (
                            <CustomNodeDataLabelsHover
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special22" ? (
                            <CustomNodeDataLabelsOnThePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special23" ? (
                            <CustomNodeScatterPlotWithColorDimension
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special24" ? (
                            <CustomNodeBasicLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special25" ? (
                            <CustomNodeLineandScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special26" ? (
                            <CustomNodeLineandScatterPlotWithNames
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special27" ? (
                            <CustomLineandScatterStyling
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special28" ? (
                            <CustomNodeStyledLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special29" ? (
                            <CustomNodeColoredandStyledScatterPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special30" ? (
                            <CustomNodeLineShapeOptionsforInterpolation
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special31" ? (
                            <CustomNodeGraphandAxesTitles
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special32" ? (
                            <CustomNodeLineDash
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special33" ? (
                            <CustomNodeConnectGapsBetweenData
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special34" ? (
                            <CustomNodeBasicBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                              addAnnotation={addAnnotation}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                            />
                          ) : chartSubType == "special35" ? (
                            <CustomNodeGroupedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special36" ? (
                            <CustomNodeStackedBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special37" ? (
                            <CustomNodeBarChartwithHoverText
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special38" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special39" ? (
                            <CustomNodeGroupedBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special40" ? (
                            <CustomNodeBarChartwithDirectLabels
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special41" ? (
                            <CustomNodeCustomizingIndividualBarColors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special42" ? (
                            <CustomNodeCustomizingIndividualBarWidths
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special43" ? (
                            <CustomNodeCustomizingIndividualBarBase
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special44" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special45" ? (
                            <CustomNodeWaterfallBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special46" ? (
                            <CustomNodeBarChartwithRelativeBarmode
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special47" ? (
                            <CustomNodeBasicPieChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special48" ? (
                            <CustomNodePieChartSubplots
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special49" ? (
                            <CustomNodeDonutChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special50" ? (
                            <CustomNodeAutomaticallyAdjustMargin
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special51" ? (
                            <ControlTextOrientationInsidePieChartSectors
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={xNumeric === true ? true : false}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special52" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special53" ? (
                            <CustomNodeMarkerSizeandColorBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special54" ? (
                            <CustomNodeHoverTextonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special55" ? (
                            <CustomNodeBubbleSizeonBubbleChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special56" ? (
                            <CustomNodeMarkerSizeColorandSymbolasArray
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special57" ? (
                            <CustomNodeCategoricalDotPlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special58" ? (
                            <CustomNodeBasicOverlaidAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special59" ? (
                            <CustomNodeOverlaidAreaChartWithoutBoundaryLines
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special60" ? (
                            <CustomNodeStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special61" ? (
                            <CustomNodeNormalizedStackedAreaChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special62" ? (
                            <CustomNodeSelectHoverPoints
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special63" ? (
                            <CustomNodeBasicHorizontalBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special64" ? (
                            <CustomNodeColoredandStyledBarChart
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special65" ? (
                            <CustomNodeBarChartwithLinePlot
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special66" ? (
                            <CustomNodeBasicPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special67" ? (
                            <CustomNodeStyledPointCloud
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special68" ? (
                            <BasicSunBurstChart
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special69" ? (
                            <BranchValues
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special70" ? (
                            <SunburstWithRepetedLabels
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special71" ? (
                            <SunburstLargeNumberSlices
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                            />
                          ) : chartSubType == "special72" ? (
                            <SunburstTextOrientaion
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special73" ? (
                            <SankeyDiagrams
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special74" ? (
                            <CreateSankeyCanvas
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : chartSubType == "special75" ? (
                            <AddNodesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special76" ? (
                            <AddLinksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special77" ? (
                            <StyleSankeyDiagramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric == true && yNumeric == true
                                  ? true
                                  : false
                              }
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special78" ? (
                            <DefineNodePositionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special81" ? (
                            <BasicSymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special82" ? (
                            <BarChartwithErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special83" ? (
                            <HorizontalErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special84" ? (
                            <AsymmetricErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special85" ? (
                            <ColoredandStyledErrorBarsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special86" ? (
                            <ErrorBarsasaPercentageoftheyValueBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special87" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special88" ? (
                            <BasicBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special89" ? (
                            <BoxPlotThatDisplayesUnderlyingdataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special90" ? (
                            <HorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special91" ? (
                            <GroupedBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === false && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : chartSubType == "special92" ? (
                            <BoxPlotStylingOutliersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special93" ? (
                            <BoxPlotStylingMeanandStandardDeviationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special94" ? (
                            <GroupedHorizontalBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special95" ? (
                            <ColoredBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special96" ? (
                            <FullyStyledBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special97" ? (
                            <RainBowBoxPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special98" ? (
                            <BasicHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special99" ? (
                            <HorizontalHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special100" ? (
                            <OverlaidHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special101" ? (
                            <StackedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special102" ? (
                            <ColoredandStyledHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special103" ? (
                            <CumulativeHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special104" ? (
                            <NormalizedHistogramBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special105" ? (
                            <SpecifyBinningFunctionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special106" ? (
                            <TwoDHistogramContourPlotwithHistogramSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special107" ? (
                            <TwoDHistogramContourPlotwithSliderControlBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special108" ? (
                            <FilledLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special109" ? (
                            <AsymmetricErrorBarswithaConstantOffsetBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special110" ? (
                            <SimpleContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special111" ? (
                            <BasicContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special112" ? (
                            <SettingXandYCoordinatesinaContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box5}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special113" ? (
                            <ColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special114" ? (
                            <CustomizingSizeandRangeofaContourPlotContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special115" ? (
                            <CustomizingSpacingBetweenXandYTicksBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special116" ? (
                            <ConnecttheGapsbetweenNullValuesintheZMatrixBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special120" ? (
                            <SmoothingContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special121" ? (
                            <SmoothContourColoringBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special122" ? (
                            <ContourLinesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special123" ? (
                            <ContourLineLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special124" ? (
                            <CustomColorscaleforContourPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special125" ? (
                            <ColorBarTitleBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special126" ? (
                            <ColorBarSizeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special127" ? (
                            <StylingColorBarTicksforContourPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box1}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special128" ? (
                            <BasicHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              box={props.box}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special129" ? (
                            <HeatmapwithCategoricalAxisLabelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box4}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special130" ? (
                            <AnnotatedHeatmapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special131" ? (
                            <HeatmapwithUnequalBlockSizesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              box={props.box5}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special132" ? (
                            <BasicTernaryPlotwithMarkersBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special133" ? (
                            <SoilTypesTernaryPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special134" ? (
                            <AddingDimensionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special135" ? (
                            <BasicParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special136" ? (
                            <AnnotatedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special137" ? (
                            <AdvancedParallelCoordinatesPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special138" ? (
                            <LogarithmicAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : chartSubType == "special139" ? (
                            <BasicWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special140" ? (
                            <MultiCategoryWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special141" ? (
                            <HorizontalWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special142" ? (
                            <StyleWaterfallChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special147" ? (
                            <SimpleCandleStickChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special148" ? (
                            <CandlestickChartwithoutRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special149" ? (
                            <CustomiseCandlestickChartwithShapesandAnnotationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special150" ? (
                            <CustomizingCandlestickChartColorsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special151" ? (
                            <AddRangeselectorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special152" ? (
                            <BasicFunnelPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special153" ? (
                            <SettingMarkerSizeandColorBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special154" ? (
                            <StackedFunnelBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special155" ? (
                            <FunnelareaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                              IsDataNumeric={xNumeric === true ? true : false}
                            />
                          ) : chartSubType == "special156" ? (
                            <MultiFunnelareaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : chartSubType == "special157" ? (
                            <DateStringsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special158" ? (
                            <BasicTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special159" ? (
                            <ManuallySetRangeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special160" ? (
                            <TimeSerieswithRangesliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special161" ? (
                            <ThreeDScatterPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special162" ? (
                            <BasicRibbonPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special163" ? (
                            <Topographical3DSurfacePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special164" ? (
                            <SurfacePlotWithContoursBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special165" ? (
                            <Multiple3DSurfacePlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special166" ? (
                            <Simple3DMeshPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special167" ? (
                            <ThreeDMeshPlotwithAlphahullBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special168" ? (
                            <ThreeDMeshTetrahedronBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special169" ? (
                            <ThreeDMeshCubeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special170" ? (
                            <ThreeDLinePlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric === true
                                  ? true
                                  : false
                              }
                              xbox={props.xbox}
                              ybox={props.ybox}
                              zbox={props.zbox}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special171" ? (
                            <ThreeDLinewithMarkersPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special172" ? (
                            <ThreeDLineSpiralPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special173" ? (
                            <ThreeDRandomWalkPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special174" ? (
                            <SimpleSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special175" ? (
                            <CustomSizedSubplotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special176" ? (
                            <MultipleSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              color3={color3}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special177" ? (
                            <SubplotswithSharedAxesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special178" ? (
                            <StackedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              color3={color3}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special179" ? (
                            <StackedSubplotswithaSharedXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special180" ? (
                            <MultipleCustomSizedSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              color3={color3}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special181" ? (
                            <SimpleInsetGraphBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                              color1={color1}
                              color2={color2}
                            />
                          ) : chartSubType == "special182" ? (
                            <Multiple3DSubplotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special183" ? (
                            <MixedSubPlotsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special184" ? (
                            <TableandChartSubplotBlock
                              block={false}
                              main={true}
                              columns={columns}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special85" ? (
                            <ClickEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special186" ? (
                            <BindingToClickEventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special187" ? (
                            <CreateannotationonclickeventBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special88" ? (
                            <HoverEventDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special189" ? (
                            <CapturingHoverEventsDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special190" ? (
                            <CapturingHoverEventsPixelsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special191" ? (
                            <TriggeringHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special192" ? (
                            <CoupledHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              color3={color3}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special193" ? (
                            <CombinedClickandHoverEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              color3={color3}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special194" ? (
                            <BindingtoZoomEventsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                              color1={color1}
                              color2={color2}
                            />
                          ) : chartSubType == "special195" ? (
                            <DisablingZoomEventsforXAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special196" ? (
                            <DisablingZoomEventsforXandYAxisBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special197" ? (
                            <FilterBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special198" ? (
                            <GroupByBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special199" ? (
                            <AggregationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special200" ? (
                            <AggregateFunctionsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special201" ? (
                            <HistogramBinningBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special202" ? (
                            <MappingWithAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special203" ? (
                            <FilterAndGroupbyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special204" ? (
                            <FilterAndAggregatesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special205" ? (
                            <AllTransformsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special208" ? (
                            <AddTwoDropdownMenustoaChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special209" ? (
                            <BinddropdowneventstochartsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special210" ? (
                            <RestyleButtonSingleAttributeBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special211" ? (
                            <RestyleButtonMultipleAttributesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special212" ? (
                            <RelayoutButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special213" ? (
                            <UpdateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special214" ? (
                            <AnimateButtonBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special215" ? (
                            <StylethebuttonsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              box={props.box1}
                              IsDataNumeric={
                                xNumeric === true && yNumeric === true
                                  ? true
                                  : false
                              }
                            />
                          ) : chartSubType == "special216" ? (
                            <BasicSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special219" ? (
                            <BindComponentstotheAppearanceofaPlotBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special220" ? (
                            <AddaPlayButtontoControlaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special221" ? (
                            <LassoSelectionBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special222" ? (
                            <BasicRangeSlideronTimeSeriesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special223" ? (
                            <AnimatingtheDataBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special224" ? (
                            <AnimatingtheLayoutBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special225" ? (
                            <DefiningNamedFramesaddFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special226" ? (
                            <AnimatingSequencesofFramesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special227" ? (
                            <AnimatingManyFramesQuicklyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special228" ? (
                            <ObjectConstancyBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special229" ? (
                            <FrameGroupsandAnimationModesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special230" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special231" ? (
                            <FilledAreaAnimationBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special232" ? (
                            <MultipleTraceFilledAreaBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special233" ? (
                            <MapAnimationsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special234" ? (
                            <BasicTreemapsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special235" ? (
                            <SetDifferentAttributesinTreeMapBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special236" ? (
                            <SetColorofTreemapSectorsBlock
                              block={false}
                              main={true}
                              data2={props.data4}
                              data={props.data2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special237" ? (
                            <NestedLayersinTreemapBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              data2={props.data4}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special238" ? (
                            <ContourandScatterPlotoftheMethodofSteepestDescentBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              IsDataNumeric={
                                xNumeric === true &&
                                yNumeric === true &&
                                zNumeric
                                  ? true
                                  : false
                              }
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special239" ? (
                            <LineChartandBarChartBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special240" ? (
                            <WebGLwithOneLakhpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special241" ? (
                            <WebGLwithOneMillionpointsBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special242" ? (
                            <WebGLwithmanytracesBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special243" ? (
                            <AnimatingwithaSliderBlock
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special256" ? (
                            <EuropeBubbleMap
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special257" ? (
                            <USABubbleMap
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special258" ? (
                            <USAChoroplethMap
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special259" ? (
                            <WorldBubbleMap
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : chartSubType == "special260" ? (
                            <WorldChoroplethMap
                              block={false}
                              main={true}
                              data={props.data2}
                              x={columnXData}
                              y={columnYData}
                              z={columnZData}
                              color1={color1}
                              color2={color2}
                              useLegend={useLegend}
                              columnX={columnX}
                              columnY={columnY}
                              columnZ={columnZ}
                              xName={xName}
                              yName={yName}
                              zName={zName}
                              parentCallback={handleCallback}
                              addAnnotation={addAnnotation}
                              annotation={annotation}
                              chartSubType={chartSubType}
                              getAnnotations={fetchAnnotations}
                              addedAnnotations={addedannotations}
                              existingWidget={props.existingWidget}
                              operationIndex2={operationIndex2}
                              annotedChartType={annotedChartType}
                            />
                          ) : null}
                        </div>
                      )}
                    </CardContent>
                  </Card>

                  {isPrint === false && (
                    <Card
                      sx={{ boxShadow: 3, border: "1px solid #d8d8d8" }}
                      style={{
                        height: 500,
                        width: 330,
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            marginTop: 50,
                          }}
                        >
                          <select
                            onChange={(e) => {
                              //  this.setState({ category: e.target.value })
                              setCategory(e.target.value);
                              if (e.target.value == 4) setIsLegend(false);
                              else setIsLegend(true);
                              setUseLegend(false);
                              // console.log('VVVVVV', e.target.value)
                              //  console.log('ll')
                            }}
                            value={category}
                            style={{
                              border: "1px solid #FFF",
                              width: 150,
                              height: 25,
                              marginTop: -40,
                              backgroundColor: "white",
                              border: "0.5px solid",
                              borderRadius: 10,
                              fontSize: 12,
                              color: "#067AB4",
                            }}
                          >
                            <option
                              style={{
                                fontSize: 14,
                                height: 25,
                                marginBottom: 20,
                              }}
                              value={-1}
                            >
                              {"Chart Categories"}
                            </option>
                            {chartMainTypes.map((ele, i) => (
                              <option
                                style={{ fontSize: 14, height: 25 }}
                                value={i}
                              >
                                {ele.type}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {category == 0 ? (
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                  setUseLegend(false);
                                }}
                                value={chartType}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={-1}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].basicCharts.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 1 ? (
                            <div
                              style={{
                                marginTop: 10,
                                marginBottom: 10,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                onChange={(e) => {
                                  console.log("sel--", e.target.value);
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].statisticalCharts.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 2 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].scientificCharts.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 3 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].financialCharts.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 4 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].threeDcharts.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 5 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].subPlots.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 6 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].customChartEvents.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 7 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].maps.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 8 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].transforms.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 9 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].customControls.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : category == 10 ? (
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                              <select
                                onChange={(e) => {
                                  setchartType(e.target.value);
                                  setSelectAnyChart(true);
                                  setUseLegend(false);
                                }}
                                style={{
                                  border: "1px solid #FFF",
                                  width: 150,
                                  height: 25,

                                  backgroundColor: "white",
                                  border: "0.5px solid",
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: "#067AB4",
                                }}
                              >
                                <option
                                  style={{
                                    fontSize: 14,
                                    height: 25,
                                    marginBottom: 20,
                                  }}
                                  value={0}
                                >
                                  {"Chart Types"}
                                </option>
                                {chartTypes[0].animations.map((ele) => (
                                  <option
                                    style={{ fontSize: 14, height: 25 }}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: 5,
                          // backgroundColor: 'yellow',
                          flex: 1,
                          display: "flex",
                          overflowY: "auto",
                          width: "100%",
                          height: "70%",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {chartType === "Scatter Plots"
                            ? visualizationOptions.section1.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 45,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Line Charts"
                            ? visualizationOptions.section3.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Bar Charts"
                            ? visualizationOptions.section5.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Pie Charts"
                            ? visualizationOptions.section6.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Bubble Charts"
                            ? visualizationOptions.section7.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Dot Plots"
                            ? visualizationOptions.section8.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Filled Area Plots"
                            ? visualizationOptions.section9.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Horizontal Bar Charts"
                            ? visualizationOptions.section10.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Sunburst Charts"
                            ? visualizationOptions.section11.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Sankey Diagrams"
                            ? visualizationOptions.section12.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Point Cloud"
                            ? visualizationOptions.section13.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Treemaps"
                            ? visualizationOptions.section14.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Multiple Chart Types"
                            ? visualizationOptions.section15.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "WebGL"
                            ? visualizationOptions.section16.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Error Bars"
                            ? visualizationOptions.section17.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Box Plots"
                            ? visualizationOptions.section18.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Histograms"
                            ? visualizationOptions.section19.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "2D Density Plots"
                            ? visualizationOptions.section20.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Continuous Error Bars"
                            ? visualizationOptions.section21.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Contour Plots"
                            ? visualizationOptions.section22.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Heatmaps"
                            ? visualizationOptions.section23.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Parallel Coordinates Plot"
                            ? visualizationOptions.section25.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Log Plots"
                            ? visualizationOptions.section26.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Waterfall Charts"
                            ? visualizationOptions.section27.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "CandleSticks Charts"
                            ? visualizationOptions.section29.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Funnel Area Charts"
                            ? visualizationOptions.section30.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Time Series"
                            ? visualizationOptions.section31.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "3D Scatter Plots"
                            ? visualizationOptions.section32.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "3D Surface Plots"
                            ? visualizationOptions.sectionSub3D.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "3D Mesh Plots"
                            ? visualizationOptions.section33.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "3D Line Plots"
                            ? visualizationOptions.section34.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Subplots"
                            ? visualizationOptions.section35a.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Inset Plots"
                            ? visualizationOptions.section35.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "3D Subplots"
                            ? visualizationOptions.section36.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Mixed SubPlots"
                            ? visualizationOptions.section37.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Table SubPlots"
                            ? visualizationOptions.section38.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Click Events"
                            ? visualizationOptions.section39.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Hover Events"
                            ? visualizationOptions.section40.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Zoom Events"
                            ? visualizationOptions.section41.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Disable Zoom Events"
                            ? visualizationOptions.section42.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Filter"
                            ? visualizationOptions.section43.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Aggregations"
                            ? visualizationOptions.section44.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "DropDown Events"
                            ? visualizationOptions.section46.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Button Events"
                            ? visualizationOptions.section48.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Slider Events"
                            ? visualizationOptions.section49.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Lasso Selection"
                            ? visualizationOptions.section50a.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Range Slider and Selector"
                            ? visualizationOptions.section50.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Animations"
                            ? visualizationOptions.section51.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Adding Sliders to Animations"
                            ? visualizationOptions.section52.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Filled Area Animation"
                            ? visualizationOptions.section53.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Bubble Maps"
                            ? visualizationOptions.section55.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : chartType === "Chropleth Maps"
                            ? visualizationOptions.section56.map((ele) => (
                                <div
                                  onClick={() => {
                                    console.log("what-", ele.type);
                                    setchartSubType(ele.type);
                                    setChartTitle(ele.title);
                                    setNewChartTitle(ele.title);

                                    setIsWidgetSaved(false);
                                    setUseLegend(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px",
                                    marginTop: 20,
                                    marginBottom: 35,
                                    borderRadius: "12px",
                                    padding: 4,
                                    width: "44%",
                                    height: 70,
                                    //background: '#A9A9A9',
                                    display: "inline-block",
                                    boxShadow:
                                      chartSubType == ele.type
                                        ? "1px 0.8px 1.5px 1.5px #a6a6a6"
                                        : 3,
                                    border:
                                      chartSubType == ele.type
                                        ? "2px solid gray"
                                        : "0.5px solid #ccc",
                                  }}
                                >
                                  <img
                                    // resizeMode={'contain'}
                                    src={ele.img}
                                    style={{ resizeMode: "contain" }}
                                    height={"100%"}
                                    width={"100%"}
                                    alt={""}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        Color: "black",
                                        lineHeight: "1.3em",
                                        margin: "0.5em 0",
                                      }}
                                    >
                                      {ele.title}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {dataTable && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "350px",
                        marginTop: "20px",
                        marginRight: "20px",
                        marginBottom: "20px",
                        alignSelf: "flex-end",
                      }}
                    >
                      <Tooltip title="Edit Mode">
                        <div
                          style={{
                            backgroundColor: editMode ? "#067AB4" : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setEditMode(!editMode)}
                        >
                          <IconButton>
                            <EditIcon sx={{ color: editMode && "white" }} />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip title="Column Resizing">
                        <div
                          style={{
                            backgroundColor: columnResize
                              ? "#067AB4"
                              : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setColumnResize(!columnResize)}
                        >
                          <IconButton>
                            <SwapHorizIcon
                              sx={{ color: columnResize && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip title="Sticky Header">
                        <div
                          style={{
                            backgroundColor: stickyHeader
                              ? "#067AB4"
                              : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setStickyHeader(!stickyHeader)}
                        >
                          <IconButton>
                            <DragHandleIcon
                              sx={{ color: stickyHeader && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip title="Column Selection">
                        <div
                          style={{
                            backgroundColor: columnSelection
                              ? "#067AB4"
                              : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setColumnSelection(!columnSelection)}
                        >
                          <IconButton>
                            <PhotoSizeSelectSmallIcon
                              sx={{ color: columnSelection && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip title="Row Selection">
                        <div
                          style={{
                            backgroundColor: rowSelection
                              ? "#067AB4"
                              : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setRowSelection(!rowSelection)}
                        >
                          <IconButton>
                            <HighlightAltIcon
                              sx={{ color: rowSelection && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip title="Fill Handle">
                        <div
                          style={{
                            backgroundColor: fillHandle ? "#067AB4" : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setFillHandle(!fillHandle)}
                        >
                          <IconButton>
                            <GradingIcon
                              sx={{ color: fillHandle && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip title="Context Menu">
                        <div
                          style={{
                            backgroundColor: contextMenu
                              ? "#067AB4"
                              : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setContextMenu(!contextMenu)}
                        >
                          <IconButton>
                            <ContentCopyIcon
                              sx={{ color: contextMenu && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>

                      <Tooltip title="Highlight">
                        <div
                          style={{
                            backgroundColor: highlightOn
                              ? "#067AB4"
                              : "#C1D9EC",
                            borderRadius: "8px",
                          }}
                          onClick={() => setHighlightOn(!highlightOn)}
                        >
                          <IconButton>
                            <HighlightIcon
                              sx={{ color: highlightOn && "white" }}
                            />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {dataTable && <MyComponent data={rowsData} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : alignment === "auto-ML" &&
        global.subscription_id === "price_1LfOpESBwqDDsny7sB1s8fra" ? (
        <AutoMLEngine />
      ) : (
        global.subscription_id === "price_1LfOrRSBwqDDsny7TiYnfuXA" && (
          <AutoMLEngine />
        )
      )}

      <Prompt
        when={isWidgetSaved === false}
        message="There are unsaved changes, are you sure you want to leave?"
      />
    </>
  );
}
